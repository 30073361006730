
import React from 'react';
import { ResponsiveLine } from '@nivo/line';


const MontlyLineChart = () => {

  const data = [
    {
        id: "Custom",
        // color: pink,
        data: [
          {
            x: "Jan",
            y: 0,
          },
          {
            x: "Feb",
            y: 2000,
          },
          {
            x: "Mar",
            y: 4000,
          },
          {
            x: "Apr",
            y: 4000,
          },
          {
            x: "May",
            y: 4500,
          },
          {
            x: "Jun",
            y: 4500,
          },
          {
            x: "Jul",
            y: 4500,
          },{
            x: "Aug",
            y: 4000,
          },
          {
            x: "Sep",
            y: 4500,
          },
          {
            x: "Oct",
            y: 4500,
          },
          {
            x: "Nov",
            y: 4500,
          },
          {
            x: "Dec",
            y: 4500,
          },
        ],
      },
    
      {
          id: "Bussiness",
        //   color: orange,
        data: [
            {
              x: "Jan",
              y: 0,
            },
            {
              x: "Feb",
              y: 2000,
            },
            {
              x: "Mar",
              y: 4000,
            },
            {
              x: "Apr",
              y: 4000,
            },
            {
              x: "May",
              y: 4500,
            },
            {
              x: "Jun",
              y: 4500,
            },
            {
              x: "Jul",
              y: 6500,
            },{
              x: "Aug",
              y: 4000,
            },
            {
              x: "Sep",
              y: 4500,
            },
            {
              x: "Oct",
              y: 4500,
            },
            {
              x: "Nov",
              y: 6500,
            },
            {
              x: "Dec",
              y: 6500,
            },
          ],
        },
        
          {
            id: "premium",
            // color: green,
            data: [
                {
                  x: "Jan",
                  y: 0,
                },
                {
                  x: "Feb",
                  y: 2000,
                },
                {
                  x: "Mar",
                  y: 4000,
                },
                {
                  x: "Apr",
                  y: 4000,
                },
                {
                  x: "May",
                  y: 4500,
                },
                {
                  x: "Jun",
                  y: 4500,
                },
                {
                  x: "Jul",
                  y: 6500,
                },{
                  x: "Aug",
                  y: 4000,
                },
                {
                  x: "Sep",
                  y: 4500,
                },
                {
                  x: "Oct",
                  y: 4500,
                },
                {
                  x: "Nov",
                  y: 6500,
                },
                {
                  x: "Dec",
                  y: 6500,
                },
              ],
          },
          {
            id: "free",
            // color: "blue",
            data: [
                {
                  x: "Jan",
                  y: 0,
                },
                {
                  x: "Feb",
                  y: 2000,
                },
                {
                  x: "Mar",
                  y: 4000,
                },
                {
                  x: "Apr",
                  y: 4000,
                },
                {
                  x: "May",
                  y: 4500,
                },
                {
                  x: "Jun",
                  y: 4500,
                },
                {
                  x: "Jul",
                  y: 6500,
                },{
                  x: "Aug",
                  y: 4000,
                },
                {
                  x: "Sep",
                  y: 4500,
                },
                {
                  x: "Oct",
                  y: 4500,
                },
                {
                  x: "Nov",
                  y: 6500,
                },
                {
                  x: "Dec",
                  y: 6500,
                },
              ],
          }
  ];

  const yTicks = [0, 5000, 10000, 15000,20000, 25000];
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 34, right: 20, bottom: 25, left: 35 }}
  
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: true,
        reverse: false
      }}
 
      yFormat=" >-.0f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        format: value => `${value / 1000}k`,
        tickValues : yTicks

      }}
      
      pointSize={6}
      pointColor={'white'}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor', modifiers: [] }}
      pointLabelYOffset={-12}
      useMesh={true}
      enableGridX={false}
      colors={['#DD6CD9','#DD6C6C','#419958','#3F4299']}
      
      legends={[
        {
            anchor: 'top-left',
            direction: 'row',
            justify: false,
            translateX: 40,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 75,
            itemHeight: -50,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
                {
                    on: 'hover',
                    style: {
                        itemBackground: 'rgba(0, 0, 0, .03)',
                        itemOpacity: 1
                    }
                }
            ]
        }
    ]}

      
      
    />
  );
};

export default MontlyLineChart;
