import React, { useState } from 'react'
import { NavLink } from "react-router-dom";
import ContentTable from '../ContentComponent/ContentTable';
import SupportSidebar from '../SupportComponent/SupportSidebar';
import LineChartDaily from '../SupportComponent/LineChartDaily';
import FeedbackPieChart from '../ContentComponent/FeedbackPieChart';


export default function SupportDashboard() {
    const [active, isactive] = useState(true);
  const handleactive = () => {
    isactive(true);
    isactive1(false);
    isactive2(false);
    isactive3(false);
  };
  const [active1, isactive1] = useState(false);
  const handleactive1 = () => {
    isactive1(true);
    isactive(false);
    isactive2(false);
    isactive3(false);
  };
  const [active2, isactive2] = useState(false);
  const handleactive2 = () => {
    isactive(false);
    isactive1(false);
    isactive2(true);
    isactive3(false);
  };
  const [active3, isactive3] = useState(false);
  const handleactive3 = () => {
    isactive(false);
    isactive1(false);
    isactive2(false);
    isactive3(true);
  };
  return (
    <div>
        <div className="dashboard_bg">
        <div className="row mr-0">
          <div className="col-3 content_dashboard_sidebar">
            <SupportSidebar />
          </div>
          <div className="top_banner col-9">
            <div className="d-flex">
              <img
                className="menu_icon mt-32 ml-22"
                src="menu_icon.svg"
                alt="Menu"
              />

              <img
                className="logout_icon mt-32 justify-content-end"
                src="logout_icon.svg"
                alt="Logout"
              />

              <div className="ml-18 mt-12">
                <div className="profile_round_dashboard">
                  <img
                    className="rounded-circle profile_logo"
                    src="profile.jpeg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-between'>
            <div className="font-family mt-32 ml-20 dash_text">Analytics</div>
            <div className='content_txt font-family mt-32'>Content > Analytics</div>
            </div>
            <div className="d-flex">
              <div className="sale_chart_bg mt-20 ml-20">
              <div className="d-flex justify-content-between">
                  <div>
                    <div className="font-family ml-20 pt-20 fnt_sale">
                    Tickets Report
                    </div>
                    <div className="font-family ml-20 mt-8 fnt_sale1 fw-400">
                      Overview of latest day
                    </div>
                  </div>
                  <div className=" pt-22 mr-20">
                    <NavLink
                      onClick={handleactive}
                      className={
                        active
                          ? "fnt1_active text-uppercase"
                          : "fnt1 text-uppercase"
                      }
                    >
                      Daily
                    </NavLink>
                    <NavLink
                      onClick={handleactive1}
                      className={
                        active1
                          ? "fnt1_active text-uppercase ml-20"
                          : "fnt1 text-uppercase ml-20"
                      }
                    >
                      Monthly
                    </NavLink>
                    <NavLink
                      onClick={handleactive2}
                      className={
                        active2
                          ? "fnt1_active text-uppercase ml-25"
                          : "fnt1 text-uppercase ml-25"
                      }
                    >
                      Weekly
                    </NavLink>
                    <NavLink
                      onClick={handleactive3}
                      className={
                        active3
                          ? "fnt1_active text-uppercase ml-28"
                          : "fnt1 text-uppercase ml-28"
                      }
                    >
                      Yearly
                    </NavLink>
                  </div>
                </div>
                <div className="content_chart_size">
                {active &&
                <LineChartDaily />}
                {active1 &&
                <LineChartDaily />}
                {active2 &&
                <LineChartDaily />}
                {active3 &&
                <LineChartDaily />}
              </div>
                
              </div>
              <div className="user_chart_bg ml-20 mt-20">
                <div className="mt-20 ml-20 user_text font-family">
                  Total Feedbacks
                </div>
                <div className="chart_2">
                  <FeedbackPieChart />
                </div>
              </div>
            </div>
            <div className="mt-20 ml-20 row">
              <div className="pad_none">
                <div className="d-flex">
                  <div className="free_user_bg">
                    <div className="d-flex">
                      <div>
                        <div className="free_user font-family">Overall Rating</div>
                        <div className="free_num">15,565</div>
                      </div>
                      <div>
                        <img
                          src="graph_line.svg"
                          alt="Graph"
                          className="graph_line"
                        />
                      </div>
                    </div>
                    <div>
                      <img
                        src="increasing_icon.svg"
                        alt="increasing"
                        className="increase_icon"
                      />
                      <span className="incr">+5%</span>{" "}
                      <span className="incr1">than last month</span>
                    </div>
                  </div>
                  <div className="free_user_bg ml-20">
                    <div className="d-flex">
                      <div>
                        <div className="free_user font-family">
                          Response Time
                        </div>
                        <div className="free_num">1 min</div>
                      </div>
                      <div>
                        <img
                          src="graph_line1.svg"
                          alt="Graph "
                          className="graph_line1"
                        />
                      </div>
                    </div>
                    <div>
                      <img
                        src="decreasing_icon.svg"
                        alt="increasing"
                        className="increase_icon"
                      />
                      <span className="decr">-2%</span>{" "}
                      <span className="decr1">than last month</span>
                    </div>
                  </div>
                <div className="d-flex ml-20">
                  <div className="free_user_bg">
                    <div className="d-flex">
                      <div>
                        <div className="free_user font-family">
                          Tickets: Total
                        </div>
                        <div className="free_num">5,103</div>
                      </div>
                      <div>
                        <img
                          src="graph_line1.svg"
                          alt="Graph"
                          className="graph_line1"
                        />
                      </div>
                    </div>
                    <div>
                      <img
                        src="decreasing_icon.svg"
                        alt="increasing"
                        className="increase_icon"
                      />
                      <span className="decr">-10%</span>{" "}
                      <span className="decr1">than last month</span>
                    </div>
                  </div>
                  <div className="free_user_bg ml-20">
                    <div className="d-flex">
                      <div>
                        <div className="free_user font-family">
                        Tickets: Today
                        </div>
                        <div className="free_num">2,475</div>
                      </div>
                      <div>
                        <img
                          src="graph_line.svg"
                          alt="Graph "
                          className="graph_line"
                        />
                      </div>
                    </div>
                    <div>
                      <img
                        src="increasing_icon.svg"
                        alt="increasing"
                        className="increase_icon"
                      />
                      <span className="incr">+4%</span>{" "}
                      <span className="incr1">than last month</span>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
            <ContentTable />
          </div>
          {/* Dashboard Side Ends Here  */}
        </div>
      </div>
    </div>
  )
}
