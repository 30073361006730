import React from 'react';
import { ResponsiveLine } from '@nivo/line';

const LineChartDaily = () => {
    const data = [
        
          {
            "id": "Tickets Resolved",
            "color": "hsl(265, 70%, 50%)",
            "data": [
              {
                "x": "Mon",
                "y": 0
              },
              {
                "x": "Tue",
                "y": 7000
              },
              {
                "x": "Wed",
                "y": 15000
              },
              {
                "x": "Thur",
                "y": 18000
              },
              {
                "x": "Fri",
                "y": 11000
              },
              {
                "x": "Sat",
                "y": 16500
              },
              {
                "x": "Sun",
                "y": 17000
              }
            ]
          },
          {
            "id": "Tickets Created",
            "color": "hsl(243, 43%, 40%)",
            "data": [
              {
                "x": "Mon",
                "y": 0
              },
              {
                "x": "Tue",
                "y": 10500
              },
              {
                "x": "Wed",
                "y": 9000
              },
              {
                "x": "Thur",
                "y": 6500
              },
              {
                "x": "Fri",
                "y": 8500
              },
              {
                "x": "Sat",
                "y": -2000
              },
              {
                "x": "Sun",
                "y": 6500
              }
            ]
          }
    ]
  const tickValues = [0, 10000, 15000, 20000, 25000];
    const color = ['#3f4299']
    return(
        <ResponsiveLine
    data={data}
    margin={{ top: 50, right: 10, bottom: 60, left: 60 }}
    xScale={{ type: 'point' }}
    yScale={{
      type: 'linear',
      min: 'auto',
      max: 'auto',
      stacked: true,
      reverse: false
    }}
    // yFormat=" >-.2f"
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: '',
      legendOffset: 36,
      legendPosition: 'middle'
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: '',
      legendOffset: -40,
      legendPosition: 'middle',
      format: value => `${value / 1000}k`,
      tickValues : tickValues
    }}
    pointSize={10}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={ color }
    pointLabelYOffset={-12}
    useMesh={true}
    enableGridX={false}
      colors={['#DD6CD9', '#3f4299']}
    legends={[
      {
        anchor: 'top-middle',
        direction: 'row',
        justify: false,
        translateX: 140,
        translateY: -30,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 110,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
  />
    )
};

export default LineChartDaily;
