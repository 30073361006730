import React from 'react'

export default function PostsCard(props) {
    
  return (
    <div>
        <div className='d-flex card_align'>
        {props.data.map((data) => (<div className="mt-16">
              <div className="bg-white post_card">
                <div className="d-flex justify-content-end">
                    <input type="checkbox" className="mt-12 mr-12" />
                    </div>
                  <div className="ml-20">
                  <div className="post_txt font-family mt_5">
                  {data.t1}
                  </div>
                  <div className="post_txt1 font-family mt-8">Category</div>
                  <div className="font-family post_txt2 mt-15 mr-31">
                    {data.t2}
                  </div>
                  </div>
              <div className="posts_brdr mt-24"></div>
              <div className="pb-12">
              <div className="d-flex ml-20 mt-12">
              <div className="posts_round">
                <img
                  className="rounded-circle posts_logo"
                  src="profile.jpeg"
                  alt=""
                />
              </div>
              <div>
                <div className="post_txt3">Robert Fox</div>
              </div>
              <div className="justify-content-end">
                <div className="post_txt4">Sep 12,2022</div>
              </div>
            </div>
              </div>
              </div>
            </div>
            ))}
        </div>
    </div>
  )
}
