


import React, { useState } from 'react'
import "../../css/sale.css"
import SaleSidebar from '../SaleComponents/SaleSidebar'
import NavbarAvatar from '../ContentComponent/NavbarAvatar'
import Chart2 from '../DashboardComponent/Chart2';
import { NavLink, useNavigate } from "react-router-dom";

import MuiTable from '../DashboardComponent/MuiTable';
import SaleChartDaily from '../SaleComponents/SaleChartDaily';
import MontlyLineChart from '../SaleComponents/MontlyLineChart';
import WeeklyLineChart from '../SaleComponents/WeeklyLineChart';
import YearlyLineChart from '../SaleComponents/YearlyLineChart';

export default function SalePlan() {
  const [isToggle,setIsToggle]=useState(true);
  const navigate=useNavigate();
  function togglesidebar()
  {
    setIsToggle(!isToggle);
  }

  
  return (
    <div className='d-flex'>

            {/* sidebar  */}

            <SaleSidebar isToggle={isToggle}/>

          
          
            <div className='container-fluid sale-content ' style={{ marginLeft: isToggle ? '20vw' : '0vw' }}  >
              {/* navbar  */}
            
              <NavbarAvatar togglesidebar={togglesidebar}/>

              {/* content parent container  */}
              <div className='row all-content-container'>
              <div className='col-md-12 mb-40'>

             
              
               {/* =====    row1    === */}
                <div className='d-flex justify-content-between ml-20 mr-20'>
            <div className="font-family mt-32  dash_text">Plans</div>
            <div className='content_txt font-family mt-32'>Sales {'>'} Plans</div>
            </div>
            {/* =====    row1 ends   === */}

               {/* =====    row2   === */}
               <div className='d-flex  ml-20 mr-20 mt-12'>
                <span className='ms-auto d-flex'>

            <div className="sale-plan-delbtn mr-12"><img src="/images/del-icon.svg" style={{height:'1.6vw',width:'1.6vw'}} alt='del'/></div>
            <button className="post_btn " style={{margin:'0'}} onClick={()=>navigate('/saleplansedit')}>  +Add New</button>

                </span>
            </div>
            {/* =====    row2 ends   === */}

            {/* =====    row3    === */}
            <div className='d-flex justify-content-between ml-20 mr-20 mt-32'>
                {/* card1  */}
          <div className='Sale-plan-card pl-36 pr-36 pt-34'>
            <div className='card-head'>
                <div className='active-plan'>Active</div>
                <div className='plan-type mt-10'>
                    <span className='plan-heading'>Free</span>
                    <span className='plan-img'><img src='/images/free-logo.svg' alt='plan-logo' className='plan-img'/></span>
                </div>
            </div>
            <div className='card-body mt-30'>
                <div className='point-box d-flex align-items-center'>
                    <img src='/images/tick.svg' alt='tick' className='tick-img mr-15'/>
                    <span className='point-txt'>Spelling</span>
                </div>
                <div className='point-box d-flex align-items-center'>
                    <img src='/images/tick.svg' alt='tick' className='tick-img mr-15'/>
                    <span className='point-txt'>Grammer</span>
                </div>
                <div className='point-box d-flex align-items-center'>
                    <img src='/images/tick.svg' alt='tick' className='tick-img mr-15'/>
                    <span className='point-txt'>Punctuation</span>
                </div>
            </div>
            <div className='edit-btn-box mb-40'>
                <div className='plan-edit-btn' style={{cursor:'pointer'}} onClick={()=>navigate('/saleplansedit')}>Edit</div>
            </div>
            
          </div>

          {/* card2  */}

          <div className='Sale-plan-card pl-36 pr-36 pt-34'>
            <div className='card-head'>
                <div className='active-plan'>Active</div>
                <div className='plan-type mt-10'>
                    <span className='plan-heading'>Premium</span>
                    <span className='plan-img'><img src='/images/premiuum-logo.svg' alt='plan-logo' className='plan-img'/></span>
                </div>
            </div>
              
            <div className='card-body mt-30'>
                <div className='point-box d-flex align-items-center'>
                    <img src='/images/tick.svg' alt='tick' className='tick-img mr-15'/>
                    <span className='point-txt'>Everything in Free</span>
                </div>
                <div className='point-box d-flex align-items-center'>
                    <img src='/images/tick.svg' alt='tick' className='tick-img mr-15'/>
                    <span className='point-txt'>Clarity-focused sentence rewrites</span>
                </div>
                <div className='point-box d-flex align-items-center'>
                    <img src='/images/tick.svg' alt='tick' className='tick-img mr-15'/>
                    <span className='point-txt'>Tone adjustments</span>
                </div>
                <div className='point-box d-flex align-items-center'>
                    <img src='/images/tick.svg' alt='tick' className='tick-img mr-15'/>
                    <span className='point-txt'> Plagiarism detection</span>
                </div>
                <div className='point-box d-flex align-items-center'>
                    <img src='/images/tick.svg' alt='tick' className='tick-img mr-15'/>
                    <span className='point-txt'>Word choice</span>
                </div>
                <div className='point-box d-flex align-items-center'>
                    <img src='/images/tick.svg' alt='tick' className='tick-img mr-15'/>
                    <span className='point-txt'>Formality level</span>
                </div>
                <div className='point-box d-flex align-items-center'>
                    <img src='/images/tick.svg' alt='tick' className='tick-img mr-15'/>
                    <span className='point-txt'> Fluency</span>
                </div>
                <div className='point-box d-flex align-items-center'>
                    <img src='/images/tick.svg' alt='tick' className='tick-img mr-15'/>
                    <span className='point-txt'>Additional advanced suggestions</span>
                </div>
            </div>
            <div className='edit-btn-box mb-40'>
                <div className='plan-edit-btn' style={{cursor:'pointer'}} onClick={()=>navigate('/saleplansedit')}>Edit</div>
            </div>
            
          </div>

          {/* card3  */}

          <div className='Sale-plan-card pl-36 pr-36 pt-34'>
            <div className='card-head'>
                <div className='active-plan'>Active</div>
                <div className='plan-type mt-10'>
                    <span className='plan-heading'>Premium</span>
                    <span className='plan-img'><img src='/images/premiuum-logo.svg' alt='plan-logo' className='plan-img'/></span>
                </div>
            </div>
             
            <div className='card-body mt-30'>
                <div className='point-box d-flex align-items-center'>
                    <img src='/images/tick.svg' alt='tick' className='tick-img mr-15'/>
                    <span className='point-txt'> Everything in Premium</span>
                </div>
                <div className='point-box d-flex align-items-center'>
                    <img src='/images/tick.svg' alt='tick' className='tick-img mr-15'/>
                    <span className='point-txt'> Style guide </span>
                </div>
                <div className='point-box d-flex align-items-center'>
                    <img src='/images/tick.svg' alt='tick' className='tick-img mr-15'/>
                    <span className='point-txt'>Snippets</span>
                </div>
                <div className='point-box d-flex align-items-center'>
                    <img src='/images/tick.svg' alt='tick' className='tick-img mr-15'/>
                    <span className='point-txt'> Brand tones</span>
                </div>
                <div className='point-box d-flex align-items-center'>
                    <img src='/images/tick.svg' alt='tick' className='tick-img mr-15'/>
                    <span className='point-txt'> Analytics dashboard </span>
                </div>
                <div className='point-box d-flex align-items-center'>
                    <img src='/images/tick.svg' alt='tick' className='tick-img mr-15'/>
                    <span className='point-txt'> Priority email support</span>
                </div>
                <div className='point-box d-flex align-items-center'>
                    <img src='/images/tick.svg' alt='tick' className='tick-img mr-15'/>
                    <span className='point-txt'> Account roles and permissions</span>
                </div>
                <div className='point-box d-flex align-items-center'>
                    <img src='/images/tick.svg' alt='tick' className='tick-img mr-15'/>
                    <span className='point-txt'>  SAML SSO </span>
                </div>
                <div className='point-box d-flex align-items-center'>
                    <img src='/images/tick.svg' alt='tick' className='tick-img mr-15' style={{marginTop:'-1.3vw'}}/>
                    <span className='point-txt'> Invoicing available for annual plans of 10 seats or more</span>
                </div>
            </div>
            <div className='edit-btn-box mb-40'>
                <div className='plan-edit-btn' style={{cursor:'pointer'}} onClick={()=>navigate('/saleplansedit')}>Edit</div>
            </div>
            
          </div>









            </div>
            {/* =====    row3 ends   === */}

            

              </div>
              </div>
              

            </div>
       
      
    </div>
  )
}
