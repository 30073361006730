
import React, { useState } from 'react'
import "../../css/sale.css"
import SaleSidebar from '../SaleComponents/SaleSidebar'
import NavbarAvatar from '../ContentComponent/NavbarAvatar'
import { useNavigate } from 'react-router-dom';


export default function InvoivePreview() {
  const [isToggle,setIsToggle]=useState(true);
  const navigate=useNavigate();
  function togglesidebar()
  {
    setIsToggle(!isToggle);
  }

  return (
    <div className='d-flex'>

            {/* sidebar  */}

            <SaleSidebar isToggle={isToggle}/>

          
          
            <div className='container-fluid sale-content ' style={{ marginLeft: isToggle ? '20vw' : '0vw' }}  >
              {/* navbar  */}
            
              <NavbarAvatar togglesidebar={togglesidebar}/>

              {/* content parent container  */}
              <div className='row all-content-container'>
              <div className='col-md-12'>

             
              
                <div className=' Newinvoice-container ml-20 mr-20 mt-40 pb-32 mb-40'>
               
               {/* =====    row1 Header   === */}
            <div className="newInvoice-header d-flex">

             
              <div className='heading-invoive-container d-flex gap-2 ml-40 mt-40 ' > 

              <img src="/images/cross.svg" onClick={()=>navigate('/saleinvoice')} alt="plus-img" className='plus-img' style={{cursor:'pointer'}} />  
            
              </div>

              <div className=' mr-40 mt-40 d-flex gap-4 ms-auto'> 
        
              <span className='invo-no' onClick={()=>navigate('/newInvoice')} style={{cursor:'pointer'}}>Edit</span>
              <div className='sale-white-btn' onClick={()=>navigate('/saleinvoice')}>download</div>
              <div className='sale-blue-btn'>Send</div>


            
              </div>


            </div>
              {/* =====    row1 Header ends  === */}

                {/* =====    row1 body   === */}
          
            <div className='invoice-preview-row1 d-flex justify-content-between mt-40 ml-104 mr-104'>
                <img src="/images/App-logo.svg"  alt="plus-img" className='app-logo-css' />  
                <span className='d-flex'>

                <span className='inv-text'>Invoice #</span>
              <span className='invo-no'>42342</span>
                </span>
                </div>
                {/* =====    row1 body ends  === */}



                {/* =====    row2 body   === */}
            <div className='newInvoice-content  mt-40 ml-104 mr-104'>
               
              <div className='row1-col-1 d-flex flex-column'>
                <p className='invoice-preview-heading-txt'>Invoice from</p>
                <p className='invoice-preview-txt'>Grammefy</p>
                <p className='invoice-preview-txt'>Ginfo@company.com</p>
                <p className='invoice-preview-txt'>United States</p>
                <p className='invoice-preview-txt'>XYZ Delta Street</p>
                <p className='invoice-preview-txt'>17655429949</p>
              

               
              </div>
              <div className='row1-col-2 d-flex flex-column'>
              <p className='invoice-preview-heading-txt'>Invoice to</p>
            
                <p className='invoice-preview-txt'>Eric Gbozo</p>
                <p className='invoice-preview-txt'>534 Victoria Trail</p>
                <p className='invoice-preview-txt'>United States</p>
                <p className='invoice-preview-txt'>Alaska- 3233</p>
                <p className='invoice-preview-txt'>example@email.com</p>
                <p className='invoice-preview-txt'>15344439055</p>

        
               
              </div>

            </div>

                {/* =====    row2 body ends  === */}



                {/* =====    row3 body ends  === */}
                <div className='newInvoice-content  pt-20 mt-20 ml-104 mr-104 top-border '>
               
              <div className='row1-col-1 d-flex flex-column'>

                <span className='invoice-preview-heading-txt'>Payment Info</span>
                <div className='d-flex mt-8'>
                <span className='invoice-preview-txt'>Bank Name:</span>
                <span className='invoice-preview-txt ml-12'>Union Bank</span>
                </div>
                <div className='d-flex mt-8'>
                <span className='invoice-preview-txt'>Account Number:</span>
                <span className='invoice-preview-txt ml-12'>Union Bank</span>
                </div>
                <div className='d-flex mt-8'>
                <span className='invoice-preview-txt'>Swift Code:</span>
                <span className='invoice-preview-txt ml-12'>Union Bank</span>
                </div>
                <div className='d-flex mt-8'>
                <span className='invoice-preview-txt'>Country:</span>
                <span className='invoice-preview-txt ml-12'>Union Bank</span>
                </div>
               

              </div>
              <div className='row1-col-2 d-flex flex-column'>
            


            <div className='d-flex'>

                <span className='invoice-preview-heading-txt'>Invoice Date: </span>
                 <span className='invoice-preview-txt ml-12'> 11 Nov 2022</span>
            </div>
            <div className='d-flex mt-8'>

                <span className='invoice-preview-heading-txt'>Due Date: </span> 
                <span className='invoice-preview-txt ml-12'> 11 Nov 2022</span>
            </div>

              </div>

            </div>
                {/* =====    row3 body ends  === */}





                          {/* =====    row4 body   === */}
            <div className='calculation-container mt-20 ml-104 mr-104'>
              <div className='cal-headd'>
              <span className='cal-text ml-20' style={{minWidth:'24.5vw'}}>Description</span>
      <span className='cal-text'style={{minWidth:'11vw'}}>Rate</span>
      <span className='cal-text' style={{minWidth:'11vw'}}>Quantity</span>
      <span className='cal-text' style={{minWidth:'10vw'}}>Discount</span>
      <span className='cal-text mr-20 '>Amount</span>
              </div>
              <div className='cal-bodyy'>
                    
          
              <span className='newinvoice-row3-col2-txt ml-20 ' style={{minWidth:'24.5vw'}}>Grammefy Business</span>
              <span className='newinvoice-row3-col2-txt' style={{minWidth:'11vw'}}>20$</span>
              <span className='newinvoice-row3-col2-txt' style={{minWidth:'11vw'}}>50$</span>
              <span className='newinvoice-row3-col2-txt' style={{minWidth:'10vw'}}>0$</span>
              <span className='newinvoice-row3-col2-txt mr-20'>1000$</span>
              </div>

            </div>





{/* 
<div className=' mt-20 ml-104 mr-104'>

<table className='table' >
    <thead>
      <tr className='cal-head'>
        <th className='cal-text ml-20 ' style={{borderTopLeftRadius:'0.267vw',verticalAlign: 'middle',paddingLeft:'1.333vw'}}>Description</th>
        <th className='cal-text'style={{ verticalAlign: 'middle' }}>Rate</th>
        <th className='cal-text'style={{ verticalAlign: 'middle' }}>Quantity</th>
        <th className='cal-text'style={{ verticalAlign: 'middle' }}>Discount</th>
        <th className='cal-text mr-20' style={{textAlign:'center',verticalAlign: 'middle',borderTopRightRadius:'0.267vw',paddingRight:'1.333vw'}}>Amount</th>
      </tr>
    </thead>
    <tbody className='cal-body' style={{height:'5.6vw'}}>
      <tr  >
        <td style={{ verticalAlign: 'middle' ,paddingLeft:'1.333vw'}}>  <span className='newinvoice-row3-col2-txt '>Grammefy Business</span></td>
        <td style={{ verticalAlign: 'middle' }}> <span className='newinvoice-row3-col2-txt'>20$</span></td>
        <td style={{ verticalAlign: 'middle' }}> <span className='newinvoice-row3-col2-txt'>50$</span></td>
        <td style={{ verticalAlign: 'middle' }}> <span className='newinvoice-row3-col2-txt'>50$</span></td>
        <td style={{textAlign:'center', verticalAlign: 'middle'}}><span className='newinvoice-row3-col2-txt'>1000$</span></td>
      </tr>
    </tbody>
  </table>
  </div> */}


            {/* row 3  */}

            <div className='newinvoice-row3  mt-40 mr-104'>
               
               <div className='newinvoice-row3-col2 ms-auto'>
                 <span className='subtotal-div1  '>
                   <p className='newinvoive-light-txt'>Subtotal</p>
                   <p className='newinvoice-row3-col2-txt'>1000$</p>
                 </span>
                 <span className='subtotal-div1 mt-8'>
                   <p className='newinvoive-light-txt'>Discount</p>
                   <p className='newinvoice-row3-col2-txt'>0$</p>
                 </span>
                 <span className='subtotal-div3 top-border mt-20 pt-20'>
                   <p className='newinvoive-light-txt'>Total</p>
                   <p className='newinvoice-row3-col2-txt'>1000$</p>
                 </span>
               </div>
             </div>


             {/* last row start */}
             
             <div className='newinvoice-row3  mt-40 mr-104 ml-104'>
               
               <p className='invoice-previe-pra'>Your Subscription Will Automatically Renew Every Month 
                If You Select The Monthly Plan, Or Every Twelve Months
                 If You Select The Annual Plan.
                  You Will Be Charged The Price Listed Above For The Plan You Select, 
                  Plus Applicable Taxes, On Each Renewal Until You Cancel In Your Account Settings 
                  Or By Submitting A Help Desk Request Here. If You Cancel, Previous Charges Will 
                  Not Be Refunded, But You May Continue To Use The Service Until The End Of The Term 
                  You Paid For.</p>
             </div>
             {/* last row end */}

          
            
            
            </div>
            {/* =====    row1 ends   === */}

           



              </div>
              </div>
              

            </div>
       
      
    </div>
  )
}
