import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import PostsCard from "./PostsCard";
import PostPagination from "./PostPagination";

export default function PostsTable() {
  const [active, isactive] = useState(true);

  const handleactive = () => {
    isactive(true);
    isactive1(false);
    isactive2(false);
    isactive3(false);
  };
  const [active1, isactive1] = useState(false);

  const handleactive1 = () => {
    isactive(false);
    isactive1(true);
    isactive2(false);
    isactive3(false);
  };
  const [active2, isactive2] = useState(false);

  const handleactive2 = () => {
    isactive(false);
    isactive1(false);
    isactive2(true);
    isactive3(false);
  };
  const [active3, isactive3] = useState(false);

  const handleactive3 = () => {
    isactive(false);
    isactive1(false);
    isactive2(false);
    isactive3(true);
  };

  const [isClicked, setisClicked] = useState(false);
  const cardClick = () => {
    setisClicked(true);
    setisClicked2(false);
  };
  const [isClicked2, setisClicked2] = useState(false);
  const cardClick2 = () => {
    setisClicked(false);
    setisClicked2(true);
  };

  const anyConditionTrue = isClicked || isClicked2
  // const toPath = anyConditionTrue ? "/Login1" : "#";
  // const handleContinue = () => {
  //   if (isClicked) {
  //     window.location.href = "/editor";
  //   } else if (isClicked2) {
  //     window.location.href = "/login2";
  //   }
  // };
  const handlemove = () => {
    if(isClicked){
      window.location.href= "/editor"
    }
    else if(isClicked2){
      window.location.href= "/newpost"
    }
  }
  const data = [
    {
      t1: "How to write content about your photographs",
      t2: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet...",
    },
    {
      t1: "How to write content about your photographs",
      t2: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet...",
    },
    {
      t1: "How to write content about your photographs",
      t2: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet...",
    },
    {
      t1: "How to write content about your photographs",
      t2: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet...",
    },
    {
      t1: "How to write content about your photographs",
      t2: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet...",
    },
    {
      t1: "How to write content about your photographs",
      t2: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet...",
    },
    {
      t1: "How to write content about your photographs",
      t2: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet...",
    },
    {
      t1: "How to write content about your photographs",
      t2: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet...",
    },
  ];

  return (
    <div>
      <div className="ml-10 mt-20">
        <div className="table_head">
          <div className="tabs_pad">
            <NavLink
              onClick={handleactive}
              className={active ? "nav_table_active" : "nav_table"}
            >
              All<span className="badge bg-light badge_txt ml-8">25</span>
            </NavLink>
            <NavLink
              onClick={handleactive1}
              className={active1 ? "nav_table_active ml-30" : "nav_table ml-30"}
            >
              Blogs<span className="badge bg-success ml-8 badge_txt1">25</span>
            </NavLink>
            <NavLink
              onClick={handleactive2}
              className={active2 ? "nav_table_active ml-30" : "nav_table ml-30"}
            >
              Support Articles
              <span className="badge ml-8 badge_bg badge_txt1">25</span>
            </NavLink>
            <NavLink
              onClick={handleactive3}
              className={active3 ? "nav_table_active ml-30" : "nav_table ml-30"}
            >
              Draft<span className="badge badge_bg1 ml-8 badge_txt1">25</span>
            </NavLink>
          </div>
        </div>
        <div className="bg-transparent">
          <div className="dropdown">
            {/* <button className='btn btn-primary dropdown-toggle'>Activity</button> */}
            <div className="d-flex justify-content-end">
              <div className="mt-20">
                <img
                  type="button"
                  src="edit_post.svg"
                  alt="edit"
                  className="post_icn"
                />
                <img
                  type="button"
                  src="trash_post.svg"
                  alt="delete"
                  className="post_icn ml-12"
                />
              </div>
              <button
                data-bs-target="#MemberModal"
                data-bs-toggle="modal"
                className="post_btn"
              >
                +Add Post
              </button>
            </div>
          </div>
          {/* =============All Tab content Starts Here============= */}
          {active && (
            <div className="d-flex ">
              <PostsCard data={data} />
            </div>
          )}

          {/* =============All Tab content Ends Here============= */}

          {/* =============Blogs Tab content Starts Here============= */}
          {active1 && (
            <div className="d-flex ">
              <PostsCard data={data} />
            </div>
          )}

          {/* =============Blogs Tab content Ends Here============= */}

          {/* =============Support Articles Tab content Starts Here============= */}
          {active2 && (
            <div className="d-flex ">
              <PostsCard data={data} />
            </div>
          )}

          {/* =============Support Articles Tab content Ends Here============= */}

          {/* =============Draft Tab content Starts Here============= */}
          {active3 && (
            <div className="d-flex ">
              <PostsCard data={data} />
            </div>
          )}

          {/* =============Draft Tab content Ends Here============= */}
        </div>
      </div>
      <PostPagination />
      {/* =============Modal Starts Here============= */}

      <div
        className="modal fade "
        id="MemberModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered MemberModal_size">
          <div className="modal-content modal_radius">
            {/* <div className="modal-header"> */}
            <div className="d-flex justify-content-end mr-16">
              <img
                className="cross_icn mt-16"
                type="button"
                data-bs-dismiss="modal"
                src="cross_icn.svg"
                alt="cancel_btn"
              />
            </div>
            <div className="modaltxt font-family">Post Type</div>

            {/* </div> */}
            
            <div className="font-family text-center posts_txt mt-8">Select post type to continue</div>
            <div>
              <div className="d-flex mt-32">
                <div
                  onClick={cardClick}
                  type="button"
                  className={isClicked ? "admin ml-30" : "sales ml-30"}
                >
                  <img
                    className="admin_logo"
                    alt="logo"
                    src={isClicked ? "blog.svg" : "blog-Unchecked.svg"}
                  />
                  <div className={isClicked ? "admin_txt" : "admin_txt1"}>
                    Blog
                  </div>
                </div>
                <div
                  onClick={cardClick2}
                  type="button"
                  className={isClicked2 ? "admin ml-12" : "sales ml-12"}
                >
                  <img
                    className="admin_logo"
                    alt="logo"
                    src={isClicked2 ? "Support-Checked.svg" : "support.svg"}
                  />
                  <div className={isClicked2 ? "admin_txt" : "admin_txt1"}>
                    Support
                  </div>
                </div>
              </div>
              {/* <Link to='/login1'> */}
              
              <button
                disabled={!anyConditionTrue}
                className="add_btn_modal font-family mb-32"
                onClick={handlemove}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* =============Modal Starts Here============= */}
    </div>
  );
}
