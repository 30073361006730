import React, { useState } from "react";


export default function AddMemberModal() {



  const [isClicked, setisClicked] = useState(false);
  const cardClick = () => {
    setisClicked(true);
    setisClicked2(false);
    setisClicked3(false);
    setisClicked4(false);
  };
  const [isClicked2, setisClicked2] = useState(false);
  const cardClick2 = () => {
    setisClicked(false);
    setisClicked2(true);
    setisClicked3(false);
    setisClicked4(false);
  };
  const [isClicked3, setisClicked3] = useState(false);
  const cardClick3 = () => {
    setisClicked(false);
    setisClicked2(false);
    setisClicked3(true);
    setisClicked4(false);
  };
  const [isClicked4, setisClicked4] = useState(false);
  const cardClick4 = () => {
    setisClicked(false);
    setisClicked2(false);
    setisClicked3(false);
    setisClicked4(true);
  };

  const anyConditionTrue = isClicked || isClicked2 || isClicked3 || isClicked4;
  return (
    <div>
        {/* =============Modal Starts Here============= */}

        <div
        className="modal fade "
        id="MemberModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered MemberModal_size">
          <div className="modal-content">
            {/* <div className="modal-header"> */}
            <div className="d-flex justify-content-end mr-16">
              <img
                className="cross_icn mt-16"
                type="button"
                data-bs-dismiss="modal"
                src="cross_icn.svg"
                alt="cancel_btn"
              />
            </div>
            <div className="modaltxt font-family">Add Member</div>

            {/* </div> */}
            <div className="d-flex justify-content-center mt-24">
              <img
                className="Modal_profile"
                src="Modal_profile.svg"
                alt="profile_picture"
              />
              <img
                className="camera_icn"
                src="camera_icn.svg"
                alt="profile_picture"
              />
            </div>
            <div>
              <div className="email_text mt-24 ml-30 font-family">
                Full Name
              </div>
              <div className="input_size ml-30 mt-7">
                <div className="d-flex">
                  <img
                    className="User_icon img-fluid"
                    src="User_icn.svg"
                    alt=""
                  />
                  <input
                    className="input_email"
                    type="email"
                    required
                    placeholder="Enter Your Full Name"
                    //   value={email}
                    //   onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="email_text mt-12 ml-30 font-family">Email Id</div>
              <div className="input_size ml-30 mt-7">
                <div className="d-flex">
                  <img
                    className="email_icon img-fluid"
                    src="vector2.svg"
                    alt=""
                  />
                  <input
                    className="input_email"
                    type="email"
                    required
                    placeholder="Enter Your Email"
                    //   value={email}
                    //   onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="font-family ml-30 role_txt mt-8">Assign Role:</div>
            <div>
              <div className="d-flex mt-8">
                <div
                  onClick={cardClick}
                  type="button"
                  className={isClicked ? "admin ml-30" : "sales ml-30"}
                >
                  <img
                    className="admin_logo"
                    alt="logo"
                    src={isClicked ? "admin.svg" : "Admin-Unchecked.svg"}
                  />
                  <div className={isClicked ? "admin_txt" : "admin_txt1"}>
                    Admin
                  </div>
                </div>
                <div
                  onClick={cardClick2}
                  type="button"
                  className={isClicked2 ? "admin ml-12" : "sales ml-12"}
                >
                  <img
                    className="admin_logo"
                    alt="logo"
                    src={isClicked2 ? "Sales-Checked.svg" : "dollar.svg"}
                  />
                  <div className={isClicked2 ? "admin_txt" : "admin_txt1"}>
                    Sales
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div
                  onClick={cardClick3}
                  type="button"
                  className={
                    isClicked3 ? "admin ml-30 mt-12" : "sales ml-30 mt-12"
                  }
                >
                  <img
                    className="admin_logo"
                    alt="logo"
                    src={isClicked3 ? "Content-Checked.svg" : "content.svg"}
                  />
                  <div className={isClicked3 ? "admin_txt" : "admin_txt1"}>
                    Content
                  </div>
                </div>
                <div
                  onClick={cardClick4}
                  type="button"
                  className={
                    isClicked4 ? "admin ml-12 mt-12" : "sales ml-12 mt-12"
                  }
                >
                  <img
                    className="admin_logo"
                    alt="logo"
                    src={isClicked4 ? "Support-Checked.svg" : "support.svg"}
                  />
                  <div className={isClicked4 ? "admin_txt" : "admin_txt1"}>
                    Support
                  </div>
                </div>
              </div>
              {/* <Link to='/login1'> */}
              {isClicked4 ? (
                <div>
                  <select className="select_role ml-30 mt-12">
                    <option>Select Role</option>
                    <option>Live Chat</option>
                    <option>Ticket Support</option>
                  </select>
                </div>
              ) : null}
              <button
                disabled={!anyConditionTrue}
                className="add_btn_modal font-family mb-32"
                // onClick={}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* =============Modal Starts Here============= */}
    </div>
  )
}
