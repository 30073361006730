





import React, { useState } from 'react'
import "../../css/sale.css"
import SaleSidebar from '../SaleComponents/SaleSidebar'
import NavbarAvatar from '../ContentComponent/NavbarAvatar'
import { useNavigate } from 'react-router-dom';
import RangeCalender from '../SaleComponents/RangeCalender';


export default function SalePlanEdit() {
  const [isToggle,setIsToggle]=useState(true);
  const navigate=useNavigate();
  function togglesidebar()
  {
    setIsToggle(!isToggle);
  }

  return (
    <div className='d-flex'>

            {/* sidebar  */}

            <SaleSidebar isToggle={isToggle}/>

          
          
            <div className='container-fluid sale-content ' style={{ marginLeft: isToggle ? '20vw' : '0vw' }}  >
              {/* navbar  */}
            
              <NavbarAvatar togglesidebar={togglesidebar}/>

              {/* content parent container  */}
              <div className='row all-content-container'>
              <div className='col-md-12'>

             
              
                <div className=' plan-edit-container ml-20 mr-20 mt-40 pb-32 mb-40'>
               
               {/* =====    row1    === */}
            <div className="newInvoice-header d-flex">

             
              <div className='heading-invoive-container d-flex gap-2 ml-40 mt-40 ' > 
              <span className='plus-img-box'>

              <img src="/images/plus-img.svg" alt="plus-img" className='plus-img' />  
              </span>
             <span className='heading-invoice'>New Plan</span> 
              </div>

              <div className=' mr-40 mt-40 d-flex gap-4 ms-auto'> 
             
              <div className='sale-white-btn' onClick={()=>navigate('/saleplans')}>close</div>
              <div className='sale-blue-btn'>download</div>


            
              </div>


            </div>
            {/* row 1 end  */}


            

            {/* row 2  */}
            <div className='newInvoice-content  mt-40 ml-74 mr-74'>
              <div className='row1-col-1 d-flex flex-column'>
                <p className='paln-edit-heading'>Plan info:-</p>
                <input type='text' placeholder='Name' className='invoice-inputbox-style '/>
                <input type='text' placeholder='Price'className='invoice-inputbox-style mt-8' />
                <p className='paln-edit-heading mt-30'>Plan Duration:-</p>
                <div className='d-flex justify-content-between'>
                    <p className='plan-light-text'>Monthly</p>
                    <div class="form-check form-switch ">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>
                <div className='d-flex justify-content-between'>
                    <p className='plan-light-text'>Quarterly</p>
                    <div class="form-check form-switch ">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>
                <div className='d-flex justify-content-between'>
                    <p className='plan-light-text'>Yearly</p>
                    <div class="form-check form-switch">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>
               
              </div>
              <div className='row1-col-2 d-flex flex-column'>
              <p className='paln-edit-heading'>discount info:-</p>
              <input type='text' placeholder='Amount' className='invoice-inputbox-style' />
              {/* <input type='text' placeholder='Duration' className='invoice-inputbox-style mt-8' />
               */}
              {/* <div className='border border-dark'> */}
               <RangeCalender/>
               {/* </div> */}
              


              <p className='paln-edit-heading mt-30'>Applicable Plan:-</p>
              <div className='d-flex justify-content-between'>
                    <p className='plan-light-text'>Monthly</p>
                    <div class="form-check form-switch ">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>
                <div className='d-flex justify-content-between'>
                    <p className='plan-light-text'>Quarterly</p>
                    <div class="form-check form-switch ">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>
                <div className='d-flex justify-content-between'>
                    <p className='plan-light-text'>Yearly</p>
                    <div class="form-check form-switch ">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>

               

                
               
              </div>
   
            </div>
            {/* =====    row2 ends   === */}

            {/* =====    row3   === */}
            <div className='ml-74 mr-74 mt-32'>
                <div className='paln-edit-heading'>Pause Plan:-</div>
                <div className='d-flex justify-content-between align-items-center'>
                    <p className='plan-edit-txt mt-24 '>Pausing plan will temporary halt the plan and resume it when you want.</p>
                   
                   
                    <div class="form-check form-switch ">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                
                </div>
                <div className='paln-edit-heading mt-32'>Plan Features:-</div>

            </div>

            {/* =====    row3 ends   === */}



            {/* =====    row4  === */}
            <div className='plan-toggle-area ml-74 mr-74 mt-20'>
                <div className='toggle-head'>
                    <span className='toggle-heading ml-40'>Correctness</span>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Grammar, selling & punctuation</p>
                <div class="form-check form-switch mr-40 ">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Consistency in spelling & punctuation</p>
                <div class="form-check form-switch mr-40">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Swedish Fluency</p>
                <div class="form-check form-switch mr-40">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>

                {/* clarity  */}
                <div className='toggle-head mt-20'>
                    <span className='toggle-heading ml-40'>Clarity</span>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Conciseness</p>
                <div class="form-check form-switch mr-40">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Full-sentence rewrites</p>
                <div class="form-check form-switch mr-40">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Formatting</p>
                <div class="form-check form-switch mr-40">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>

                {/* Engagement  */}
                <div className='toggle-head mt-20'>
                    <span className='toggle-heading ml-40'>Engagement</span>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Word Choice</p>
                <div class="form-check form-switch mr-40">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Word & Sentence Variety</p>
                <div class="form-check form-switch mr-40">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>


                {/* Delivery  */}
                <div className='toggle-head mt-20'>
                    <span className='toggle-heading ml-40'>Delivery </span>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Tone Detection</p>
                <div class="form-check form-switch mr-40">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Tone Suggestions</p>
                <div class="form-check form-switch mr-40">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Sound Confident</p>
                <div class="form-check form-switch mr-40">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Sound Friendly</p>
                <div class="form-check form-switch mr-40">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Sound Diplomatic</p>
                <div class="form-check form-switch mr-40">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Sound Constructive</p>
                <div class="form-check form-switch mr-40">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Sound Sincere</p>
                <div class="form-check form-switch mr-40">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Sound Formal</p>
                <div class="form-check form-switch mr-40">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Inclusive Language</p>
                <div class="form-check form-switch mr-40">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>
                

                {/* Plagiarism */}
                <div className='toggle-head mt-20'>
                    <span className='toggle-heading ml-40'>Plagiarism</span>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Plagiarism Detection</p>
                <div class="form-check form-switch mr-40">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>

                {/* Security */}
                <div className='toggle-head mt-20'>
                    <span className='toggle-heading ml-40'>Security</span>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Enterprise-grade privacy & security</p>
                <div class="form-check form-switch mr-40">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Account roles & permissions</p>
                <div class="form-check form-switch mr-40">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>

                {/* Team Features */}
                <div className='toggle-head mt-20'>
                    <span className='toggle-heading ml-40'>Team Features</span>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Style Guide</p>
                <div class="form-check form-switch mr-40">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>
                <div className='toggle-row d-flex align-items-center justify-content-between'>
                <p className='plan-edit-txt mt-24 ml-40 '>Brand Tones</p>
                <div class="form-check form-switch mr-40">
                     <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"/>
                     </div>
                </div>



                

                

            </div>


            {/* =====    row4 ends   === */}






           






          
            
            
            </div>
            {/* =====    row1 ends   === */}

           



              </div>
              </div>
              

            </div>
       
      
    </div>
  )
}
