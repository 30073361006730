
import React from 'react';
import { ResponsiveLine } from '@nivo/line';


const SaleChartDaily = () => {

  const data = [
    {
        id: "Custom",
        // color: pink,
        data: [
          {
            x: "Mon",
            y: 0,
          },
          {
            x: "Tue",
            y: 9000,
          },
          {
            x: "Wed",
            y: 4000,
          },
          {
            x: "Thur",
            y: 4000,
          },
          {
            x: "Fri",
            y: 4500,
          },
          {
            x: "Sat",
            y: 4500,
          },
          {
            x: "Sun",
            y: 6500,
          },
        ],
      },
    
      {
          id: "Bussiness",
        //   color: orange,
          data: [
            {
              x: "Mon",
              y: 4000,
            },
            {
              x: "Tue",
              y: 2000,
            },
            {
              x: "Wed",
              y: 1000,
            },
            {
              x: "Thur",
              y: 2000,
            },
            {
              x: "Fri",
              y: 3500,
            },
            {
              x: "Sat",
              y: 1500,
            },
            {
              x: "Sun",
              y: 1500,
            },
          ],
        },
        
          {
            id: "premium",
            // color: green,
            data: [
              {
                x: "Mon",
                y: 4500,
              },
              {
                x: "Tue",
                y: 3000,
              },
              {
                x: "Wed",
                y: 4000,
              },
              {
                x: "Thur",
                y: 2000,
              },
              {
                x: "Fri",
                y: 3500,
              },
              {
                x: "Sat",
                y: 6500,
              },
              {
                x: "Sun",
                y: 1500,
              },
            ],
          },
          {
            id: "free",
            // color: "blue",
            data: [
              {
                x: "Mon",
                y: 1000,
              },
              {
                x: "Tue",
                y: 10000,
              },
              {
                x: "Wed",
                y: 10000,
              },
              {
                x: "Thur",
                y: 4000,
              },
              {
                x: "Fri",
                y: 1500,
              },
              {
                x: "Sat",
                y: 6500,
              },
              {
                x: "Sun",
                y: 4500,
              },
            ],
          }
  ];

  const yTicks = [0, 5000, 10000, 15000,20000, 25000];
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 34, right: 20, bottom: 25, left: 35 }}
  
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: true,
        reverse: false
      }}
 
      yFormat=" >-.0f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        format: value => `${value / 1000}k`,
        tickValues : yTicks

      }}
      
      pointSize={6}
      pointColor={'white'}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor', modifiers: [] }}
      pointLabelYOffset={-12}
      useMesh={true}
      enableGridX={false}
      colors={['#DD6CD9','#DD6C6C','#419958','#3F4299']}
      
      legends={[
        {
            anchor: 'top-left',
            direction: 'row',
            justify: false,
            translateX: 40,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 75,
            itemHeight: -50,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
                {
                    on: 'hover',
                    style: {
                        itemBackground: 'rgba(0, 0, 0, .03)',
                        itemOpacity: 1
                    }
                }
            ]
        }
    ]}

      
      
    />
  );
};

export default SaleChartDaily;
