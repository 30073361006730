


import React, { useState } from 'react'
import "../../css/sale.css"
import SaleSidebar from '../SaleComponents/SaleSidebar'
import NavbarAvatar from '../ContentComponent/NavbarAvatar'
import Chart2 from '../DashboardComponent/Chart2';
import { NavLink } from "react-router-dom";

import MuiTable from '../DashboardComponent/MuiTable';
import SmallCard from '../SaleComponents/SmallCard';
import InvoiceTable from '../SaleComponents/InvoiceTable';


export default function Invoice() {
  const [isToggle,setIsToggle]=useState(true);
  function togglesidebar()
  {
    setIsToggle(!isToggle);
  }

 
  return (
    <div className='d-flex'>

            {/* sidebar  */}

            <SaleSidebar isToggle={isToggle}/>

          
          
            <div className='container-fluid sale-content ' style={{ marginLeft: isToggle ? '20vw' : '0vw' }}  >
              {/* navbar  */}
            
              <NavbarAvatar togglesidebar={togglesidebar}/>

              {/* content parent container  */}
              <div className='row all-content-container'>
              <div className='col-md-12'>

             
              
               {/* =====    row1    === */}
                <div className='d-flex justify-content-between ml-20 mr-20'>
            <div className="font-family mt-32  dash_text">Invoices</div>
            <div className='content_txt font-family mt-32'>Sales > Invoices</div>
            </div>
            {/* =====    row1 ends   === */}

        

             {/* small cards row 3 */}
             <div className="mt-20 ml-20 mr-20 ">
              <div className="pad_none">
                <div className="d-flex justify-content-between">
                  {/* 1 */}
                  <SmallCard title={'Invoices: Today'} amount={'15,565'} amountgraph={'graph_line.svg'} perImg={'increasing_icon.svg'} perAmount={'+5%'} perTxt={'than last month'} />
                  
                  {/* 2 */}

                  <SmallCard title={'Invoices: Total'} amount={'10,279'} amountgraph={'graph_line1.svg'} perImg={'decreasing_icon.svg'} perAmount={'-2%'} perTxt={'than last month'} />


                  {/* 3 */}
                {/* <div className="d-flex ml-20"> */}

                <SmallCard title={'Invoices: Pending'} amount={'5,103'} amountgraph={'graph_line1.svg'} perImg={'decreasing_icon.svg'} perAmount={'-10%'} perTxt={'than last month'} />

                
                  {/* 4 */}

                <SmallCard title={'Invoices: Paid'} amount={'2,475'} amountgraph={'graph_line.svg'} perImg={'increasing_icon.svg'} perAmount={'+4%'} perTxt={'than last month'} />

                {/* </div> */}
                </div>
              </div>
            </div>
            {/* small cards row 2 ends */}

            {/* user table  */}
            {/* <DashboardTable/> */}
            {/* <MuiTable/> */}
            <InvoiceTable/>
            {/* user table ends */}



              </div>
              </div>
              

            </div>
       
      
    </div>
  )
}

