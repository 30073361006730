import React from "react";
import { Link } from "react-router-dom";

export default function PasswordChanged() {
  return (
    <div>
      <div className="select_background">
      <div>
          <img src="login_bottom.svg" alt="bottom-pic" className="bot_img" />
          <img src="login_top.svg" alt="top-pic" className="top_img" />
          </div>
        <div className="align_1">
        <div className="reset_card">
          <div className="logo_positioning">
            <img
              className="select_type_logo"
              alt="logo"
              src="select_type_logo.svg"
            />
          </div>
          <div className="font-family font_user mt-24">Password Changed</div>
          <div className="font-family font_user1 mt-8 ml-32 mr-32">
          Password changed successfully
          </div>
          
          <Link to='/login1'>
            <button className="reset_btn font-family mt-32">Log In</button>
          </Link>
        </div>
        </div>
      </div>
    </div>
  );
}
