import "../Dashboard/Dashboard.css";
import { NavLink } from "react-router-dom";
import React, { useState } from "react";

export default function PostsSidebar() {
    const [Active, isActive] = useState(false)
  const handleActive = () => {
    isActive(true)
    isActive1(false)
    isActive2(false)
    isActive3(false)
  }
  const [Active1, isActive1] = useState(false)
  const handleActive1 = () => {
    isActive(false)
    isActive1(true)
    isActive2(false)
    isActive3(false)
  }
  const [Active2, isActive2] = useState(true)
  const handleActive2 = () => {
    isActive(false)
    isActive1(false)
    isActive2(true)
    isActive3(false)
  }
  const [Active3, isActive3] = useState(false)
  const handleActive3 = () => {
    isActive(false)
    isActive1(false)
    isActive2(false)
    isActive3(true)
  }
  return (
    <div>
        <div className="">
        <img
          className="grammefy_logo ml-62 mt-32 mr-62"
          src="Grammefy_white_logo.svg"
          alt="Grammefy_Logo"
        />
        <div className="row">
          <div className="col d-flex ">
            <div className="d-flex profile_bg ml-34 mt-40">
              <div className="profile_round ml-38">
                <img
                  className="rounded-circle profile_logo"
                  src="profile.jpeg"
                  alt=""
                />
              </div>
              <div className="">
                <div className="profile_text">Robert Fox</div>
                <div className="profile_text1 ">Admin</div>
              </div>
            </div>
          </div>
        </div>
        <ul className="nav d-flex flex-column">
          <div onClick={handleActive} className={Active ? "navlinks_size bg_links mt-40 ml-34" : "navlinks_size  mt-40 ml-34"}>
            <NavLink to="/dashboard" className='decoration_none'>
              <div
                className="row ppc abc"
                id="account"
                title="Dashboard"
              >
                <div className="col d-flex justify-content-center align-items-center aab">
                  <img className="side_icon mt-16" src={Active ? "dash_icn.svg" : "dash_icn_white.svg"} alt="" />
                  <div className={Active ? "side_text" : "side_text1"}>Dashboard</div>
                </div>
              </div>
            </NavLink>
          </div>
          <div onClick={handleActive1} className={Active1 ? "navlinks_size bg_links mt-20 ml-34" : "navlinks_size mt-20 ml-34"}>
            <NavLink to="/content" className='decoration_none' >
              <div
                className="row ppc abc"
                id="account"
                title="Analytics"
              >
                <div className="col d-flex justify-content-center align-items-center aab">
                  <img className="side_icon mt-16" src={Active1 ? "analytics_icn_active.svg" : "analytics_icn.svg"} alt="" />
                  <div className={Active1 ? "side_text" : "side_text1"}>Analytics</div>
                </div>
              </div>
            </NavLink>
          </div>
          <div onClick={handleActive2} className={Active2 ? "navlinks_size bg_links mt-20 ml-34" : "navlinks_size mt-20 ml-34"}>
            <NavLink to="/posts" className='decoration_none'>
              <div
                className="row ppc abc"
                id="account"
                title="Posts"
              >
                <div className="col d-flex justify-content-center align-items-center aab">
                  <img className="side_icon mt-16" src={Active2 ? "post_icn_active.svg" : "post_icn.svg"} alt="" />
                  <div className={Active2 ? "side_text" : "side_text1"}>Posts</div>
                </div>
              </div>
            </NavLink>
          </div>
          <div onClick={handleActive3} className={Active3 ?  "navlinks_size bg_links mt-20 ml-34" : "navlinks_size mt-20 ml-34"}>
            <NavLink to="/feedback" className='decoration_none'>
              <div
                className="row ppc abc"
                id="account"
                title="Sales"
              >
                <div className="col d-flex justify-content-center align-items-center aab">
                  <img className="side_icon mt-16" src={Active3 ? "feedback_icn_active.svg" : "feedback_icn.svg"} alt="" />
                  <div className={Active3 ? "side_text" : "side_text1"}>Feedback</div>
                </div>
              </div>
            </NavLink>
          </div>
        </ul>
      </div>
    </div>
  )
}
