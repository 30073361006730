import React from 'react';
import { ResponsiveLine } from '@nivo/line';

const LineMonthly = () => {
  const color = ['#3f4299']
  const data = [
    {
      id: "norway",
      color: "hsl(231, 39%, 41%)",
      data: [
        {
          x: "Jan",
          y: 8000,
        },
        {
          x: "Feb",
          y: 20000,
        },
        {
          x: "Mar",
          y: 15000,
        },
        {
          x: "Apr",
          y: 13500,
        },
        {
          x: "May",
          y: 16500,
        },
        {
          x: "June",
          y: 14500,
        },
        {
          x: "July",
          y: 16500,
        },
        {
          x: "Aug",
          y: 18500,
        },
        {
          x: "Sep",
          y: 14000,
        },
        {
          x: "Oct",
          y: 17500,
        },
        {
          x: "Nov",
          y: 16500,
        },
        {
          x: "Dec",
          y: 17985,
        },
      ],
    },
  ];
  const tickValues = [0, 10000, 15000, 20000, 25000];
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 24, right: 20, bottom: 50, left: 45 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: true,
        reverse: false
      }}
      // lineColor="#ff0000"
      yFormat=" >-.0f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        // legend: 'transportation',
        legendOffset: 36,
        legendPosition: 'middle'
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        // legend: 'count',
        legendOffset: -40,
        legendPosition: 'middle',
        format: value => `${value / 1000}k`,
        tickValues : tickValues

      }}
      pointSize={8}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={1}
      pointBorderColor={ color }
      pointLabelYOffset={-12}
      useMesh={true}
      enableGridX={false}
      colors={['#3f4299']}
      
      
    />
  );
};

export default LineMonthly;
