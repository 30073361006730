import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import AddMemberModal from "../SaleComponents/AddMemberModal";

const columns = [
  { id: "name", label: "Name", minWidth: 150, align: "center" },
  { id: "email", label: "Email", minWidth: 170, align: "center" },
  {
    id: "team",
    label: "Team",
    minWidth: 120,
    align: "center",
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: "activity",
    label: "Activity",
    // minWidth: 170,
    align: "center",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "action",
    label: "Action",
    minWidth: 80,
    align: "center",
    format: (value) => value.toFixed(2),
  },
];

function Delete() {
  alert("delete");
}
function Edit() {
  alert("edit");
}
const rows = [
  {
    id: 1,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Sales Team",
    activity: "Online",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 2,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Content Team",
    activity: "Offline",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 3,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Content Team",
    activity: "Offline",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 4,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Content Team",
    activity: "Online",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 5,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Content Team",
    activity: "Offline",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 6,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Content Team",
    activity: "Offline",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 7,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Content Team",
    activity: "Offline",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 8,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Content Team",
    activity: "Offline",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 9,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Content Team",
    activity: "Offline",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 10,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Content Team",
    activity: "Offline",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 11,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Content Team",
    activity: "Online",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 12,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Content Team",
    activity: "Offline",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
];

export default function MuiTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [selectedActivity, setSelectedActivity] = React.useState("");

  const handleActivityChange = (event) => {
    setSelectedActivity(event.target.value);
  };

  const filteredRows = selectedActivity
    ? rows.filter((row) => row.activity === selectedActivity)
    : rows;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className="mt-20 ml-20 mr-20 ">
      <div className="table_head">
        <div className="tabs_pad d-flex">
          <div className="table-highlight-member pt-3 pb-3 ">
            <span>Members</span>{" "}
            <span className="badge bg-lightt badge_txt ml-8 ">
              {rows.length}
            </span>
          </div>
        </div>
      </div>

      <div className="dropdownTable  " style={{ background: "white" }}>
        {/* <button className='btn btn-primary dropdown-toggle'>Activity</button> */}
        <div className="d-flex align-items-center">
          {/* <div className="d-flex justify-content-around align-items-center border border-dark"> */}

          <select
            type="button"
            className="drp_btn text-muted mb-3"
            onChange={handleActivityChange}
            value={selectedActivity}
          >
            <option selected value="">
              <span className="text-muted">Activity</span>
            </option>
            <option value="Online">Online</option>
            <option value="Offline">Offline</option>
          </select>

          <button
                data-bs-target="#MemberModal"
                data-bs-toggle="modal"
                className="add_btn"
                style={{ margin: '0 0 0 0.933vw' }}

              >
                +Add Member
              </button>
          {/* </div> */}
              <AddMemberModal/>

          <TablePagination
            // rowsPerPageOptions={[10, 20,{ label: 'All', value: -1 }]}
            rowsPerPageOptions={[8, 20, { label: "All", value: -1 }]}
            sx={{ marginLeft: "auto", fontSize: "400 !important" }}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>

        <Paper
          className="mr-20 ml-20"
          sx={{
            width: "100%",
            height: "35.333vw",
            marginLeft: "auto",
            marginRight: "auto",
            paddingLeft: "1.333vw",
            paddingRight: "1.333vw",
            overflow: "hidden",
          }}
        >
          <TableContainer sx={{ maxHeight: "35.333vw" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      className="table-headd"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {column.label}
                        {column.label !== 'Action' && (
  <img
    src="data_icn.svg"
    alt="Column Icon"
    className="data_icn"
    style={{ marginLeft: "8px" }}
  />
)}
                      </div>
                      {/* {column.label} */}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, rowIndex) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={rowIndex}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ fontSize: "600 !important" }}
                            // className={column.id === 'activity' ? (value === 'Online' ? 'onlinecss' : 'offlinecss') : ''}
                          >
                            {column.id === "name" ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={value.img}
                                  alt={value.name}
                                  style={{ marginRight: "8px" }}
                                />
                                {value.name}
                              </div>
                            ) : column.id === "activity" ? (
                              <div className="activity-status">
                                {value === "Online" ? (
                                  <span className="onlinecss">Online</span>
                                ) : (
                                  <span className="offlinecss">Offline</span>
                                )}
                              </div>
                            ) : column.id === "action" ? (
                              <div className="action-cell">
                                {/* delimg:'trash_icn.svg',editimg:'edit_icn.svg' */}
                                <img
                                  src="trash_icn.svg"
                                  alt="Delete"
                                  className="trash_icn "
                                  style={{ cursor: "pointer" }}
                                  onClick={Delete}
                                />
                                <img
                                  src="edit_icn.svg"
                                  alt="Edit"
                                  className="trash_icn "
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "1.6vw",
                                  }}
                                  onClick={Edit}
                                />
                              </div>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
}
