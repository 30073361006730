import React from 'react'

export default function SmallCard(props) {
  return (
    <div>
       <div className="free_user_bg">
                    <div className="d-flex flex-column">
                        <div className="card-heading font-family">{props.title}</div>
                      <div>
                        <span className="card-amount">{props.amount}</span>
                        <span className="card-amount-graph"> <img src={props.amountgraph} alt="Graph" className='graphy-img'/> </span>
                       </div>
                      
                    <div>
                      <img
                        src={props.perImg}
                        alt="increasing"
                        className="increase_icon"
                      />
                      <span className="incr">{props.perAmount}</span>{" "}
                      <span className="incr1">{props.perTxt}</span>
                    </div>
                    </div>
                  </div>
    </div>
  )
}
