import { NavLink, useLocation, useNavigate } from "react-router-dom";
import React from "react";

export default function SaleSidebar({isToggle}) {
  

    const navigate=useNavigate();
    const location =useLocation();
    const invoicepaths = ["/saleinvoice", "/newInvoice","/invoivePreview"];
    const ticketpaths = ["/ticketview", "/saletickets"];
    const planpaths = ["/saleplans", "/saleplansedit"];
    const userpaths = ["/saleusers","/userdetail",'/usertrasaction'];


    // console.log('location',location);
   
  return (
    <div>
        {/* <h3>Sidebar</h3>     */}
        <div className={`sale_sidebar ${isToggle ? "open" : "closed"}`}> {/* apply bg color   */}
        <img onClick={()=>{navigate('/dashboard')}} style={{cursor:'pointer'}}
          className="sale_grammefy_logo ml-62 mt-32 mr-62"
          src="Grammefy_white_logo.svg"
          alt="Grammefy_Logo"
        />
        <div className="row">
          <div className="col d-flex ">
            <div className="d-flex profile_bg ml-34 mt-40">
              <div className="profile_round ml-38">
                <img
                  className="rounded-circle profile_logo"
                  src="profile.jpeg"
                  alt=""
                />
              </div>
              <div className="">
                <div className="profile_text">Robert Fox</div>
                <div className="profile_text1 ">Admin</div>
              </div>
            </div>
          </div>
        </div>
        <ul className="nav d-flex flex-column">
          <div 
          className={ "navlinks_size  mt-40 ml-34"}
          >
            <NavLink to="/dashboard" className='decoration_none'>
              <div
                className="row ppc abc"
                id="account"
                title="Dashboard"
              >
                <div className="col d-flex justify-content-center align-items-center aab">
                  <img className="side_icon mt-16" src={ "dash_icn_white.svg"} alt="" />
                  <div className={ "side_text1"}>Dashboard</div>
                </div>
              </div>
            </NavLink>
          </div>
          <div className={location.pathname === '/analytics' ? "navlinks_size bg_links mt-20 ml-34" : "navlinks_size  mt-20 ml-34"}>
            <NavLink to="/analytics" className='decoration_none' >
              <div
                className="row ppc abc"
                id="account"
                title="Analytics"
              >
                <div className="col d-flex justify-content-center align-items-center aab">
                  <img className="side_icon mt-16" src={location.pathname === '/analytics' ? "analytics_icn_active.svg" : "analytics_icn.svg"} alt="" />
                  <div className={location.pathname === '/analytics' ? "side_text" : "side_text1"}>Analytics</div>
                </div>
              </div>
            </NavLink>
          </div>
          <div 
          // onClick={handleActive2} 
          className={ invoicepaths.includes(location.pathname) ? "navlinks_size bg_links mt-20 ml-34" : "navlinks_size mt-20 ml-34"}>
            <NavLink to="/saleinvoice" className='decoration_none'>
              <div
                className="row ppc abc"
                id="account"
                title="Invoice"
              >
                <div className="col d-flex justify-content-center align-items-center aab">
                  <img className="side_icon mt-16" src={invoicepaths.includes(location.pathname) ? "/images/invoicedark.svg" : "/images/invoice.svg"} alt="" />
                  <div className={invoicepaths.includes(location.pathname) ? "side_text" : "side_text1"}>Invoices</div>
                </div>
              </div>
            </NavLink>
          </div>
          <div 
          // onClick={handleActive3} 
          className={ ticketpaths.includes(location.pathname)  ?  "navlinks_size bg_links mt-20 ml-34" : "navlinks_size mt-20 ml-34"}>
            <NavLink to="/saletickets" className='decoration_none'>
              <div
                className="row ppc abc"
                id="account"
                title="tickets"
              >
                <div className="col d-flex justify-content-center align-items-center aab">
                  <img className="side_icon mt-16" src={ ticketpaths.includes(location.pathname)  ? "/images/ticketdark.svg" : "/images/ticket.svg"} alt="" />
                  <div className={ ticketpaths.includes(location.pathname) ? "side_text" : "side_text1"}>Tickets</div>
                </div>
              </div>
            </NavLink>
          </div>
          <div 
          // onClick={handleActive4}
           className={planpaths.includes( location.pathname)  ?  "navlinks_size bg_links mt-20 ml-34" : "navlinks_size mt-20 ml-34"}>
            <NavLink to="/saleplans" className='decoration_none'>
              <div
                className="row ppc abc"
                id="account"
                title="plans"
              >
                <div className="col d-flex justify-content-center align-items-center aab">
                  <img className="side_icon mt-16" src={ planpaths.includes( location.pathname) ? "/images/admindark.svg" : "/images/admin.svg"} alt="" />
                  <div className={planpaths.includes( location.pathname) ? "side_text" : "side_text1"}>Plans</div>
                </div>
              </div>
            </NavLink>
          </div>
          <div 
          // onClick={handleActive5}
           className={userpaths.includes( location.pathname) ?  "navlinks_size bg_links mt-20 ml-34" : "navlinks_size mt-20 ml-34"}>
            <NavLink to="/saleusers" className='decoration_none'>
              <div
                className="row ppc abc"
                id="account"
                title="users"
              >
                <div className="col d-flex justify-content-center align-items-center aab">
                  <img className="side_icon mt-16" src={userpaths.includes( location.pathname) ? "/images/userdark.svg" : "/images/user.svg"} alt="" />
                  <div className={userpaths.includes( location.pathname) ? "side_text" : "side_text1"}>Users</div>
                </div>
              </div>
            </NavLink>
          </div>
        </ul>
      </div>
      
    </div>
  )
}
