


import React, { useState } from 'react'
import "../../css/sale.css"
import SaleSidebar from '../SaleComponents/SaleSidebar'
import NavbarAvatar from '../ContentComponent/NavbarAvatar'
import { useNavigate } from 'react-router-dom';


export default function NewInvoice() {
  const [isToggle,setIsToggle]=useState(true);
  const navigate=useNavigate();
  function togglesidebar()
  {
    setIsToggle(!isToggle);
  }

  return (
    <div className='d-flex'>

            {/* sidebar  */}

            <SaleSidebar isToggle={isToggle}/>

          
          
            <div className='container-fluid sale-content ' style={{ marginLeft: isToggle ? '20vw' : '0vw' }}  >
              {/* navbar  */}
            
              <NavbarAvatar togglesidebar={togglesidebar}/>

              {/* content parent container  */}
              <div className='row all-content-container'>
              <div className='col-md-12'>

             
              
               {/* =====    row1    === */}
                <div className=' Newinvoice-container ml-20 mr-20 mt-40 pb-32 mb-40'>
               
            <div className="newInvoice-header d-flex">

             
              <div className='heading-invoive-container d-flex gap-2 ml-40 mt-40 ' > 
              <span className='plus-img-box'>

              <img src="/images/plus-img.svg" alt="plus-img" className='plus-img' />  
              </span>
             <span className='heading-invoice'>New Invoice</span> 
              </div>

              <div className=' mr-40 mt-40 d-flex gap-4 ms-auto'> 
              <span className='inv-text'>Invoice #</span>
              <span className='invo-no'>42342</span>
              <div className='sale-white-btn' onClick={()=>navigate('/saleinvoice')}>close</div>
              <div className='sale-blue-btn'>download</div>


            
              </div>


            </div>

            <div className='newInvoice-content  mt-40 ml-104 mr-104'>
              <div className='row1-col-1 d-flex flex-column'>
                <p className='invoice-txt'>From:-</p>
                <input type='text' placeholder='Grammefy' className='invoice-inputbox-style '/>
                <input type='text' placeholder='Ginfo@company.com'className='invoice-inputbox-style mt-8' />
                <input type='text' placeholder='United States'className='invoice-inputbox-style mt-8' />
                <input type='text' placeholder='XYZ Delta Street' className='invoice-inputbox-style mt-8'/>
                <input type='text' placeholder='17655429949'className='invoice-inputbox-style mt-8' />

                <div className='invoive-detail-box ' style={{marginTop:'8vw'}}>
                  <p className='invoice-txt'>Invoice Details:-</p>
                  <input type='text' placeholder='11 NOv 2023'className='invoice-inputbox-style mt-8' />
                </div>
              </div>
              <div className='row1-col-2 d-flex flex-column'>
              <p className='invoice-txt'>Bill to:-</p>
              <input type='text' placeholder='Name' className='invoice-inputbox-style' />
              <input type='text' placeholder='Email' className='invoice-inputbox-style mt-8' />
                <input type='text' placeholder='Country' className='invoice-inputbox-style mt-8 mt-8'/>
                <input type='text' placeholder='State' className='invoice-inputbox-style mt-8'/>
                <input type='text' placeholder='Address' className='invoice-inputbox-style mt-8'/>
                <input type='text' placeholder='Zip Code' className='invoice-inputbox-style mt-8'/>
                <input type='text' placeholder='Phone' className='invoice-inputbox-style mt-8'/>

                  <input type='text' placeholder='Due Date' className='invoice-inputbox-style' style={{marginTop:'auto'}}/>
               
              </div>

            </div>

            {/* row 2  */}

           

<div className=' mt-20 ml-104 mr-104'>

<table className='table' >
    <thead>
      <tr className='cal-head'>
        <th className='cal-text ml-20 ' style={{borderTopLeftRadius:'0.267vw',verticalAlign: 'middle',paddingLeft:'1.333vw'}}>Description</th>
        <th className='cal-text'style={{ verticalAlign: 'middle' }}>Rate</th>
        <th className='cal-text'style={{ verticalAlign: 'middle' }}>Quantity</th>
        <th className='cal-text'style={{ verticalAlign: 'middle' }}>Discount</th>
        <th className='cal-text mr-20' style={{textAlign:'center',verticalAlign: 'middle',borderTopRightRadius:'0.267vw',paddingRight:'1.333vw'}}>Amount</th>
      </tr>
    </thead>
    <tbody className='cal-body' style={{height:'5.6vw'}}>
      <tr  >
        <td style={{ verticalAlign: 'middle' ,paddingLeft:'1.333vw'}}> <input type='text' placeholder='Item Description' className='item-description'/></td>
        <td style={{ verticalAlign: 'middle' }}><input type='number' placeholder='0' className='item-num' /></td>
        <td style={{ verticalAlign: 'middle' }}><input type='number' placeholder='0' className='item-num'/></td>
        <td style={{ verticalAlign: 'middle' }}><input type='number' placeholder='0' className='item-num' /></td>
        <td style={{textAlign:'center', verticalAlign: 'middle'}}><span className='newinvoice-row3-col2-txt'>1000$</span></td>
      </tr>
    </tbody>
  </table>
  </div>




        {/* last row */}

        <div className='newinvoice-row3 mt-20 ml-104 mr-104 '>
                <div className='newinvoice-row3-col1 d-flex flex-column'>
                  <p className='heading-invoice'>Account Details:-</p>
                  <input type='text' placeholder='Union Bank'  className='invoice-inputbox-style ' />
                  <input type='text' placeholder='27B6111763' className='invoice-inputbox-style mt-8 ' />

                  <input type='text'placeholder='XBLGVZTV'  className='invoice-inputbox-style mt-8' />

                  <input type='text' placeholder='United States' className='invoice-inputbox-style mt-8' />

                </div>
                <div className='newinvoice-row3-col2 '>
                  <span className='subtotal-div1  '>
                    <p className='newinvoive-light-txt'>Subtotal</p>
                    <p className='newinvoice-row3-col2-txt'>1000$</p>
                  </span>
                  <span className='subtotal-div1 mt-8'>
                    <p className='newinvoive-light-txt'>Discount</p>
                    <p className='newinvoice-row3-col2-txt'>0$</p>
                  </span>
                  <span className='subtotal-div3 top-border mt-20 pt-20'>
                    <p className='newinvoive-light-txt'>Total</p>
                    <p className='newinvoice-row3-col2-txt'>1000$</p>
                  </span>
                  <span className='subtotal-btn-box d-flex justify-content-between mt-8 pt-20'>
                   <span className='newinvoice-white-btn'onClick={()=>navigate('/invoivePreview')}>Preview</span>
                   <span className='newinvoice-blue-btn'>Send</span>
                   
                  </span>
                </div>
              </div>
          
            
            
            </div>
            {/* =====    row1 ends   === */}

           



              </div>
              </div>
              

            </div>
       
      
    </div>
  )
}
