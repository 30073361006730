import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

const EditorBar = () => {
  const editor = useEditor({
    extensions: [StarterKit],
    placeholder:"<p>Hi there</p>",
    content:
      "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis nisl cursus bibendum sit nulla accumsan sodales ornare. At urna viverra non suspendisse neque, lorem. Pretium condimentum pellentesque gravida id etiam sit sed arcu euismod. Rhoncus proin orci duis scelerisque molestie cursus tincidunt aliquam.</p>",
  });
  console.log("editor in tiptap", editor);

  return (
    <div className="outline_title">
      <EditorContent editor={editor} />
    </div>
  );
};

export default EditorBar;
