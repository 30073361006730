import React from "react";

export default function FeedbackTable() {

  return (
    <div>
      <div className="ml-20 mt-20">
        <div className="table_head">
          <div className="tabs_pad">
            <div className="nav_table">
              Feedbacks<span className="badge bg-light badge_txt ml-8">25</span>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="dropdown">
            {/* <button className='btn btn-primary dropdown-toggle'>Activity</button> */}
            <div className="d-flex justify-content-end mr-20">
              {/* <select type="button" className="drp_btn text-muted">
                <option disabled selected>
                  <span className="text-muted">Activity</span>
                </option>
                <option>Online</option>
                <option>Offline</option>
              </select> */}
              {/* <button
                data-bs-target="#MemberModal"
                data-bs-toggle="modal"
                className="add_btn"
              >
                +Add Member
              </button> */}
              <div className="justify-content-end d-flex">
                <div className="mt-16 font-family page_txt">Rows per page:</div>
                <select className="drp_page font-family page_txt">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                </select>
                <div className="font-family mt-16 ml-20 page_txt">
                  0-7 of 11
                </div>
                <div className=" mt-18 ml-16">
                  <img type="button" src="arrow_left.svg" alt="left page" />
                </div>
                <div className=" mt-18 ml-25">
                  <img type="button" src="arrow_right.svg" alt="right page" />
                </div>
              </div>
            </div>
          </div>
          {/* =============All Tab content Starts Here============= */}

          <div className="ml-20 mt-16 mr-20">
            <table className="table table-borderless">
              <thead className=" table_head1 font-family">
                <tr className="text-white text-center">
                  <th scope="col" className="head_pad1">
                    #id
                    <img className="data_icn" src="data_icn.svg" alt="icon" />
                  </th>
                  <th scope="col" className="head_pad1">
                    User
                    <img className="data_icn" src="data_icn.svg" alt="icon" />
                  </th>
                  <th scope="col" className="head_pad1">
                    Feedback
                    <img className="data_icn" src="data_icn.svg" alt="icon" />
                  </th>
                  <th scope="col" className="head_pad1">
                    Rating
                    <img className="data_icn" src="data_icn.svg" alt="icon" />
                  </th>
                  <th scope="col" className="head_pad1">
                    Action
                    <img className="data_icn" src="data_icn.svg" alt="icon" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center font-family">
                  <td className="head_pad2">#526587</td>
                  <td className="head_pad2">
                    <img
                      className="profile_avatar"
                      src="profile_avatar.svg"
                      alt="profile"
                    />
                    Esther Howard{" "}
                  </td>
                  <td className="head_pad2">
                    Amet minim mollit non deserunt ullamco.{" "}
                  </td>
                  <td className="head_pad2 justify-content-center">4.5</td>
                  <td className="head_pad2">
                    <img
                      type="button"
                      className="trash_icn"
                      src="trash_icn.svg"
                      alt="trash"
                    />
                    <img
                      data-bs-target="#MemberModal"
                      data-bs-toggle="modal"
                      type="button"
                      className="trash_icn ml-24"
                      src="document_icn.svg"
                      alt="trash"
                    />
                  </td>
                </tr>
                <tr className="text-center font-family">
                  <td className="head_pad2">#526587</td>
                  <td className="head_pad2">
                    <img
                      className="profile_avatar"
                      src="profile_avatar.svg"
                      alt="profile"
                    />
                    Esther Howard{" "}
                  </td>
                  <td className="head_pad2">
                    Amet minim mollit non deserunt ullamco.{" "}
                  </td>
                  <td className="head_pad2 justify-content-center">4.5</td>
                  <td className="head_pad2">
                    <img
                      type="button"
                      className="trash_icn"
                      src="trash_icn.svg"
                      alt="trash"
                    />
                    <img
                      type="button"
                      className="trash_icn ml-24"
                      src="document_icn.svg"
                      alt="trash"
                    />
                  </td>
                </tr>
                <tr className="text-center font-family">
                  <td className="head_pad2">#526587</td>
                  <td className="head_pad2">
                    <img
                      className="profile_avatar"
                      src="profile_avatar.svg"
                      alt="profile"
                    />
                    Esther Howard{" "}
                  </td>
                  <td className="head_pad2">
                    Amet minim mollit non deserunt ullamco.{" "}
                  </td>
                  <td className="head_pad2 justify-content-center">4.5</td>
                  <td className="head_pad2">
                    <img
                      type="button"
                      className="trash_icn"
                      src="trash_icn.svg"
                      alt="trash"
                    />
                    <img
                      type="button"
                      className="trash_icn ml-24"
                      src="document_icn.svg"
                      alt="trash"
                    />
                  </td>
                </tr>
                <tr className="text-center font-family">
                  <td className="head_pad2">#526587</td>
                  <td className="head_pad2">
                    <img
                      className="profile_avatar"
                      src="profile_avatar.svg"
                      alt="profile"
                    />
                    Esther Howard{" "}
                  </td>
                  <td className="head_pad2">
                    Amet minim mollit non deserunt ullamco.{" "}
                  </td>
                  <td className="head_pad2 justify-content-center">4.5</td>
                  <td className="head_pad2">
                    <img
                      type="button"
                      className="trash_icn"
                      src="trash_icn.svg"
                      alt="trash"
                    />
                    <img
                      type="button"
                      className="trash_icn ml-24"
                      src="document_icn.svg"
                      alt="trash"
                    />
                  </td>
                </tr>
                <tr className="text-center font-family">
                  <td className="head_pad2">#526587</td>
                  <td className="head_pad2">
                    <img
                      className="profile_avatar"
                      src="profile_avatar.svg"
                      alt="profile"
                    />
                    Esther Howard{" "}
                  </td>
                  <td className="head_pad2">
                    Amet minim mollit non deserunt ullamco.{" "}
                  </td>
                  <td className="head_pad2 justify-content-center">4.5</td>
                  <td className="head_pad2">
                    <img
                      type="button"
                      className="trash_icn"
                      src="trash_icn.svg"
                      alt="trash"
                    />
                    <img
                      type="button"
                      className="trash_icn ml-24"
                      src="document_icn.svg"
                      alt="trash"
                    />
                  </td>
                </tr>
                <tr className="text-center font-family">
                  <td className="head_pad2">#526587</td>
                  <td className="head_pad2">
                    <img
                      className="profile_avatar"
                      src="profile_avatar.svg"
                      alt="profile"
                    />
                    Esther Howard{" "}
                  </td>
                  <td className="head_pad2">
                    Amet minim mollit non deserunt ullamco.{" "}
                  </td>
                  <td className="head_pad2 justify-content-center">4.5</td>
                  <td className="head_pad2">
                    <img
                      type="button"
                      className="trash_icn"
                      src="trash_icn.svg"
                      alt="trash"
                    />
                    <img
                      type="button"
                      className="trash_icn ml-24"
                      src="document_icn.svg"
                      alt="trash"
                    />
                  </td>
                </tr>
                <tr className="text-center font-family">
                  <td className="head_pad2">#526587</td>
                  <td className="head_pad2">
                    <img
                      className="profile_avatar"
                      src="profile_avatar.svg"
                      alt="profile"
                    />
                    Esther Howard{" "}
                  </td>
                  <td className="head_pad2">
                    Amet minim mollit non deserunt ullamco.{" "}
                  </td>
                  <td className="head_pad2 justify-content-center">4.5</td>
                  <td className="head_pad2">
                    <img
                      type="button"
                      className="trash_icn"
                      src="trash_icn.svg"
                      alt="trash"
                    />
                    <img
                      type="button"
                      className="trash_icn ml-24"
                      src="document_icn.svg"
                      alt="trash"
                    />
                  </td>
                </tr>
                <tr className="text-center font-family">
                  <td className="head_pad2">#526587</td>
                  <td className="head_pad2">
                    <img
                      className="profile_avatar"
                      src="profile_avatar.svg"
                      alt="profile"
                    />
                    Esther Howard{" "}
                  </td>
                  <td className="head_pad2">
                    Amet minim mollit non deserunt ullamco.{" "}
                  </td>
                  <td className="head_pad2 justify-content-center">4.5</td>
                  <td className="head_pad2">
                    <img
                      type="button"
                      className="trash_icn"
                      src="trash_icn.svg"
                      alt="trash"
                    />
                    <img
                      type="button"
                      className="trash_icn ml-24"
                      src="document_icn.svg"
                      alt="trash"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* =============All Tab content Ends Here============= */}
        </div>
      </div>

      {/* =============Modal Starts Here============= */}

      <div
        className="modal fade "
        id="MemberModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered FeedbackModal_size">
          <div className="modal-content">
            <div className="d-flex justify-content-end mr-16">
              <img
                className="cross_icn mt-16"
                type="button"
                data-bs-dismiss="modal"
                src="cross_icn.svg"
                alt="cancel_btn"
              />
            </div>
            <div className="d-flex mt-10">
              <img
                src="star_active.svg"
                className="side_icn ml-24"
                alt="rate"
              />
              <img src="star_active.svg" className="side_icn ml-8" alt="rate" />
              <img src="star_active.svg" className="side_icn ml-8" alt="rate" />
              <img src="star_active.svg" className="side_icn ml-8" alt="rate" />
              <img
                src="star_inactive.svg"
                className="side_icn ml-8"
                alt="rate"
              />
            </div>
            <div className="ml-24 font-family feed_txt mt-16">
              Amet minim mollit non deserunt ullamco
            </div>
            <div className="feed_txt1 ml-24 font-family mr-12 mt-16">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.
              Exercitation veniam consequat sunt nostrud amet.
            </div>
            <div className="d-flex ml-24 mt-24 pb-30">
              <div className="profile_round_dashboard">
                <img
                  className="rounded-circle profile_logo"
                  src="profile.jpeg"
                  alt=""
                />
              </div>
              <div className="mt-8">
                <div className="profile_text">Esther Howard</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
