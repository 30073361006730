import React from "react";
import { Link } from "react-router-dom";

export default function ReleaseApp() {
  return (
    <div>
      <div className="">
        <img className="editor_bg" src="editor_bg.svg" alt="vector" />
        <div className="editor_head">
          <div className="container editor_pad d-flex justify-content-between">
          <img type="button" className="cross_icn_white" src="cross_icn_white.svg" alt="cancel" />
          <div className="d-flex">
            <button className="draft_btn font-family">Set as draft</button>
            <Link to="/posts" className="publish_lnk">
            <button className="publish_btn font-family ml-12">Publish</button>
            </Link>
            <div className="editor_brdr"></div>
            <div className="ml-20">
            <div className="editor_round">
                <img
                  className="rounded-circle profile_logo"
                  src="profile.jpeg"
                  alt=""
                />
              </div>
            </div>
          </div>
          </div>
          </div>
          <div className="container mt-32">
            <div className="d-flex">
            <img className="side_icn" src="header_img.svg" alt="header_img" />
            <div className="img_tag d-flex ml-12">
                <img className="img_tag_icn" src="cross_icn.svg" alt="cancel" />
                <div className="img_tag_txt font-fmaily ml-8">img.jpg</div>
            </div>
            </div>
            <div className="editor_txt1 font-family mt-24">We’ve officially released our new app!</div>
            <div className="img_tag d-flex">
                <img className="img_tag_icn" src="cross_icn.svg" alt="cancel" />
                <div className="img_tag_txt font-fmaily ml-8">Information Technology</div>
            </div>
            <div className="mt-24 editor_bg_color d-flex">
              <div className="">
              <img type="button" className="undo_icn" src="undo_icn.svg" alt="undo" />
              <img type="button" className="undo_icn" src="redo_icn.svg" alt="redo" />
              </div>
              <select className="bg-transparent text-white editor_select font-family">
                <option className="font-family text-black">Normal text</option>
                <option className="heading1 font-family">Heading 1</option>
                <option className="heading2 font-family">Heading 2</option>
                <option className="heading3 font-family">Heading 3</option>
              </select>
              <select className="bg-transparent text-white editor_select1 font-family">
                <option className="font-family text-black"></option>
                <option data-thumbnail="align_icn.svg" className="font-family text-black"><img src="align_left.svg" alt="center" />Center</option>
              </select>
              <img type="button" src="bold_icn.svg" alt="bold" />
              <img type="button" src="italic_icn.svg" alt="italic" />
              <img type="button" src="underline_icn.svg" alt="Underline" />
              <img type="button" src="strike_icn.svg" alt="Strike" />
              <img type="button" className="ulist_icn" src="ulist_icn.svg" alt="ul" />
              <img type="button" className="olist_icn" src="olist_icn.svg" alt="ol" />
              <img type="button" className="link_icn" src="link_icn.svg" alt="link" />
              <img type="button" className="image_icn" src="image_icn.svg" alt="upload" />
              <img type="button" className="image_icn" src="gif_icn.svg" alt="gif" />
            </div>
            <textarea className="typing_input font-family mt-32" placeholder="Type here..." />
            <img className="release_img" src="release_img.svg" alt="release" />
          </div>
      </div>
    </div>
  );
}
