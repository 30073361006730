// import logo from "../logo.svg";
import "../App.css";
import Login_1 from "./Login/Login_1";
import ForgotPass from "./Login/ForgotPass";
import SelectUser from "./Login/SelectUser";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ForgotPass2 from "./Login/ForgotPass2";
import ResetPass from "./Login/ResetPass";
import ResetPass2 from "./Login/ResetPass2";
import PasswordChanged from "./Login/PasswordChanged";
import Main_Dashboard from "./Dashboard/Main_Dashboard";
import Login_2 from "./Login/Login_2";
import Login_3 from "./Login/Login_3";
import Login_4 from "./Login/Login_4";
import Content_Dashboard from "./Dashboard/Content_Dashboard";
import Posts_Dashboard from "./Dashboard/Posts_Dashboard";
import PostEditor from "./Dashboard/PostEditor";
import ReleaseApp from "./Dashboard/ReleaseApp";
import NewPost from "./Dashboard/NewPost";
import FeedBack_Dashboard from "./Dashboard/FeedBack_Dashboard";
import SupportDashboard from "./Dashboard/SupportDashboard";
import TicketDashboard from "./Dashboard/TicketDashboard";
import UnassignedTicket from "./Dashboard/UnassignedTicket";
import OpenTicket from "./Dashboard/OpenTicket";
import CloseTicket from "./Dashboard/CloseTicket";
import LiveChatDashboard from "./Dashboard/LiveChatDashboard";
import Analytics from "./Sales/Analytics";
import Invoice from "./Sales/Invoice";
import LiveChatUnassignedTicket from "./Dashboard/LiveChatUnassignedTicket";
import LiveOpenTicket from "./Dashboard/LiveOpenTicket";
import LiveCloseTicket from "./Dashboard/LiveCloseTicket";
import NewInvoice from "./Sales/NewInvoice";
import InvoivePreview from "./Sales/InvoivePreview";
import Tickets from "./Sales/Tickets";
import TicketView from "./Sales/TicketView";
import SalePlan from "./Sales/SalePlan";
import SalePlanEdit from "./Sales/SalePlanEdit";
import Users from "./Sales/Users";
import UserDetails from "./Sales/UserDetails";
import UserTransaction from "./Sales/UserTransaction";

function App() {
  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SelectUser />} />
        <Route path="/login1" element={<Login_1/>} />
        <Route path="/login2" element={<Login_2/>} />
        <Route path="/login3" element={<Login_3/>} />
        <Route path="/login4" element={<Login_4/>} />
        <Route path="/forgot" element={<ForgotPass/>} />
        <Route path="/forgot2" element={<ForgotPass2/>} />
        <Route path="/reset" element={<ResetPass/>} />
        <Route path="/reset2" element={<ResetPass2/>} />
        <Route path="/changepass" element={<PasswordChanged/>} />
        <Route path="/dashboard" element={<Main_Dashboard/>} />
        <Route path="/content" element={<Content_Dashboard/>} />
        <Route path="/posts" element={<Posts_Dashboard/>} />
        <Route path="/editor" element={<PostEditor/>} />
        <Route path="/release" element={<ReleaseApp/>} />
        <Route path="/newpost" element={<NewPost/>} />
        <Route path="/feedback" element={<FeedBack_Dashboard/>} />
        <Route path="/support" element={<SupportDashboard/>} />
        <Route path="/ticket" element={<TicketDashboard />} />
        <Route path="/unassigned" element={<UnassignedTicket />} />
        <Route path="/openticket" element={<OpenTicket />} />
        <Route path="/liveopenticket" element={<LiveOpenTicket />} />
        <Route path="/closeticket" element={<CloseTicket />} />
        <Route path="/livecloseticket" element={<LiveCloseTicket />} />
        <Route path="/livechat" element={<LiveChatDashboard />} />
        <Route path="/chatticket" element={<LiveChatUnassignedTicket />} />
        <Route path="/analytics" element={<Analytics/>} />
        <Route path="/saleinvoice" element={<Invoice/>} />
        <Route path="/newInvoice" element={<NewInvoice/>} />
        <Route path="/invoivePreview" element={<InvoivePreview/>} />
        <Route path="/saletickets" element={<Tickets/>} />
        <Route path="/ticketview" element={<TicketView/>} />
        <Route path="/saleplans" element={<SalePlan/>} />
        <Route path="/saleplansedit" element={<SalePlanEdit/>} />
        <Route path="/saleusers" element={<Users/>} />
        <Route path="/userdetail" element={<UserDetails/>} />
        <Route path="/usertrasaction" element={<UserTransaction/>} />




      </Routes>
    </BrowserRouter>
  );
}

export default App;
