import React from 'react'
import { Dropdown } from "react-bootstrap";

export default function NavbarAvatar({togglesidebar}) {
  return (
    <div>
<div className='row nav-row'>
                <div className='col-md-12'>

            <div className="d-flex ">
              <img
                className="menu_icon mt-32 ml-22"
                src="menu_icon.svg"
                alt="Menu"
                onClick={togglesidebar}
                style={{ cursor: 'pointer'}}
              />

              <img
                className="sale-logout_icon mt-32 ms-auto"
                src="logout_icon.svg"
                alt="Logout"
              />

              <div className="ml-18 mt-12">
                <div className="profile_round_dashboard">
                <Dropdown>
      <Dropdown.Toggle variant="link" >
                  <img
                    className="rounded-circle profile_logo"
                    src="profile.jpeg"
                    alt=""
                  />
                  </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="#">Edit Profile</Dropdown.Item>
          <Dropdown.Item href="#">Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
                </div>
              </div>
            </div>
                </div>


              </div>













   


    </div>
  )
}
