import React from 'react'
import { ResponsivePie } from '@nivo/pie'

export default function Chart2() {
    const data = [
          {
            "id": "Premium",
            "label": "Premium",
            "value": 420,
            "color": "hsl(113, 70%, 50%)"
          },
          {
            "id": "Business",
            "label": "Business",
            "value": 140,
            "color": "hsl(179, 70%, 50%)"
          },
          {
            "id": "Custom",
            "label": "Custom",
            "value": 120,
            "color": "hsl(199, 70%, 50%)"
          },
          {
            "id": "Free",
            "label": "Free",
            "value": 530,
            "color": "hsl(234, 43%, 40%)"
          }
    ]
    const color = ['#419958', '#DD6C6C', '#DD6CD9', '#3f4299']
    return (
        

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

    <ResponsivePie
        data={data}
        margin={{ top: 30, right: 90, bottom: 120, left: 90 }}
        innerRadius={0.55}
        padAngle={1}
        activeOuterRadiusOffset={8}
        colors={ color }
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'brighter',
                    '0'
                ]
            ]
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#505050"
        arcLinkLabelsThickness={1}
        arcLinkLabelsColor={{ from: 'color' }}
        enableArcLabels={false}
        arcLabelsTextColor="#0d0d0d"
        
        
        legends={[
            {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 0,
                itemWidth: 70,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 12,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}
    />
)
  
}
