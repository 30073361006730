import React, { useState } from "react";
import "../../css/sale.css";
import SaleSidebar from "../SaleComponents/SaleSidebar";
import NavbarAvatar from "../ContentComponent/NavbarAvatar";
import SmallCard from "../SaleComponents/SmallCard";
import TicketTable from "../SaleComponents/SaleTicketTable";

export default function TicketView() {
  const [isToggle, setIsToggle] = useState(true);
  const [editable, setEditable] = useState("Unassigned");

  const handleStatusChange = (event) => {
    setEditable(event.target.value); // Update the state based on the selected option
  };

  function togglesidebar() {
    setIsToggle(!isToggle);
  }

  return (
    <div className="d-flex">
      {/* sidebar  */}

      <SaleSidebar isToggle={isToggle} />

      <div
        className="container-fluid sale-content "
        style={{ marginLeft: isToggle ? "20vw" : "0vw" }}
      >
        {/* navbar  */}

        <NavbarAvatar togglesidebar={togglesidebar} />

        {/* content parent container  */}
        <div className="row all-content-container">
          <div className="col-md-12">
            {/* =====    row1    === */}
            <div className="d-flex  mr-20 ml-20 mt-40 mb-40">
              <div className="msg-container">
                <div className="msg-header">
                  <img
                    className="ml-40 ticket_cross"
                    src="cross_icn.svg"
                    alt="cancel"
                  />
                  <div className="font-family ml-24 fnt16">#526587</div>
                  <div className="font-family ml-24 fnt16">
                    Need help! Would like to upgrade plan
                  </div>
                  <span className="ms-auto d-flex align-items-center">
                    {editable === "Open" && (
                      <div className="open_css mr-30">Open</div>
                    )}
                    {editable === "Close" && (
                      <div className="close_css mr-30">Close</div>
                    )}
                    {editable === "Unassigned" && (
                      <>
                        <div className="unassigne_css">Unassigned</div>
                        <img
                          type="button"
                          className="ml-10 mr-30 email_reply_icn"
                          onClick={() => setEditable("Open")}
                          src="email_reply_icn.svg"
                          alt="reply"
                        />
                      </>
                    )}
                  </span>
                </div>
                <div className="msg-body-box">
                  <div className="ticket-user-msg ml-24 mr-24 mt-32 ">
                    <div className="info-user d-flex align-items-center">
                      <img
                        className="avatar chat_icn"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      <span className="ml-18 msg_txt font-family">
                        Jacob Jones
                      </span>
                      <div className="font-family msg_txt1 ms-auto">
                        2 min ago
                      </div>
                    </div>
                    <div className="arrive-msg  font-family mt-20 ml-55 fnt16_light chat_width">
                      Hi,
                      <br /> Nulla Lorem mollit cupidatat irure. Laborum magna
                      nulla duis ullamco cillum dolor. Voluptate exercitation
                      incididunt aliquip deserunt reprehenderit elit laborum.
                      Nulla Lorem mollit cupidatat irure. Laborum magna nulla
                      duis ullamco cillum dolor. Voluptate exercitation
                      incididunt aliquip deserunt reprehenderit elit laborum.
                    </div>
                  </div>

                  {/* ========editor===== */}
                  {editable === "Open" && (
                    <div className="ticket-editor-container">
                      <span className="reply">Reply</span>
                      <div className="ticket-editor">
                        <div
                          className="d-flex align-items-center"
                          style={{ height: "5vw" }}
                        >
                          <div
                            className="d-flex justify-content-between pl-24 pr-24"
                            style={{ width: "100%" }}
                          >
                            <input
                              className="editor_input"
                              placeholder="Write your message"
                              style={{ width: "90%" }}
                            />
                            {/* <textarea cols='100' rows='3'></textarea> */}
                            <img
                              className="send_btn"
                              src="send_btn.svg"
                              alt="Send"
                            />
                          </div>
                        </div>
                        {/* ============= toolbar ================= */}
                        <div className="ticket-toolbar-box">
                          <img
                            type="button"
                            className="undo_ic ml-32 ticket-editor-big-icon"
                            src="undo_icn_black.svg"
                            alt="undo"
                          />
                          <img
                            type="button"
                            className="undo_ic ticket-editor-big-icon"
                            src="redo_icn_black.svg"
                            alt="redo"
                          />

                          <select className="bg-transparent editor-heading-txt font-family">
                            <option className="font-family text-black selected-heading">
                              Normal text
                            </option>
                            <option className="heading1 font-family selected-heading">
                              Heading 1
                            </option>
                            <option className="heading2 font-family selected-heading">
                              Heading 2
                            </option>
                            <option className="heading3 font-family selected-heading">
                              Heading 3
                            </option>
                          </select>
                          <select className="bg-transparent text-white editor-align-txt font-family">
                            <option
                              className="font-family text-black"
                              value="option1"
                            ></option>
                            <option
                              data-thumbnail="align_icn_black.svg"
                              className="font-family text-black"
                            >
                              <img src="/images/right_align.svg" alt="center" />
                              center
                            </option>
                            <option
                              data-thumbnail="align_icn_black.svg"
                              className="font-family text-black"
                            >
                              <img src="align_left_black.svg" alt="center" />
                              left
                            </option>
                          </select>

                          <img
                            type="button"
                            className="ticket-editor-icon"
                            src="bold_icn_black.svg"
                            alt="bold"
                          />
                          <img
                            type="button"
                            className="ticket-editor-icon"
                            src="italic_icn_black.svg"
                            alt="italic"
                          />
                          <img
                            type="button"
                            className="ticket-editor-icon"
                            src="underline_icn_black.svg"
                            alt="Underline"
                          />
                          <img
                            type="button"
                            className="ticket-editor-icon"
                            src="strike_icn_black.svg"
                            alt="Strike"
                          />
                          <img
                            type="button"
                            className="ulist_ic ticket-editor-big-icon"
                            src="ulist_icn_black.svg"
                            alt="ul"
                          />
                          <img
                            type="button"
                            className="olist_i ticket-editor-big-icon"
                            src="olist_icn_black.svg"
                            alt="ol"
                          />
                          <img
                            type="button"
                            className="link_ic ticket-editor-big-icon"
                            src="link_icn_black.svg"
                            alt="link"
                          />
                          <img
                            type="button"
                            className="image_ic ticket-editor-big-icon"
                            src="image_icn_black.svg"
                            alt="upload"
                          />
                          <img
                            type="button"
                            className="image_ic ticket-editor-big-icon"
                            src="gif_icn_black.svg"
                            alt="gif"
                          />
                          <img
                            type="button"
                            className="image_ic mr-12 ticket-editor-big-icon"
                            src="file_icn_black.svg"
                            alt="gif"
                          />
                        </div>
                        {/* ============= toolbar end ================= */}
                      </div>
                    </div>
                  )}

                  {/* ========editor ends===== */}
                </div>
              </div>
              <div className="leftsidebar-container ml-20">
                <div className=" user_details" style={{ width: "100%" }}>
                  <div className="unassign_card1 d-flex">
                    <div className="user_txt font-family">User Details</div>
                    <img className="user_bg" src="user_bg.svg" alt="logo" />
                  </div>
                  <div>
                    <img
                      className="avatar mt-16 ml-16"
                      src="profile_avatar.svg"
                      alt="profile"
                    />
                    <div className="font-family mt-8 ml-16 user_text">
                      Jacob Jones
                    </div>
                    <div className="font-family mt-8 ml-16 fnt12">
                      example@email.com
                    </div>
                    <div className="font-family mt-8 ml-16 fnt12_blue">
                      Free User
                    </div>
                  </div>
                </div>

                {/* Ticket Details */}
                <div
                  className="mt-16  ticket_details"
                  style={{ width: "100%" }}
                >
                  <div className="unassign_card1 d-flex">
                    <div className="user_txt font-family">Ticket Details</div>
                    <img className="user_bg" src="user_bg.svg" alt="logo" />
                  </div>
                  <div className="ml-16 mt-16 font-family fnt16">
                    Ticket Number:
                  </div>
                  <div className="ml-16 font-family fnt12_grey">526587</div>
                  <div className="ml-16 mt-16 font-family fnt16">
                    Ticket Subject:
                  </div>
                  <div className="ml-16 font-family fnt12_grey">
                    Need help! would like to upgrade my plan
                  </div>
                  <div className="ml-16 mt-16 font-family fnt16">
                    Ticket Date:
                  </div>
                  <div className="ml-16 font-family fnt12_grey">
                    10-Nov-2022
                  </div>
                  <div className="ml-16 mt-16 font-family fnt16">
                    Ticket Status:
                  </div>
                  <div className="ml-16 font-family">
                    <select value={editable} onChange={handleStatusChange} className="select_status">
                      <option value="Unassigned">Unassigned</option>
                      <option value="Open">Open</option>
                      <option value="Close">Close</option>
                    </select>
                  </div>
                  <div className="d-flex justify-content-center">

                  <button className="font-family button_ticket mt-16 ">
                    Update
                  </button>
                  </div>
                </div>

                {/* Timeline */}
                <div className="mt-16  timeline" style={{ width: "100%" }}>
                  <div className="unassign_card1 d-flex">
                    <div className="user_txt font-family">Timeline</div>
                    <img className="user_bg" src="user_bg.svg" alt="logo" />
                  </div>
                  <div className="overflow_y">

                    <div class="list-wrapper ml-16 pr-24">
                      <div class="red-line"></div>

                      <div class="list-item-wrapper">
                        <div class="list-bullet"></div>
                        <div class="list-item">
                          <div class="font-family fnt16">
                            Need help! would like to upgrade my plan
                          </div>
                          <div class="font-family fnt12_grey">10-Nov-2022 </div>
                          <div class="font-family fnt12_grey">
                            Status: Unassigned{" "}
                          </div>
                        </div>
                      </div>

                      <div class="list-item-wrapper">
                        <div class="list-bullet"></div>
                        <div class="list-item">
                          <div class="font-family fnt16">
                            Need help! would like to upgrade my plan
                          </div>
                          <div class="font-family fnt12_grey">10-Nov-2022 </div>
                          <div class="font-family fnt12_grey">
                            Status: Unassigned{" "}
                          </div>
                        </div>
                      </div>

                      <div class="list-item-wrapper">
                        <div class="list-bullet"></div>
                        <div class="list-item">
                          <div class="font-family fnt16">
                            Need help! would like to upgrade my plan
                          </div>
                          <div class="font-family fnt12_grey">10-Nov-2022 </div>
                          <div class="font-family fnt12_grey">
                            Status: Unassigned{" "}
                          </div>
                        </div>
                        <div class="white-line"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* =====    row1 ends   === */}
          </div>
        </div>
      </div>
    </div>
  );
}
