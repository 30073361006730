import React from 'react'

export default function PostPagination() {
  return (
    <div>
        
<div className="mt-32 justify-content-between">
<nav aria-label="Page navigation example pagination_width">
<ul className="pagination justify-content-center">
<li className="page-item disabled ">
<a className="page-link " href="#" tabindex="-1"><img src="arrow_left.svg" alt="left_icn" /></a>
</li>
<li className="page-item pagination_txt ml-8"><a className="page-link link_color" href="#">1</a></li>
<li className="page-item pagination_txt ml-8"><a className="page-link link_color" href="#">2</a></li>
<li className="page-item pagination_txt ml-8"><a className="page-link link_color" href="#">...</a></li>
<li className="page-item pagination_txt ml-8"><a className="page-link link_color" href="#">9</a></li>
<li className="page-item pagination_txt ml-8"><a className="page-link link_color" href="#">10</a></li>
<li className="page-item ml-8">
<a className="page-link" href="#"><img src="arrow_right.svg" /></a>
</li>
</ul>
</nav>
  </div>
    </div>
  )
}
