import React, { useState } from "react";
import "../Login/Login.css";
// import { Link } from "react-router-dom";

export default function SelectUser() {
  const [isClicked, setisClicked] = useState(false);
  const cardClick = () => {
    setisClicked(true);
    setisClicked2(false);
    setisClicked3(false);
    setisClicked4(false);
  };
  const [isClicked2, setisClicked2] = useState(false);
  const cardClick2 = () => {
    setisClicked(false);
    setisClicked2(true);
    setisClicked3(false);
    setisClicked4(false);
  };
  const [isClicked3, setisClicked3] = useState(false);
  const cardClick3 = () => {
    setisClicked(false);
    setisClicked2(false);
    setisClicked3(true);
    setisClicked4(false);
  };
  const [isClicked4, setisClicked4] = useState(false);
  const cardClick4 = () => {
    setisClicked(false);
    setisClicked2(false);
    setisClicked3(false);
    setisClicked4(true);
  };

  const anyConditionTrue = isClicked || isClicked2 || isClicked3 || isClicked4;
  // const toPath = anyConditionTrue ? "/Login1" : "#";
  const handleContinue = () => {
    if (isClicked) {
      window.location.href = "/login1";
    } else if (isClicked2) {
      window.location.href = "/login2";
    } else if (isClicked3) {
      window.location.href = "/login3";
    } else if (isClicked4) {
      window.location.href = "/login4";
    }
  };

  return (
    <>
      {/* <div className=""> */}
      <div className="select_background ">
        <div>
          <img src="login_bottom.svg" alt="bottom-pic" className="bot_img" />
          <img src="login_top.svg" alt="top-pic" className="top_img" />
          </div>
        <div className="align_1">
          <div className="user_card ">
            <div className="logo_positioning">
              <img
                className="select_type_logo"
                alt="logo"
                src="select_type_logo.svg"
              />
            </div>
            <div className="font-family font_user mt-24">User Type</div>
            <div className="font-family font_user1 mt-8">
              Select a user type to continue
            </div>
            <div className="d-flex mt-32">
              <div
                onClick={cardClick}
                type="button"
                className={isClicked ? "admin ml-30" : "sales ml-30"}
              >
                <img
                  className="admin_logo"
                  alt="logo"
                  src={isClicked ? "admin.svg" : "Admin-Unchecked.svg"}
                />
                <div className={isClicked ? "admin_txt" : "admin_txt1"}>
                  Admin
                </div>
              </div>
              <div
                onClick={cardClick2}
                type="button"
                className={isClicked2 ? "admin ml-12" : "sales ml-12"}
              >
                <img
                  className="admin_logo"
                  alt="logo"
                  src={isClicked2 ? "Sales-Checked.svg" : "dollar.svg"}
                />
                <div className={isClicked2 ? "admin_txt" : "admin_txt1"}>
                  Sales
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div
                onClick={cardClick3}
                type="button"
                className={
                  isClicked3 ? "admin ml-30 mt-12" : "sales ml-30 mt-12"
                }
              >
                <img
                  className="admin_logo"
                  alt="logo"
                  src={isClicked3 ? "Content-Checked.svg" : "content.svg"}
                />
                <div className={isClicked3 ? "admin_txt" : "admin_txt1"}>
                  Content
                </div>
              </div>
              <div
                onClick={cardClick4}
                type="button"
                className={
                  isClicked4 ? "admin ml-12 mt-12" : "sales ml-12 mt-12"
                }
              >
                <img
                  className="admin_logo"
                  alt="logo"
                  src={isClicked4 ? "Support-Checked.svg" : "support.svg"}
                />
                <div className={isClicked4 ? "admin_txt" : "admin_txt1"}>
                  Support
                </div>
              </div>
            </div>
            {/* <Link to='/login1'> */}
            <button
              disabled={!anyConditionTrue}
              className="continue_btn font-family"
              onClick={handleContinue}
            >
              Continue
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
    </>
  );
}
