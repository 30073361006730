import React from "react";
import EditorBar from "../ContentComponent/EditorBar";
import { Link } from "react-router-dom";

// import { Color } from '@tiptap/extension-color'
// import ListItem from '@tiptap/extension-list-item'
// import TextStyle from '@tiptap/extension-text-style'
// import { EditorContent, useEditor } from '@tiptap/react'
// import StarterKit from '@tiptap/starter-kit'

export default function NewPost() {

  // const MenuBar = ({ editor }) => {
  //   if (!editor) {
  //     return null
  //   }}

  return (
    <div>
      <div className="">
        <img className="editor_bg" src="editor_bg.svg" alt="vector" />
        <div className="editor_head">
          <div className="container editor_pad d-flex justify-content-between">
          <img type="button" className="cross_icn_white" src="cross_icn_white.svg" alt="cancel" />
          <div className="d-flex">
            <button className="draft_btn font-family">Set as draft</button>
            <Link to="/posts" className="publish_lnk">
            <button className="publish_btn font-family ml-12">Publish</button>
            </Link>
            <div className="editor_brdr"></div>
            <div className="ml-20">
            <div className="editor_round">
                <img
                  className="rounded-circle profile_logo"
                  src="profile.jpeg"
                  alt=""
                />
              </div>
            </div>
          </div>
          </div>
          </div>
          <div className="container mt-32">
            <div className="d-flex">
            <img className="side_icn" src="header_img_light.svg" alt="header_img" />
            <div to="/release" className="ml-12 incr2 mt_5">Header Image</div>
            </div>
            <div contentEditable className="outline_title editor_txt1 font-family mt-24">New post title here...</div>
            <div className="d-flex">
              <div className="img_tag d-flex">
                <img className="img_tag_icn" src="cross_icn.svg" />
                <div className="img_tag_txt font-fmaily ml-8">
                  Information Technology
                </div>
              </div>
            <input className="editor_input font-family mt_5" placeholder="Category" />
            </div>
            <div className="mt-24 editor_bg_color d-flex">
              <div className="">
              <img type="button" className="undo_icn" src="undo_icn.svg" alt="undo" />
              <img type="button" className="undo_icn" src="redo_icn.svg" alt="redo" />
              </div>
              <select className="bg-transparent text-white editor_select font-family">
                <option className="font-family text-black">Normal text</option>
                <option className="heading1 font-family">Heading 1</option>
                <option className="heading2 font-family">Heading 2</option>
                <option className="heading3 font-family">Heading 3</option>
              </select>
              <select className="bg-transparent text-white editor_select1 font-family">
                <option className="font-family text-black"></option>
                <option data-thumbnail="align_icn.svg" className="font-family text-black"><img src="align_left.svg" alt="center" />Center</option>
              </select>
              {/* <img onClick={() => editor.chain().focus().toggleBold().run()} */}
        {/* disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleBold()
            .run()
        } */}
        {/* className={editor.isActive('bold') ? 'is-active' : ''} type="button" src="bold_icn.svg" alt="bold" /> */}
              <img type="button" src="italic_icn.svg" alt="italic" />
              <img type="button" src="underline_icn.svg" alt="Underline" />
              <img type="button" src="strike_icn.svg" alt="Strike" />
              <img type="button" className="ulist_icn" src="ulist_icn.svg" alt="ul" />
              <img type="button" className="olist_icn" src="olist_icn.svg" alt="ol" />
              <img type="button" className="link_icn" src="link_icn.svg" alt="link" />
              <img type="button" className="image_icn" src="image_icn.svg" alt="upload " />
              <img type="button" className="image_icn" src="gif_icn.svg" alt="gif" />
            </div>
            {/* <textarea className="typing_input " placeholder="Type here..." /> */}
            <div className="typing_input font-family mt-32">
            <EditorBar />
          </div>
          </div>
      </div>
    </div>
  );
}
