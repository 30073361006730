


import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";

const columns = [
  { id: "tId", label: "Transaction Id", minWidth: 100, align: "center" },
  { id: "date", label: "Date", minWidth: 130, align: "center" },
  {
    id: "mode",
    label: "Mode",
    minWidth: 120,
    align: "center",
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: "amount",
    label: "Amount",
    // minWidth: 170,
    align: "center",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "plan",
    label: "Plan",
    minWidth: 80,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "action",
    label: "Action",
    minWidth: 80,
    align: "center",
    format: (value) => value.toFixed(2),
  },
];


const rows = [
  {
    id: 1,
    tId:  "#526587" ,
    date: "Aug 31,2022",
    mode: "Credit Card",
    amount: "-",
    plan: "Free",
   
  },
  {
    id: 2,
    tId:  "#526587" ,
    date: "Oct 13, 2022",
    mode: "Credit Card",
    amount: "-",
    plan: "Free",
   
  },
  {
    id: 3,
    tId:  "#526587" ,
    date: "Jun 12,2023",
    mode: "Credit Card",
    amount: "$33",
    plan: "Premium",

  },
  {
    id: 4,
    tId:  "#526587" ,
    date: "Dec 22, 2023",
    mode: "Credit Card",
    amount: "$500",
    plan: "Business",
 
  },
  {
    id: 5,
    tId:  "#526587" ,
    date: "Sep 20,2022",
    mode: "Credit Card",
    amount: "$1500",
    plan: "Custom",
   
  },
  {
    id: 6,
    tId:  "#526587" ,
    date: "Aug 4, 2022",
    mode: "Credit Card",
    amount: "-",
    plan: "Free",
 
  },
  {
    id: 7,
    tId:  "#526525" ,
    date: "Jun 23, 2022",
    mode: "Credit Card",
    amount: "$3200",
    plan: "Custom",
 
  },
  {
    id: 8,
    tId:  "#105986" ,
    date: "Jul 8, 2022",
    mode: "Credit Card",
    amount: "$1800",
    plan: "Custom",

  },
  {
    id: 9,
    tId:  "#526520" ,
    date: "Aug 1,2022",
    mode: "Credit Card",
    amount: "-",
    plan: "Free",

  },

];

export default function  UserDetailTable    () {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const navigate=useNavigate();
  
  const handleIconClick = (status) => {
    if (status === 'Custom') {

      navigate("/usertrasaction"); 
    }
  };
  const [selectedOption, setSelectedOption] = React.useState('');
  
  const handleChange = (event) => {
      setSelectedOption(event.target.value);
    };
    
    
    const sortMonthly = (data) => {
        return data.slice().sort((a, b) => new Date(a.date) - new Date(b.date));
    };
    
    const sortYearly = (data) => {
        const sortedData = data.slice().sort((a, b) => new Date(a.date) - new Date(b.date));
        const yearlyData = {};
        
        sortedData.forEach((item) => {
          const year = new Date(item.date).getFullYear();
          if (!yearlyData[year]) {
            yearlyData[year] = [];
          }
          yearlyData[year].push(item);
        });
      
        const sortedYearlyData = [];
        for (const year in yearlyData) {
          sortedYearlyData.push(...yearlyData[year]);
        }
      
        return sortedYearlyData;
      };
      
    
    const sortedRows = selectedOption === 'monthly' ? sortMonthly(rows) : selectedOption === 'yearly' ? sortYearly(rows) : rows;
    

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className="mt-20 ml-20 mr-20 ">
      <div className="table_head">
        <div className="tabs_pad d-flex">
          <div className="table-highlight-member pt-3 pb-3 ">
            <span>Transactions</span>{" "}
            <span className="badge bg-lightt badge_txt ml-8 ">
              {rows.length}
            </span>
          </div>
        </div>
      </div>

      <div className="dropdownTable  " style={{ background: "white" }}>
    
        <div className="d-flex align-items-center">
          <select
            type="button"
            className="drp_btn text-muted mb-3"
            value={selectedOption} onChange={handleChange}
          >
            <option value="" disabled>Sort By</option>
            <option value="monthly">By Month</option>
            <option value="yearly">By Year</option>
          </select>

          <TablePagination
      
            rowsPerPageOptions={[8, 20, { label: "All", value: -1 }]}
            sx={{ marginLeft: "auto", fontSize: "400 !important" }}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>

        <Paper
          className="mr-20 ml-20"
          sx={{
            width: "100%",
            height: "35.333vw",
            marginLeft: "auto",
            marginRight: "auto",
            paddingLeft: "1.333vw",
            paddingRight: "1.333vw",
            overflow: "hidden",
          }}
        >
          <TableContainer sx={{ maxHeight: "35.333vw" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      className="table-headd"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {column.label}

                        {column.label !== 'Action' && (
  <img
    src="data_icn.svg"
    alt="Column Icon"
    className="data_icn"
    style={{ marginLeft: "8px" }}
  />
)}
                      </div>
                     
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, rowIndex) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={rowIndex}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ fontSize: "600 !important" }}
                            
                          >
                            {
                            
                            column.id === 'plan' ? (
                            <div className="activity-status">
                             {value === 'Free' ? (<span className="open_css" >Free</span>) :value === 'Business' ? (<span className="unassigne_css">Business</span>): value === 'Premium' ? (<span  className="close_css">Premium</span>):<span  className="custom_css">Custom</span>}
                          </div>)
                            
                            : column.id === "action" ? (
                              <div className="action-cell">
                               
                               <img src='/images/Admin-Sales-Table-Group 47.svg' alt="action" className='trash_icn' style={{ cursor: 'pointer' }}  
                            onClick={() => handleIconClick(row.plan)}
                               />
                               
                              </div>
                            ) : (
                              value
                            )}
                            
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
}
