



import React, { useState } from "react";
import "../../css/sale.css";
import SaleSidebar from "../SaleComponents/SaleSidebar";
import NavbarAvatar from "../ContentComponent/NavbarAvatar";

import UserDetailTable from "../SaleComponents/UserDetailTable";
import { useNavigate } from "react-router-dom";

export default function UserDetails() {
  const [isToggle, setIsToggle] = useState(true);
  function togglesidebar() {
    setIsToggle(!isToggle);
  }
  const navigate=useNavigate();

  return (
    <div className="d-flex">
      {/* sidebar  */}

      <SaleSidebar isToggle={isToggle} />

      <div
        className="container-fluid sale-content "
        style={{ marginLeft: isToggle ? "20vw" : "0vw" }}
      >
        {/* navbar  */}

        <NavbarAvatar togglesidebar={togglesidebar} />

        {/* content parent container  */}
        <div className="row all-content-container">
          <div className="col-md-12">
            {/* =====    row1    === */}
            <div className="user-detail-profile ml-20 mr-20 mt-40">
                <div className="user-detail-cross pt-24 ml-24">
                    <img src='/images/cross.svg' alt="cross" style={{cursor:'pointer '}} onClick={()=>navigate('/saleusers')}/>
                </div>
                <div className=" mt-16 ml-24 d-flex">
                    <div className="user-profile-data pl-36 pr-36 ">
                        
                        <img src='/images/user-img.svg' alt="cross" className="user-img "/>
                        <span className="user-name mt-8 ">Esther Howard</span>
                        <span className="user-id mt-8 ">#526587</span>
                    </div>
                    <div className="profile-data pl-36 d-flex flex-column">
                        <span className="user-profile-heading mt-10">User Info</span>
                        <div className="other-info ">
                            <div className="other-info-row1 d-flex mt-24">
                                <span className="row1-box1 d-flex flex-column ">
                                    <span className="user-info-bold">Email</span>
                                    <span className="user-info-body">example@email.com</span>
                                </span>
                                <span className="row1-box2 d-flex flex-column ml-30">
                                    <span className="user-info-bold">Last Login</span>
                                    <span className="user-info-body">14/11/2022</span>
                                </span>
                            </div>
                            <div className="other-info-row2 d-flex mt-20">
                                <span className="row1-box1 d-flex flex-column">
                                    <span className=" user-info-bold">Date Created</span>
                                    <span className="user-info-body">13/10/2022</span>
                                </span>
                                <span className="email-box d-flex flex-column  ml-30">
                                    <span className="user-info-bold">Current Plan</span>
                                    <span className="user-info-body">Premium</span>
                                </span>
                            </div>


                        </div>

                    </div>
                   
                </div>
              
            </div>
            {/* =====    row1 ends   === */}

                 
            {/* user table */}
            
            <UserDetailTable/>
            {/* user table ends */}
          </div>
        </div>
      </div>
    </div>
  );
}

