import React, { useState } from "react";
import "../Dashboard/Dashboard.css";
import DashboardSidebar from "../DashboardComponent/DashboardSidebar";
import Chart1 from "../DashboardComponent/Chart1";
import { NavLink } from "react-router-dom";
import Chart2 from "../DashboardComponent/Chart2";
import BarChart from "../DashboardComponent/BarChart";
import LineMonthly from "../DashboardComponent/LineMonthly";
import LineWeekly from "../DashboardComponent/LineWeekly";
import LineYearly from "../DashboardComponent/LineYearly";
import BarMonthly from "../DashboardComponent/BarMonthly";
import BarWeekly from "../DashboardComponent/BarWeekly";
import BarYearly from "../DashboardComponent/BarYearly";
import DashboardTable from "../DashboardComponent/DashboardTable";


export default function Main_Dashboard() {
  const [active, isactive] = useState(true);
  const handleactive = () => {
    isactive(true);
    isactive1(false);
    isactive2(false);
    isactive3(false);
  };
  const [active1, isactive1] = useState(false);
  const handleactive1 = () => {
    isactive1(true);
    isactive(false);
    isactive2(false);
    isactive3(false);
  };
  const [active2, isactive2] = useState(false);
  const handleactive2 = () => {
    isactive(false);
    isactive1(false);
    isactive2(true);
    isactive3(false);
  };
  const [active3, isactive3] = useState(false);
  const handleactive3 = () => {
    isactive(false);
    isactive1(false);
    isactive2(false);
    isactive3(true);
  };
  const [BarActive, isBarActive] = useState(true);
  const handleactiveBar = () => {
    isBarActive(true);
    isBarActive1(false);
    isBarActive2(false);
    isBarActive3(false);
  };
  const [BarActive1, isBarActive1] = useState(false);
  const handleactiveBar1 = () => {
    isBarActive1(true);
    isBarActive(false);
    isBarActive2(false);
    isBarActive3(false);
  };
  const [BarActive2, isBarActive2] = useState(false);
  const handleactiveBar2 = () => {
    isBarActive(false);
    isBarActive1(false);
    isBarActive2(true);
    isBarActive3(false);
  };
  const [BarActive3, isBarActive3] = useState(false);
  const handleactiveBar3 = () => {
    isBarActive(false);
    isBarActive1(false);
    isBarActive2(false);
    isBarActive3(true);
  };

  return (
    <div>
      <div className="dashboard_bg">
        <div className="row mr-0">
          <div className="col-3 dashboard_sidebar">
            <DashboardSidebar />
          </div>
          <div className="top_banner col-9">
            <div className="d-flex">
              <img
                className="menu_icon mt-32 ml-22"
                src="menu_icon.svg"
                alt="Menu"
              />

              <img
                className="logout_icon mt-32 justify-content-end"
                src="logout_icon.svg"
                alt="Logout"
              />

              <div className="ml-18 mt-12">
                <div className="profile_round_dashboard">
                  <img
                    className="rounded-circle profile_logo"
                    src="profile.jpeg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="font-family mt-32 ml-20 dash_text">Dashboard</div>
            <div className="d-flex">
              <div className="sale_chart_bg mt-20 ml-20">
              <div className="d-flex">
                  <div>
                    <div className="font-family ml-20 pt-20 fnt_sale">
                      Sales Statistics
                    </div>
                    <div className="font-family ml-20 mt-8 fnt_sale1 fw-400">
                      Overview of latest day
                    </div>
                  </div>
                  <div className=" pt-22 ml-240">
                    <NavLink
                      onClick={handleactive}
                      className={
                        active
                          ? "fnt1_active text-uppercase"
                          : "fnt1 text-uppercase"
                      }
                    >
                      Daily
                    </NavLink>
                    <NavLink
                      onClick={handleactive1}
                      className={
                        active1
                          ? "fnt1_active text-uppercase ml-20"
                          : "fnt1 text-uppercase ml-20"
                      }
                    >
                      Monthly
                    </NavLink>
                    <NavLink
                      onClick={handleactive2}
                      className={
                        active2
                          ? "fnt1_active text-uppercase ml-25"
                          : "fnt1 text-uppercase ml-25"
                      }
                    >
                      Weekly
                    </NavLink>
                    <NavLink
                      onClick={handleactive3}
                      className={
                        active3
                          ? "fnt1_active text-uppercase ml-28"
                          : "fnt1 text-uppercase ml-28"
                      }
                    >
                      Yearly
                    </NavLink>
                  </div>
                </div>
                <div className="sale_chart_size">
                {active &&
                <Chart1 />}
                {active1 &&
                <LineMonthly />}
                {active2 &&
                <LineWeekly />}
                {active3 &&
                <LineYearly />}
              </div>
                <div className="d-flex">
                  <div className="ml-40 mt-32 font-family">
                    <div className="earn_value">$6268.4</div>
                    <div className="fnt_sale1">Current Month Earnings</div>
                  </div>
                  <div className="ml-86 mt-32 font-family">
                    <div className="earn_value">55</div>
                    <div className="fnt_sale1">Current Month Sales</div>
                  </div>
                  <div type="button" className="mt-35 summary_btn">
                    Last Month Summary
                  </div>
                </div>
              </div>
              <div className="user_chart_bg ml-20 mt-20">
                <div className="mt-20 ml-20 user_text font-family">
                  Total Users
                </div>
                <div className="chart_2 ">
                  <Chart2 />
                </div>
              </div>
            </div>
            <div className="mt-20 ml-20 row">
              <div className="col-6 pad_none">
                <div className="d-flex">
                  <div className="free_user_bg">
                    <div className="d-flex">
                      <div>
                        <div className="free_user font-family">Free Users</div>
                        <div className="free_num">15,565</div>
                      </div>
                      <div>
                        <img
                          src="graph_line.svg"
                          alt="Graph"
                          className="graph_line"
                        />
                      </div>
                    </div>
                    <div>
                      <img
                        src="increasing_icon.svg"
                        alt="increasing"
                        className="increase_icon"
                      />
                      <span className="incr">+5%</span>{" "}
                      <span className="incr1">than last month</span>
                    </div>
                  </div>
                  <div className="free_user_bg ml-20">
                    <div className="d-flex">
                      <div>
                        <div className="free_user font-family">
                          Premium Users
                        </div>
                        <div className="free_num">10,279</div>
                      </div>
                      <div>
                        <img
                          src="graph_line1.svg"
                          alt="Graph "
                          className="graph_line1"
                        />
                      </div>
                    </div>
                    <div>
                      <img
                        src="decreasing_icon.svg"
                        alt="increasing"
                        className="increase_icon"
                      />
                      <span className="decr">-2%</span>{" "}
                      <span className="decr1">than last month</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-20">
                  <div className="free_user_bg">
                    <div className="d-flex">
                      <div>
                        <div className="free_user font-family">
                          Business Users
                        </div>
                        <div className="free_num">5,103</div>
                      </div>
                      <div>
                        <img
                          src="graph_line1.svg"
                          alt="Graph"
                          className="graph_line1"
                        />
                      </div>
                    </div>
                    <div>
                      <img
                        src="decreasing_icon.svg"
                        alt="increasing"
                        className="increase_icon"
                      />
                      <span className="decr">-10%</span>{" "}
                      <span className="decr1">than last month</span>
                    </div>
                  </div>
                  <div className="free_user_bg ml-20">
                    <div className="d-flex">
                      <div>
                        <div className="free_user font-family">
                          Custom Users
                        </div>
                        <div className="free_num">10,279</div>
                      </div>
                      <div>
                        <img
                          src="graph_line.svg"
                          alt="Graph "
                          className="graph_line"
                        />
                      </div>
                    </div>
                    <div>
                      <img
                        src="increasing_icon.svg"
                        alt="increasing"
                        className="increase_icon"
                      />
                      <span className="incr">+4%</span>{" "}
                      <span className="incr1">than last month</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 pad_none">
                <div className="bar_chart_bg">
                  <div>
                    <div className="d-flex">
                      <div>
                        <div className="font-family ml-20 pt-20 fnt_sale">
                          Screen Time Statistics
                        </div>
                        <div className="font-family ml-20 mt-8 fnt_sale1 fw-400">
                          Overview of latest day
                        </div>
                      </div>
                      <div className=" pt-22 ml-80">
                        <NavLink
                          onClick={handleactiveBar}
                          className={
                            BarActive
                              ? "fnt1_active text-uppercase"
                              : "fnt1 text-uppercase"
                          }
                        >
                          Daily
                        </NavLink>
                        <NavLink
                          onClick={handleactiveBar1}
                          className={
                            BarActive1
                              ? "fnt1_active text-uppercase ml-20"
                              : "fnt1 text-uppercase ml-20"
                          }
                        >
                          Monthly
                        </NavLink>
                        <NavLink
                          onClick={handleactiveBar2}
                          className={
                            BarActive2
                              ? "fnt1_active text-uppercase ml-25"
                              : "fnt1 text-uppercase ml-25"
                          }
                        >
                          Weekly
                        </NavLink>
                        <NavLink
                          onClick={handleactiveBar3}
                          className={
                            BarActive3
                              ? "fnt1_active text-uppercase ml-28"
                              : "fnt1 text-uppercase ml-28"
                          }
                        >
                          Yearly
                        </NavLink>
                      </div>
                    </div>
                  </div>
                  <div className="bar_chart font-family">
                  {BarActive &&
                <BarChart />}
                {BarActive1 &&
                <BarMonthly />}
                {BarActive2 &&
                <BarWeekly />}
                {BarActive3 &&
                <BarYearly />}
                  </div>
                </div>
              </div>
            </div>
            <DashboardTable />
          </div>
          {/* Dashboard Side Ends Here  */}
        </div>
      </div>
    </div>
  );
}
