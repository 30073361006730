import React from "react";
import "../Dashboard/Dashboard.css";
import LiveChatSidebar from "../SupportComponent/LiveChatSidebar";
import LiveTicketTable from "../SupportComponent/LiveTicketTable";

export default function LiveChatDashboard() {
  
  return (
    <div>
      <div className="dashboard_bg">
        <div className="row mr-0">
          <div className="col-3 ticket_dashboard_sidebar">
            <LiveChatSidebar />
          </div>
          <div className="top_banner col-9"> 
            <div className="d-flex">
              <img
                className="menu_icon mt-32 ml-22"
                src="menu_icon.svg"
                alt="Menu"
              />

              <img
                className="logout_icon mt-32 justify-content-end"
                src="logout_icon.svg"
                alt="Logout"
              />

              <div className="ml-18 mt-12">
                <div className="profile_round_dashboard">
                  <img
                    className="rounded-circle profile_logo"
                    src="profile.jpeg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-between'>
            <div className="font-family mt-32 ml-20 dash_text">Live Chat</div>
            <div className='content_txt font-family mt-32'>Support {'>'} Tickets</div>
            </div>
            <div className="mt-20 ml-20 row">
              <div className="pad_none">
                <div className="d-flex">
                  <div className="free_user_bg">
                    <div className="d-flex">
                      <div>
                        <div className="free_user font-family">Response Time</div>
                        <div className="free_num">1 min</div>
                      </div>
                      <div>
                        <img
                          src="graph_line.svg"
                          alt="Graph"
                          className="graph_line_ticket"
                        />
                      </div>
                    </div>
                    <div>
                      <img
                        src="increasing_icon.svg"
                        alt="increasing"
                        className="increase_icon"
                      />
                      <span className="incr">+5%</span>{" "}
                      <span className="incr1">than last month</span>
                    </div>
                  </div>
                  <div className="free_user_bg ml-20">
                    <div className="d-flex">
                      <div>
                        <div className="free_user font-family">
                          Rating
                        </div>
                        <div className="free_num">3.5</div>
                      </div>
                      <div>
                        <img
                          src="graph_line1.svg"
                          alt="Graph "
                          className="graph_line1"
                        />
                      </div>
                    </div>
                    <div>
                      <img
                        src="decreasing_icon.svg"
                        alt="increasing"
                        className="increase_icon"
                      />
                      <span className="decr">-2%</span>{" "}
                      <span className="decr1">than last month</span>
                    </div>
                  </div>
                <div className="d-flex ml-20">
                  <div className="free_user_bg">
                    <div className="d-flex">
                      <div>
                        <div className="free_user font-family">
                          Tickets: Total
                        </div>
                        <div className="free_num">5,103</div>
                      </div>
                      <div>
                        <img
                          src="graph_line1.svg"
                          alt="Graph"
                          className="graph_line1"
                        />
                      </div>
                    </div>
                    <div>
                      <img
                        src="decreasing_icon.svg"
                        alt="increasing"
                        className="increase_icon"
                      />
                      <span className="decr">-10%</span>{" "}
                      <span className="decr1">than last month</span>
                    </div>
                  </div>
                  <div className="free_user_bg ml-20">
                    <div className="d-flex">
                      <div>
                        <div className="free_user font-family">
                        Tickets: Today
                        </div>
                        <div className="free_num">2,475</div>
                      </div>
                      <div>
                        <img
                          src="graph_line.svg"
                          alt="Graph "
                          className="graph_line"
                        />
                      </div>
                    </div>
                    <div>
                      <img
                        src="increasing_icon.svg"
                        alt="increasing"
                        className="increase_icon"
                      />
                      <span className="incr">+4%</span>{" "}
                      <span className="incr1">than last month</span>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
            <LiveTicketTable />
          </div>
          {/* Dashboard Side Ends Here  */}
        </div>
      </div>
    </div>
  );
}
