import React from "react";
import "../Dashboard/Dashboard.css";
import PostsSidebar from "../ContentComponent/PostsSidebar";
import PostsTable from "../ContentComponent/PostsTable";

export default function Posts_Dashboard() {
  
  return (
    <div>
      <div className="dashboard_bg">
        <div className="row mr-0">
          <div className="col-3 posts_sidebar">
            <PostsSidebar />
          </div>
          <div className="top_banner col-9">
            <div className="d-flex">
              <img
                className="menu_icon mt-32 ml-22"
                src="menu_icon.svg"
                alt="Menu"
              />

              <img
                className="logout_icon mt-32 justify-content-end"
                src="logout_icon.svg"
                alt="Logout"
              />

              <div className="ml-18 mt-12">
                <div className="profile_round_dashboard">
                  <img
                    className="rounded-circle profile_logo"
                    src="profile.jpeg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-between'>
            <div className="font-family mt-32 ml-20 dash_text">Posts</div>
            <div className='content_txt font-family mt-32'>Content {'>'} Posts</div>
            </div>
           
            
            <PostsTable />
          </div>
          {/* Dashboard Side Ends Here  */}
        </div>
      </div>
    </div>
  );
}
