import React from 'react'
import { ResponsivePie } from '@nivo/pie'

export default function ContentPieChart() {
    const data = [
        {
            "id": "Thumbs Down",
            "label": "Thumbs Down",
            "value": 30,
            "color": "hsl(113, 70%, 50%)"
        },
        {
          "id": "Thumbs Up",
          "label": "Thumbs Up",
          "value": 70,
          "color": "hsl(179, 70%, 50%)"
        },
        ]
    const color = ['#DD6C6C','#419958', '#DD6CD9']
    return (
        

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

    <ResponsivePie
        data={data}
        margin={{ top: -50, right: 100, bottom: 0, left: 100 }}
        innerRadius={0.55}
        padAngle={1}
        activeOuterRadiusOffset={8}
        colors={ color }
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'brighter',
                    '0'
                ]
            ]
        }}
        arcLinkLabelsSkipAngle={0}
        arcLinkLabelsTextColor="#505050"
        arcLinkLabelsThickness={1}
        arcLinkLabelsColor={{ from: 'color' }}
        enableArcLabels={false}
        arcLabelsTextColor="#0d0d0d"
        arcBorderWidth={2}
        
        legends={[
            {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: -50,
                itemsSpacing: 30,
                itemWidth: 70,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 12,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}
    />
)
  
}
