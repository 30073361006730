import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { NavLink } from "react-router-dom";
import { useState } from "react";

const columns = [
  { id: "name", label: "Name", minWidth: 150, align: "center" },
  { id: "email", label: "Email", minWidth: 170, align: "center" },
  {
    id: "team",
    label: "Team",
    minWidth: 120,
    align: "center",
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: "activity",
    label: "Activity",
    // minWidth: 170,
    align: "center",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "action",
    label: "Action",
    minWidth: 80,
    align: "center",
    format: (value) => value.toFixed(2),
  },
];


function Delete()
{
alert('delete');
}
function Edit()
{
alert('edit');
}

const rows = [
  {
    id: 1,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Sales Team",
    activity: "Online",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 2,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Admin Team",
    activity: "Offline",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 3,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Content Team",
    activity: "Offline",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 4,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Content Team",
    activity: "Online",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 5,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Content Team",
    activity: "Offline",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 6,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Support Team",
    activity: "Offline",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 7,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Content Team",
    activity: "Offline",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 8,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Content Team",
    activity: "Offline",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 9,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Content Team",
    activity: "Offline",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 10,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Content Team",
    activity: "Offline",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 11,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Content Team",
    activity: "Online",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
  {
    id: 12,
    name: { img: "profile_avatar.svg", name: "Esther Howard" },
    email: "example@gmail.com",
    team: "Content Team",
    activity: "Offline",
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action: { delete: Delete, edit: Edit },
  },
];

export default function DashboardTable() {

  const [active, isactive] = React.useState(true);

    const [filter, setFilter] = React.useState('all');
    const [filteredRows, setFilteredRows] = React.useState(rows);
    const [adminCount, setAdminCount] = React.useState(0);
    const [salesCount, setSalesCount] = React.useState(0);
    const [contentCount, setContentCount] = React.useState(0);
    const [supportCount, setSupportCount] = React.useState(0);
    // const [searchQuery, setSearchQuery] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [selectedActivity, setSelectedActivity] = React.useState("");

  const handleActivityChange = (event) => {
    setSelectedActivity(event.target.value);
  };

  const ActivityRows = selectedActivity
    ? rows.filter((row) => row.activity === selectedActivity)
    : rows;

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const updateCounts = () => {
    const adminRecords = rows.filter((row) => row.team === 'Admin Team');
    const salesRecords = rows.filter((row) => row.team === 'Sales Team');
    const contentRecords = rows.filter((row) => row.team === 'Content Team');
    const supportRecords = rows.filter((row) => row.team === 'Support Team');

    setAdminCount(adminRecords.length);
    setSalesCount(salesRecords.length);
    setContentCount(contentRecords.length);
    setSupportCount(supportRecords.length);
  };
  
  React.useEffect(() => {
    // Update the counts when filteredRows change
    updateCounts(rows);
  }, [filteredRows]);

  

  function SetStateAll(){handleFilterChange('all')};
  function SetStateAdmin(){handleFilterChange('Admin')};
  function SetStateSales(){handleFilterChange('Sales')};
  function SetStateContent(){handleFilterChange('Content')};
  function SetStateSupport(){handleFilterChange('Support')};

  const handleFilterChange = (status) => {
    setFilter(status);
    if (status === 'all') {
        handleactive();
      setFilteredRows(rows);
    } else if (status === 'Admin') {
        handleactive1();
      setFilteredRows(rows.filter((row) => row.team === 'Admin Team'));
    } else if (status === 'Sales') {
        handleactive2();
      setFilteredRows(rows.filter((row) => row.team === 'Sales Team'));
    } else if (status === 'Content') {
        handleactive3();
      setFilteredRows(rows.filter((row) => row.team === 'Content Team'));
    } else if (status === 'Support') {
        handleactive4();
      setFilteredRows(rows.filter((row) => row.team === 'Support Team'));
    }
  };

  const handleactive = () => {
    isactive(true);
    isactive1(false);
    isactive2(false);
    isactive3(false);
    isactive4(false);
  };
  const [active1, isactive1] = React.useState(false);

  const handleactive1 = () => {
    isactive(false);
    isactive1(true);
    isactive2(false);
    isactive3(false);
    isactive4(false);
  };
  const [active2, isactive2] = React.useState(false);

  const handleactive2 = () => {
    isactive(false);
    isactive1(false);
    isactive2(true);
    isactive3(false);
    isactive4(false);
  };
  const [active3, isactive3] = React.useState(false);

  const handleactive3 = () => {
    isactive(false);
    isactive1(false);
    isactive2(false);
    isactive3(true);
    isactive4(false);
  };
  const [active4, isactive4] = React.useState(false);

  const handleactive4 = () => {
    isactive(false);
    isactive1(false);
    isactive2(false);
    isactive3(false);
    isactive4(true);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [isClicked, setisClicked] = useState(false);
  const cardClick = () => {
    setisClicked(true);
    setisClicked2(false);
    setisClicked3(false);
    setisClicked4(false);
  };
  const [isClicked2, setisClicked2] = useState(false);
  const cardClick2 = () => {
    setisClicked(false);
    setisClicked2(true);
    setisClicked3(false);
    setisClicked4(false);
  };
  const [isClicked3, setisClicked3] = useState(false);
  const cardClick3 = () => {
    setisClicked(false);
    setisClicked2(false);
    setisClicked3(true);
    setisClicked4(false);
  };
  const [isClicked4, setisClicked4] = useState(false);
  const cardClick4 = () => {
    setisClicked(false);
    setisClicked2(false);
    setisClicked3(false);
    setisClicked4(true);
  };

  const anyConditionTrue = isClicked || isClicked2 || isClicked3 || isClicked4;
  return (
    <div className="mt-20 ml-20 mr-20 ">
      <div className="table_head">
      <div className="tabs_pad d-flex">

<NavLink
          //   onClick={handleactive}
          onClick={SetStateAll}

            className={active ? "nav_table_active" : "nav_table"}
          >
            All<span className="badge bg-light badge_txt ml-8">{rows.length}</span>
          </NavLink>
          <NavLink
          //   onClick={handleactive1}
          onClick={SetStateAdmin}
            className={active1 ? "nav_table_active ml-30" : "nav_table ml-30"}
          >
            Admin<span className="badge bg-success ml-8 badge_txt1">{adminCount}</span>
          </NavLink>
          <NavLink
          //   onClick={handleactive2}
          onClick={SetStateSales}
            className={active2 ? "nav_table_active ml-30" : "nav_table ml-30"}
          >
            Sales<span className="badge ml-8 badge_bg badge_txt1">{salesCount}</span>
          </NavLink>
          <NavLink
          //   onClick={handleactive3}
          onClick={SetStateContent}
            className={active3 ? "nav_table_active ml-30" : "nav_table ml-30"}
          >
            Content<span className="badge badge_bg1 ml-8 badge_txt1">{contentCount}</span>
          </NavLink>
          <NavLink
          //   onClick={handleactive3}
          onClick={SetStateSupport}
            className={active4 ? "nav_table_active ml-30" : "nav_table ml-30"}
          >
            Support<span className="badge badge_bg2 ml-8 badge_txt1">{supportCount}</span>
          </NavLink>
          


</div>
      </div>

      <div className="dropdownTable  " style={{ background: "white" }}>
        {/* <button className='btn btn-primary dropdown-toggle'>Activity</button> */}
        <div className="d-flex align-items-center">
          <select
            type="button"
            className="drp_btn text-muted mb-3"
            onChange={handleActivityChange}
            value={selectedActivity}
          >
            <option selected value="">
              <span className="text-muted">Activity</span>
            </option>
            <option value="Online">Online</option>
            <option value="Offline">Offline</option>
          </select>
          <button
                data-bs-target="#MemberModal"
                data-bs-toggle="modal"
                className="add_btn_maindash"
              >
                +Add Member
              </button>

          <TablePagination
            // rowsPerPageOptions={[10, 20,{ label: 'All', value: -1 }]}
            rowsPerPageOptions={[8, 20, { label: "All", value: -1 }]}
            sx={{ marginLeft: "auto", fontSize: "400 !important" }}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>

        <Paper
          className="mr-20 ml-20"
          sx={{
            width: "100%",
            height: "35.333vw",
            marginLeft: "auto",
            marginRight: "auto",
            paddingLeft: "1.333vw",
            paddingRight: "1.333vw",
            overflow: "hidden",
          }}
        >
          <TableContainer sx={{ maxHeight: "35.333vw" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      className="table-headd"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {column.label}

                        <img
                          src="data_icn.svg"
                          alt="Column Icon"
                          className="data_icn"
                          style={{ marginLeft: "8px" }}
                        />
                      </div>
                      {/* {column.label} */}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredRows 
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, rowIndex) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={rowIndex}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ fontSize: "600 !important" }}
                            // className={column.id === 'activity' ? (value === 'Online' ? 'onlinecss' : 'offlinecss') : ''}
                          >
                            {column.id === "name" ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={value.img}
                                  alt={value.name}
                                  style={{ marginRight: "8px" }}
                                />
                                {value.name}
                              </div>
                            ) : column.id === "activity" ? (
                              <div className="activity-status">
                                {value === "Online" ? (
                                  <span className="onlinecss">Online</span>
                                ) : (
                                  <span className="offlinecss">Offline</span>
                                )}
                              </div>
                            ) : column.id === "action" ? (
                              <div className="action-cell">
                                {/* delimg:'trash_icn.svg',editimg:'edit_icn.svg' */}
                                <img
                                  src="trash_icn.svg"
                                  alt="Delete"
                                  className="trash_icn "
                                  style={{ cursor: "pointer" }}
                                  onClick={Delete}
                                />
                                <img
                                  src="edit_icn.svg"
                                  alt="Edit"
                                  className="trash_icn "
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "1.6vw",
                                  }}
                                  onClick={Edit}
                                />
                              </div>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
      

      {/* =============Modal Starts Here============= */}

      <div
        className="modal fade "
        id="MemberModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered MemberModal_size">
          <div className="modal-content">
            {/* <div className="modal-header"> */}
            <div className="d-flex justify-content-end mr-16">
              <img
                className="cross_icn mt-16"
                type="button"
                data-bs-dismiss="modal"
                src="cross_icn.svg"
                alt="cancel_btn"
              />
            </div>
            <div className="modaltxt font-family">Add Member</div>

            {/* </div> */}
            <div className="d-flex justify-content-center mt-24">
              <img
                className="Modal_profile"
                src="Modal_profile.svg"
                alt="profile_picture"
              />
              <img
                className="camera_icn"
                src="camera_icn.svg"
                alt="profile_picture"
              />
            </div>
            <div>
              <div className="email_text mt-24 ml-30 font-family">
                Full Name
              </div>
              <div className="input_size ml-30 mt-7">
                <div className="d-flex">
                  <img
                    className="User_icon img-fluid"
                    src="User_icn.svg"
                    alt=""
                  />
                  <input
                    className="input_email"
                    type="email"
                    required
                    placeholder="Enter Your Full Name"
                    //   value={email}
                    //   onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="email_text mt-12 ml-30 font-family">Email Id</div>
              <div className="input_size ml-30 mt-7">
                <div className="d-flex">
                  <img
                    className="email_icon img-fluid"
                    src="vector2.svg"
                    alt=""
                  />
                  <input
                    className="input_email"
                    type="email"
                    required
                    placeholder="Enter Your Email"
                    //   value={email}
                    //   onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="font-family ml-30 role_txt mt-8">Assign Role:</div>
            <div>
              <div className="d-flex mt-8">
                <div
                  onClick={cardClick}
                  type="button"
                  className={isClicked ? "admin ml-30" : "sales ml-30"}
                >
                  <img
                    className="admin_logo"
                    alt="logo"
                    src={isClicked ? "admin.svg" : "Admin-Unchecked.svg"}
                  />
                  <div className={isClicked ? "admin_txt" : "admin_txt1"}>
                    Admin
                  </div>
                </div>
                <div
                  onClick={cardClick2}
                  type="button"
                  className={isClicked2 ? "admin ml-12" : "sales ml-12"}
                >
                  <img
                    className="admin_logo"
                    alt="logo"
                    src={isClicked2 ? "Sales-Checked.svg" : "dollar.svg"}
                  />
                  <div className={isClicked2 ? "admin_txt" : "admin_txt1"}>
                    Sales
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div
                  onClick={cardClick3}
                  type="button"
                  className={
                    isClicked3 ? "admin ml-30 mt-12" : "sales ml-30 mt-12"
                  }
                >
                  <img
                    className="admin_logo"
                    alt="logo"
                    src={isClicked3 ? "Content-Checked.svg" : "content.svg"}
                  />
                  <div className={isClicked3 ? "admin_txt" : "admin_txt1"}>
                    Content
                  </div>
                </div>
                <div
                  onClick={cardClick4}
                  type="button"
                  className={
                    isClicked4 ? "admin ml-12 mt-12" : "sales ml-12 mt-12"
                  }
                >
                  <img
                    className="admin_logo"
                    alt="logo"
                    src={isClicked4 ? "Support-Checked.svg" : "support.svg"}
                  />
                  <div className={isClicked4 ? "admin_txt" : "admin_txt1"}>
                    Support
                  </div>
                </div>
              </div>
              {/* <Link to='/login1'> */}
              {isClicked4 ? (
                <div>
                  <select className="select_role ml-30 mt-12">
                    <option>Select Role</option>
                    <option>Live Chat</option>
                    <option>Ticket Support</option>
                  </select>
                </div>
              ) : null}
              <button
                disabled={!anyConditionTrue}
                className="add_btn_modal font-family mb-32"
                // onClick={}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* =============Modal Starts Here============= */}
    </div>
  );
}
