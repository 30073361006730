import React from 'react'
import { ResponsivePie } from '@nivo/pie'

export default function FeedbackPieChart() {
    const data = [
        {
            "id": "1 Star",
            "label": "1 Star",
            "value": 10,
            "color": "hsl(113, 70%, 50%)"
        },
        {
          "id": "2 Star",
          "label": "2 Star",
          "value": 30,
          "color": "hsl(179, 70%, 50%)"
        },
        {
          "id": "3 Star",
          "label": "3 Star",
          "value": 40,
          "color": "hsl(179, 70%, 50%)"
        },
        {
          "id": "4 Star",
          "label": "4 Star",
          "value": 45,
          "color": "hsl(179, 70%, 50%)"
        },
        {
          "id": "5 Star",
          "label": "5 Star",
          "value": 80,
          "color": "hsl(234, 43%, 42%)"
        },
        ]
    const color = [ '#DD6CD9','#DD6C6C','#419958', '#6BCCDF', '#3f4299']
    return (
        

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

    <ResponsivePie
        data={data}
        margin={{ top: -40, right: 80, bottom: 0, left: 80 }}
        innerRadius={0.55}
        padAngle={1}
        activeOuterRadiusOffset={8}
        colors={ color }
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'brighter',
                    '0'
                ]
            ]
        }}
        arcLinkLabelsSkipAngle={0}
        arcLinkLabelsTextColor="#505050"
        arcLinkLabelsThickness={1}
        arcLinkLabelsColor={{ from: 'color' }}
        enableArcLabels={false}
        arcLabelsTextColor="#0d0d0d"
        arcBorderWidth={2}
        
        legends={[
            {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: -40,
                itemsSpacing: 30,
                itemWidth: 30,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 12,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}
    />
)
  
}
