



import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { NavLink, useNavigate } from "react-router-dom";


const columns = [
  { id: 'user_id', label: 'Id', minWidth: 150, align: 'center' },
  { id: 'name', label: 'Name', minWidth: 170, align: 'center', },
  { id: 'email',label: 'Email',minWidth: 120, align: 'center'},
  { id: 'date',label: 'Date',align: 'center',minWidth: 100},
  { id: 'plan',label: 'Plan',align: 'center',minWidth: 100},
  { id: 'action',label: 'Action', minWidth: 80, align: 'center'},
];







const rows = [
  {
    id:1,
    user_id:'#526587',
    name:{img:'profile_avatar.svg',name:'Amber Howard'},
    email:'example@gmail.com',
    date:'Aug 31,2022',
    plan:'Free',
    
  },
  {
    id:2,
    user_id:'#526520',
    name:{img:'profile_avatar.svg',name:'Amber Howard'},
    email:'example@gmail.com',
    date:'Oct 13, 2022',
    plan:'Premium',
    
  },
  {
    id:3,
    user_id:'#105986',
    name:{img:'profile_avatar.svg',name:'Amber Howard'},
    email:'example@gmail.com',
    date:'Jun 12,2022',
    plan:'Business',
    
  },
  {
    id:4,
    user_id:'#526589',
    name:{img:'profile_avatar.svg',name:'Amber Howard'},
    email:'example@gmail.com',
    date:'Dec 22, 2022',
    plan:'Free',
    
  },
  {
    id:5,
    user_id:'#526525',
    name:{img:'profile_avatar.svg',name:'Amber Howard'},
    email:'example@gmail.com',
    date:'Sep 20,2022',
    plan:'Custom',
    
  },
  {
    id:6,
    user_id:'#256584',
    name:{img:'profile_avatar.svg',name:'Amber Howard'},
    email:'example@gmail.com',
    date:'Aug 4, 2022',
    plan:'Premium',
    
  },
  {
    id:7,
    user_id:'#200257',
    name:{img:'profile_avatar.svg',name:'Amber Howard'},
    email:'example@gmail.com',
    date:'Jun 23, 2022',
    plan:'Custom',
    
  },
  {
    id:8,
    user_id:'#696589',
    name:{img:'profile_avatar.svg',name:'Amber Howard'},
    email:'example@gmail.com',
    date:'Jul 8, 2022',
    plan:'Business',
    
  },
  {
    id:9,
    user_id:'#526587',
    name:{img:'profile_avatar.svg',name:'Amber Howard'},
    email:'example@gmail.com',
    date:'Aug 31,2022',
    plan:'Free',
    
  },
  {
    id:10,
    user_id:'#526587',
    name:{img:'profile_avatar.svg',name:'Amber Howard'},
    email:'example@gmail.com',
    date:'Aug 31,2022',
    plan:'Free',
    
  },
  {
    id:11,
    user_id:'#526587',
    name:{img:'profile_avatar.svg',name:'Amber Howard'},
    email:'example@gmail.com',
    date:'Aug 31,2022',
    plan:'Free',
    
  },
  {
    id:12,
    user_id:'#526587',
    name:{img:'profile_avatar.svg',name:'Amber Howard'},
    email:'example@gmail.com',
    date:'Aug 31,2022',
    plan:'Free',
    
  },
 
 
];

export default function UsersTable() {
    const [active, isactive] = React.useState(true);

    const [filter, setFilter] = React.useState('all');
    const [filteredRows, setFilteredRows] = React.useState(rows);
    const [FreeCount, setFreeCount] = React.useState(0);
    const [PremiumCount, setPremiumCount] = React.useState(0);
    const [BusinessCount, setBusinessCount] = React.useState(0);
    const [CustomCount, setCustomCount] = React.useState(0);

    const [searchQuery, setSearchQuery] = React.useState('');

   const navigate= useNavigate();

   const handleIconClick = (status) => {
    if (status === 'Business') {
      // Use the navigate function to redirect to a specific page
      navigate('/ticketview'); // Replace '/Business-page' with your desired URL
    }
  };

    const updateCounts = () => {
        const FreeRecords = rows.filter((row) => row.status === 'Free');
        const PremiumRecords = rows.filter((row) => row.status === 'Premium');
        const BusinessRecords = rows.filter((row) => row.status === 'Business');
        const CustomRecords = rows.filter((row) => row.status === 'Custom');

    
        setFreeCount(FreeRecords.length);
        setPremiumCount(PremiumRecords.length);
        setBusinessCount(BusinessRecords.length);
        setCustomCount(CustomRecords.length);

      };
    
      React.useEffect(() => {
        // Update the counts when filteredRows change
        updateCounts(rows);
      }, [filteredRows]);
    
  
//    ============== search function ===========

const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    if (value.trim() === '') {
      // If search query is empty, show all rows based on the selected filter
      handleFilterChange(filter);
    } else {
      // Filter rows based on search query and the selected filter
      setFilteredRows(
        rows.filter(
          (row) =>
            row.user.name.toLowerCase().includes(value.toLowerCase()) ||
            row.invoice_no.includes(value)
        )
      );
    }
  };




    // const handleFilterChange = (status) => {
    //     setFilter(status);
    //     if (status === 'all') {
    //       setFilteredRows(rows);
    //     } else {
    //       setFilteredRows(rows.filter((row) => row.status === status));
    //     }
    //   };


    const handleFilterChange = (status) => {
        setFilter(status);
        if (status === 'all') {
            handleactive(); 
          setFilteredRows(rows);
        } else if (status === 'Free') {
            handleactive1();
          setFilteredRows(rows.filter((row) => row.status === 'Free'));
        } else if (status === 'Premium') {
            handleactive2();
          setFilteredRows(rows.filter((row) => row.status === 'Premium'));
        } else if (status === 'Business') {
            handleactive3();
          setFilteredRows(rows.filter((row) => row.status === 'Business'));
        }
        else if (status === 'Custom')
        {
            handleactive4();
        //   setFilteredRows(rows.filter((row) => row.status === 'Custom'));
        setFilteredRows(rows.filter((row) => row.status === 'Custom'));


        }
      };

    const handleactive = () => {
      isactive(true);
      isactive1(false);
      isactive2(false);
      isactive3(false);
      isactive4(false);
    };
    const [active1, isactive1] = React.useState(false);
  
    const handleactive1 = () => {
      isactive(false);
      isactive1(true);
      isactive2(false);
      isactive3(false);
      isactive4(false);
    };
    const [active2, isactive2] = React.useState(false);
  
    const handleactive2 = () => {
      isactive(false);
      isactive1(false);
      isactive2(true);
      isactive3(false);
      isactive4(false);
    };
    const [active3, isactive3] = React.useState(false);
  
    const handleactive3 = () => {
      isactive(false);
      isactive1(false);
      isactive2(false);
      isactive3(true);
      isactive4(false);
    };
    const [active4, isactive4] = React.useState(false);
  
    const handleactive4 = () => {
      isactive(false);
      isactive1(false);
      isactive2(false);
      isactive3(false);
      isactive4(true);
    };






  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
 

 





  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  function SetStateAll(){handleFilterChange('all')};
  function SetStatePaid(){handleFilterChange('Free')};
  function SetStateUnpaid(){handleFilterChange('Premium')};
  function SetStateOverdue(){handleFilterChange('Business')};
  function SetStateCustom(){handleFilterChange('Custom')};


  return (
    <div className='mt-20 ml-20 mr-20 '>
    
    <div className="table_head">


<div className="tabs_pad d-flex">

  <NavLink
            //   onClick={handleactive}
            onClick={SetStateAll}

              className={active ? "nav_table_active" : "nav_table"}
            >
              All<span className="badge bg-light badge_txt ml-8">{rows.length}</span>
            </NavLink>
            <NavLink
            //   onClick={handleactive1}
            onClick={SetStatePaid}
              className={active1 ? "nav_table_active ml-30" : "nav_table ml-30"}
            >
             Free<span className="badge bg-success ml-8 badge_txt1">{FreeCount}</span>
            </NavLink>
            <NavLink
            //   onClick={handleactive2}
            onClick={SetStateUnpaid}
              className={active2 ? "nav_table_active ml-30" : "nav_table ml-30"}
            >
             Premium<span className="badge ml-8 badge_bg badge_txt1">{PremiumCount}</span>
            </NavLink>
            <NavLink
            //   onClick={handleactive3}
            onClick={SetStateOverdue}
              className={active3 ? "nav_table_active ml-30" : "nav_table ml-30"}
            >
              Business<span className="badge badge_bg1 ml-8 badge_txt1">{BusinessCount}</span>
            </NavLink>
            <NavLink
            //   onClick={handleactive3}
            onClick={SetStateCustom}
              className={active4 ? "nav_table_active ml-30" : "nav_table ml-30"}
            // className= "nav_table ml-30"

            >
              Custom<span className="badge badge_bg1 ml-8 badge_txt1">{CustomCount}</span>
            </NavLink>
            


</div>
</div>


<div className="dropdownTable  mb-5 " style={{background:'white'}}>
            {/* <button className='btn btn-primary dropdown-toggle'>Activity</button> */}
            <div className="d-flex align-items-center">
            <div className="ml-20 ">
                <img src="search_icn.svg" className="search_icn" alt='search' />
                <input className="ticket_input" placeholder="Search client or ticket no"
                value={searchQuery}
                onChange={handleSearchChange} />
              </div>

              
               <TablePagination
    // rowsPerPageOptions={[10, 20,{ label: 'All', value: -1 }]}
    rowsPerPageOptions={[8, 20, { label: 'All', value: -1 }]}
    sx={{ marginLeft:'auto' ,fontSize: '400 !important'}}
    component="div"
    count={rows.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
  />


            </div>







         



   
    <Paper className="mr-20 ml-20" sx={{ width: '100%',height:'35.333vw',marginLeft:'auto',marginRight:'auto',paddingLeft:'1.333vw',paddingRight:'1.333vw',overflow: 'hidden' }}>
    
      <TableContainer sx={{ maxHeight: '35.333vw'}}>

        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className='table-headd'
                >
                  <div style={{ display: 'flex',justifyContent:'center',alignItems:'center' }}>
                   
                  {column.label} 
                   
                  {column.label !== 'Action' && (
  <img
    src="data_icn.svg"
    alt="Column Icon"
    className="data_icn"
    style={{ marginLeft: "8px" }}
  />
)}
                  </div>
                  {/* {column.label} */}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
<TableBody>
            {
            filteredRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align} style={{fontSize:'600 !important'}}
                      // className={column.id === 'activity' ? (value === 'Online' ? 'onlinecss' : 'offlinecss') : ''}
                      >
                        {column.id === 'name' ? (
                          <div style={{ display: 'flex', alignItems: 'center',justifyContent:'center' }}>
                            <img src={value.img} alt={value.name} style={{ marginRight: '8px' }} />
                            {value.name}
                          </div>
                            ): column.id === 'plan' ? (
                            <div className="activity-status">
                             {value === 'Free' ? (<span className="open_css" >Free</span>) :value === 'Business' ? (<span className="unassigne_css">Business</span>): value === 'Premium' ? (<span  className="close_css">Premium</span>):<span  className="custom_css">Custom</span>}
                          </div>
                        ) : column.id === 'action' ? (
                          <div className='action-cell'>
                            {/* delimg:'trash_icn.svg',editimg:'edit_icn.svg' */}
                            <img src='/images/Admin-Sales-Table-Group 47.svg' alt="action" className='trash_icn' style={{ cursor: 'pointer' }}  onClick={() => navigate("/userdetail")}/>
                            
                          </div>
                        ) : (
                          value
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>




          
          
        </Table>
      </TableContainer>
      
    </Paper>
    </div>
    </div>
  );
}
