import React from "react";
import "../Dashboard/Dashboard.css";
import { useNavigate } from "react-router-dom";
import LiveChatSidebar from "../SupportComponent/LiveChatSidebar";

export default function LiveChatUnassignedTicket() {
  const navigate = useNavigate();
  const navigateCross = () =>{
    navigate('/ticket');
  }
  const handlenavigate = () =>{
    navigate('/liveopenticket')
  }
  return (
    <div>
      <div className="dashboard_bg">
        <div className="d-flex">
          <div className="ticket_dashboard_sidebar">
            <LiveChatSidebar />
          </div>
          <div className="top_banner">
            <div className="d-flex">
              <img
                className="menu_icon mt-32 ml-22"
                src="menu_icon.svg"
                alt="Menu"
              />

              <img
                className="logout_icon mt-32 justify-content-end"
                src="logout_icon.svg"
                alt="Logout"
              />

              <div className="ml-18 mt-12">
                <div className="profile_round_dashboard">
                  <img
                    className="rounded-circle profile_logo"
                    src="profile.jpeg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="d-flex ml-20">
            <div className="mt-40 bg-white unassign_card unassign_card_bg">
              {/* <div className=""></div> */}
              <div className="justify-content-between d-flex align-items-center">
                <div className="d-flex mt-39">
                  <img onClick={navigateCross} className="ml-40 ticket_cross" src="cross_icn.svg" alt="cancel" />
                  <div className="font-family ml-24 fnt16">#526587</div>
                  <div className="font-family ml-24 fnt16">
                    Need help! Would like to upgrade plan
                  </div>
                </div>
                <div className="mt-35">
                <div className="activity4">
                  <div className="activity3">Unassigned</div>
                </div>
                <img type="button" onClick={handlenavigate} className="ml-10 mr-30 email_reply_icn" src="email_reply_icn.svg" alt="reply" />
                </div>
              </div>
              <div className="unassign_brdr"></div>
              <div className="container chat_section">
                <div className="d-flex justify-content-between align-items-center pt-32">
                <div className="d-flex">
                <img className="avatar chat_icn" src="profile_avatar.svg" alt="profile" />
                <div className="ml-18 msg_txt font-family">Jacob Jones</div>
                </div>
                <div className="font-family msg_txt1">2 min ago</div>
                </div>
                <div className="font-family mt-20 ml-55 fnt16_light chat_width">
                Hi,<br /> Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. Voluptate exercitation incididunt aliquip deserunt reprehenderit elit laborum. Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. Voluptate exercitation incididunt aliquip deserunt reprehenderit elit laborum.
                </div>
              </div>
              </div>
            
            <div>
              {/* User Details */}
            <div className="mt-40 ml-20 user_details">
            <div className="unassign_card1 d-flex">
              <div className="user_txt font-family">User Details</div>
              <img className="user_bg" src="user_bg.svg" alt="logo" />
            </div>
            <div>
              <img className="avatar mt-16 ml-16" src="profile_avatar.svg" alt="profile" />
              <div className="font-family mt-8 ml-16 user_text">Jacob Jones</div>
              <div className="font-family mt-8 ml-16 fnt12">example@email.com</div>
              <div className="font-family mt-8 ml-16 fnt12_blue">Free User</div>
            </div>
            </div>
              {/* Ticket Details */}
            <div className="mt-16 ml-20 ticket_details">
            <div className="unassign_card1 d-flex">
              <div className="user_txt font-family">Ticket Details</div>
              <img className="user_bg" src="user_bg.svg" alt="logo" />
            </div>
            <div className="ml-16 mt-16 font-family fnt16">Ticket Number:</div>
            <div className="ml-16 font-family fnt12_grey">526587</div>
            <div className="ml-16 mt-16 font-family fnt16">Ticket Subject:</div>
            <div className="ml-16 font-family fnt12_grey">Need help! would like to upgrade my plan</div>
            <div className="ml-16 mt-16 font-family fnt16">Ticket Date:</div>
            <div className="ml-16 font-family fnt12_grey">10-Nov-2022</div>
            <div className="ml-16 mt-16 font-family fnt16">Ticket Status:</div>
            <div className="ml-16 font-family">
              <select className="select_status">
                <option>Unassigned</option>
                <option>Open</option>
                <option>Close</option>
                </select>
            </div>
            <button className="font-family button_ticket mt-16 ml-16">Update</button>
            </div>
              {/* Timeline */}
            <div className="mt-16 ml-20 timeline">
            <div className="unassign_card1 d-flex">
              <div className="user_txt font-family">Timeline</div>
              <img className="user_bg" src="user_bg.svg" alt="logo" />
            </div>
            <div className="overflow_y">
              
            <ul className="ulist_timeline">
            <li className="list_timeline">
            <div className=" mt-16 font-family fnt16">Need help! would like to upgrade my plan</div>
            <div className=" font-family fnt12_grey">10-Nov-2022</div>
            <div className=" font-family fnt12_grey">Status: Unassigned</div>
            </li>
            <li className="list_timeline">
            <div className=" mt-16 font-family fnt16">Need help! would like to upgrade my plan</div>
            
            <div className=" font-family fnt12_grey">10-Nov-2022</div>
            <div className=" font-family fnt12_grey">Status: Seen</div>
            </li>
            <li className="list_timeline">
            <div className=" mt-16 font-family fnt16">Need help! would like to upgrade my plan</div>
            
            <div className=" font-family fnt12_grey">10-Nov-2022</div>
            <div className=" font-family fnt12_grey">Status: Seen</div>
            </li>
            </ul>
            </div>
            </div>
            </div>
            </div>
          </div>
          {/* Dashboard Side Ends Here  */}
        </div>
      </div>
    </div>
  );
}
