import React from "react";
import { ResponsiveBar } from "@nivo/bar";

export default function FeedbackBarYearly() {
    const data = [
        {
          "days": "2018",
          "day": 17000,
          "hot dogColor": "hsl(313, 70%, 50%)"
        },
        {
          "days": "Tue",
          "day": 13500,
          "hot dogColor": "hsl(58, 70%, 50%)"
        },
        {
          "days": "Wed",
          "day": 14000,
          "hot dogColor": "hsl(40, 70%, 50%)"
        },
        {
          "days": "Thur",
          "day": 16000,
          "hot dogColor": "hsl(147, 70%, 50%)"
        },
        {
          "days": "Fri",
          "day": 13500,
          "hot dogColor": "hsl(237, 70%, 50%)"
        },
        {
          "days": "Sat",
          "day": 20000,
          "hot dogColor": "hsl(290, 70%, 50%)"
        },
        {
          "days": "Sun",
          "day": 18000,
          "hot dogColor": "hsl(38, 70%, 50%)"
        }
      ]
      const tickValues = [0, 10000, 15000, 20000];
  return (
    <ResponsiveBar
      data={data}
      keys={["day"]}
      indexBy="days"
      margin={{ top: 40, right: 0, bottom: 20, left: 40 }}
      padding={0.6}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={'#3f4299'}
    
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        // legend: "country",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        // legend: "food",
        legendPosition: "middle",
        legendOffset: -40,
        format: value => `${value / 1000}k`,
        tickValues: tickValues
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[]}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={(e) =>
        e.id + ": " + e.formattedValue + " in country: " + e.indexValue
      }
    />
  );
}
