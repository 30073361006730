import React from "react";
import { ResponsiveBar } from "@nivo/bar";

export default function BarYearly() {
    const data = [
        {
          "days": "2016",
          "Year": 9,
          "hot dogColor": "hsl(313, 70%, 50%)"
        },
        {
          "days": "2017",
          "Year": 17,
          "hot dogColor": "hsl(58, 70%, 50%)"
        },
        {
          "days": "2018",
          "Year": 14,
          "hot dogColor": "hsl(40, 70%, 50%)"
        },
        {
          "days": "2019",
          "Year": 5,
          "hot dogColor": "hsl(147, 70%, 50%)"
        },
        {
          "days": "2020",
          "Year": 16,
          "hot dogColor": "hsl(237, 70%, 50%)"
        },
        {
          "days": "2021",
          "Year": 14,
          "hot dogColor": "hsl(290, 70%, 50%)"
        },
        {
          "days": "2022",
          "Year": 13.5,
          "hot dogColor": "hsl(38, 70%, 50%)"
        },
        {
          "days": "2023",
          "Year": 16,
          "hot dogColor": "hsl(38, 70%, 50%)"
        }
      ]
      const tickValues = [0, 8, 16, 24];
  return (
    <ResponsiveBar
      data={data}
      keys={["Year"]}
      indexBy="days"
      margin={{ top: 20, right: 0, bottom: 20, left: 50 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={'#3f4299'}
    
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        // legend: "country",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        // legend: "food",
        legendPosition: "middle",
        legendOffset: -40,
        format: (value)=> `${value}h`,
        tickValues: tickValues
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[]}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={(e) =>
        e.id + ": " + e.formattedValue + " in country: " + e.indexValue
      }
    />
  );
}
