import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { NavLink, useNavigate } from "react-router-dom";


const columns = [
  { id: 'invoice_no', label: 'Invoive no', minWidth: 150, align: 'center' },
  { id: 'user', label: 'User', minWidth: 170, align: 'center', },
  { id: 'date',label: 'Date',minWidth: 120, align: 'center'},
  { id: 'amount',label: 'Amount',align: 'center',minWidth: 100},
  { id: 'status',label: 'Status',align: 'center',minWidth: 100},
  { id: 'action',label: 'Action', minWidth: 80, align: 'center'},
];



function Delete()
{
alert('delete');
}
function Edit()
{
alert('edit');
}
const rows = [
  {
    id:1,
    invoice_no:'#526587',
    user:{img:'profile_avatar.svg',name:'Amber Howard'},
    date:'Aug 31,2022',
    amount:'$1,200',
    status:'Paid',
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action:{delete:Delete,edit:Edit}
  },
  {
    id:2,
    invoice_no:'#111111',
    user:{img:'profile_avatar.svg',name:'Esther Howard'},
    date:'Aug 31,2022',
    amount:'$1,200',
   status:'Unpaid',
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action:{delete:Delete,edit:Edit}
  },
  {
    id:3,
    invoice_no:'#526587',
    user:{img:'profile_avatar.svg',name:'Esther Howard'},
    date:'Aug 31,2022',
    amount:'$1,200',
   status:'Overdue',
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action:{delete:Delete,edit:Edit}
  },
  {
    id:4,
    invoice_no:'#526587',
    user:{img:'profile_avatar.svg',name:'Esther Howard'},
    date:'Aug 31,2022',
    amount:'$1,200',
   status:'Unpaid',
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action:{delete:Delete,edit:Edit}
  },
  {
    id:5,
    invoice_no:'#526587',
    user:{img:'profile_avatar.svg',name:'Esther Howard'},
    date:'Aug 31,2022',
    amount:'$1,200',
   status:'Paid',
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action:{delete:Delete,edit:Edit}
  },
  {
    id:6,
    invoice_no:'#526587',
    user:{img:'profile_avatar.svg',name:'Esther Howard'},
    date:'Aug 31,2022',
    amount:'$1,200',
   status:'Paid',
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action:{delete:Delete,edit:Edit}
  },
  {
    id:7,
    invoice_no:'#526587',
    user:{img:'profile_avatar.svg',name:'Esther Howard'},
    date:'Aug 31,2022',
    amount:'$1,200',
   status:'Paid',
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action:{delete:Delete,edit:Edit}
  },
  {
    id:8,
    invoice_no:'#526587',
    user:{img:'profile_avatar.svg',name:'Esther Howard'},
    date:'Aug 31,2022',
    amount:'$1,200',
   status:'Overdue',
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action:{delete:Delete,edit:Edit}
  },
  {
    id:9,
    invoice_no:'#526587',
    user:{img:'profile_avatar.svg',name:'Esther Howard'},
    date:'Aug 31,2022',
    amount:'$1,200',
   status:'Paid',
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action:{delete:Delete,edit:Edit}
  },
  {
    id:10,
    invoice_no:'#526587',
    user:{img:'profile_avatar.svg',name:'Esther Howard'},
    date:'Aug 31,2022',
    amount:'$1,200',
   status:'Paid',
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action:{delete:Delete,edit:Edit}
  }
  ,
  {
    id:11,
    invoice_no:'#526587',
    user:{img:'profile_avatar.svg',name:'Esther Howard'},
    date:'Aug 31,2022',
    amount:'$1,200',
   status:'Unpaid',
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action:{delete:Delete,edit:Edit}
  }
  ,
  {
    id:12,
    invoice_no:'#526587',
    user:{img:'profile_avatar.svg',name:'Esther Howard'},
    date:'Aug 31,2022',
    amount:'$1,200',
   status:'Paid',
    // action:{delimg:'trash_icn.svg',editimg:'edit_icn.svg'}
    action:{delete:Delete,edit:Edit}
  }
 
];

export default function InvoiceTable() {
    const [active, isactive] = React.useState(true);

    const [filter, setFilter] = React.useState('all');
    const [filteredRows, setFilteredRows] = React.useState(rows);
    const [paidCount, setPaidCount] = React.useState(0);
    const [unpaidCount, setUnpaidCount] = React.useState(0);
    const [overdueCount, setOverdueCount] = React.useState(0);
    const [searchQuery, setSearchQuery] = React.useState('');

   const navigate= useNavigate();

    const updateCounts = () => {
        const paidRecords = rows.filter((row) => row.status === 'Paid');
        const unpaidRecords = rows.filter((row) => row.status === 'Unpaid');
        const overdueRecords = rows.filter((row) => row.status === 'Overdue');
    
        setPaidCount(paidRecords.length);
        setUnpaidCount(unpaidRecords.length);
        setOverdueCount(overdueRecords.length);
      };
    
      React.useEffect(() => {
        // Update the counts when filteredRows change
        updateCounts(rows);
      }, [filteredRows]);
    
  
//    ============== search function ===========

const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    if (value.trim() === '') {
      // If search query is empty, show all rows based on the selected filter
      handleFilterChange(filter);
    } else {
      // Filter rows based on search query and the selected filter
      setFilteredRows(
        rows.filter(
          (row) =>
            row.user.name.toLowerCase().includes(value.toLowerCase()) ||
            row.invoice_no.includes(value)
        )
      );
    }
  };




    // const handleFilterChange = (status) => {
    //     setFilter(status);
    //     if (status === 'all') {
    //       setFilteredRows(rows);
    //     } else {
    //       setFilteredRows(rows.filter((row) => row.status === status));
    //     }
    //   };


    const handleFilterChange = (status) => {
        setFilter(status);
        if (status === 'all') {
            handleactive();
          setFilteredRows(rows);
        } else if (status === 'Paid') {
            handleactive1();
          setFilteredRows(rows.filter((row) => row.status === 'Paid'));
        } else if (status === 'Unpaid') {
            handleactive2();
          setFilteredRows(rows.filter((row) => row.status === 'Unpaid'));
        } else if (status === 'Overdue') {
            handleactive3();
          setFilteredRows(rows.filter((row) => row.status === 'Overdue'));
        }
      };

    const handleactive = () => {
      isactive(true);
      isactive1(false);
      isactive2(false);
      isactive3(false);
      isactive4(false);
    };
    const [active1, isactive1] = React.useState(false);
  
    const handleactive1 = () => {
      isactive(false);
      isactive1(true);
      isactive2(false);
      isactive3(false);
      isactive4(false);
    };
    const [active2, isactive2] = React.useState(false);
  
    const handleactive2 = () => {
      isactive(false);
      isactive1(false);
      isactive2(true);
      isactive3(false);
      isactive4(false);
    };
    const [active3, isactive3] = React.useState(false);
  
    const handleactive3 = () => {
      isactive(false);
      isactive1(false);
      isactive2(false);
      isactive3(true);
      isactive4(false);
    };
    const [active4, isactive4] = React.useState(false);
  
    const handleactive4 = () => {
      isactive(false);
      isactive1(false);
      isactive2(false);
      isactive3(false);
      isactive4(true);
    };






  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
 

 





  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  function SetStateAll(){handleFilterChange('all')};
  function SetStatePaid(){handleFilterChange('Paid')};
  function SetStateUnpaid(){handleFilterChange('Unpaid')};
  function SetStateOverdue(){handleFilterChange('Overdue')};

  return (
    <div className='mt-20 ml-20 mr-20 '>
    
    <div className="table_head">


<div className="tabs_pad d-flex">

  <NavLink
            //   onClick={handleactive}
            onClick={SetStateAll}

              className={active ? "nav_table_active" : "nav_table"}
            >
              All<span className="badge bg-light badge_txt ml-8">{rows.length}</span>
            </NavLink>
            <NavLink
            //   onClick={handleactive1}
            onClick={SetStatePaid}
              className={active1 ? "nav_table_active ml-30" : "nav_table ml-30"}
            >
              Paid<span className="badge bg-success ml-8 badge_txt1">{paidCount}</span>
            </NavLink>
            <NavLink
            //   onClick={handleactive2}
            onClick={SetStateUnpaid}
              className={active2 ? "nav_table_active ml-30" : "nav_table ml-30"}
            >
              Unpaid<span className="badge ml-8 badge_bg badge_txt1">{unpaidCount}</span>
            </NavLink>
            <NavLink
            //   onClick={handleactive3}
            onClick={SetStateOverdue}
              className={active3 ? "nav_table_active ml-30" : "nav_table ml-30"}
            >
              Overdue<span className="badge badge_bg1 ml-8 badge_txt1">{overdueCount}</span>
            </NavLink>
            


</div>
</div>


<div className="dropdownTable  mb-5 " style={{background:'white'}}>
            {/* <button className='btn btn-primary dropdown-toggle'>Activity</button> */}
            <div className="d-flex align-items-center">
            <div className="ml-20 ">
                <img src="search_icn.svg" className="search_icn" alt='search' />
                <input className="ticket_input" placeholder="Search client or ticket no"
                value={searchQuery}
                onChange={handleSearchChange} />
              </div>

              <button
                className="add-invoice-btn"
                onClick={() => navigate('/newInvoice')}
              >
                +New Invoice
              </button>
              
               <TablePagination
    // rowsPerPageOptions={[10, 20,{ label: 'All', value: -1 }]}
    rowsPerPageOptions={[8, 20, { label: 'All', value: -1 }]}
    sx={{ marginLeft:'auto' ,fontSize: '400 !important'}}
    component="div"
    count={rows.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
  />


            </div>







         



   
    <Paper className="mr-20 ml-20" sx={{ width: '100%',height:'35.333vw',marginLeft:'auto',marginRight:'auto',paddingLeft:'1.333vw',paddingRight:'1.333vw',overflow: 'hidden' }}>
    
      <TableContainer sx={{ maxHeight: '35.333vw'}}>

        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className='table-headd'
                >
                  <div style={{ display: 'flex',justifyContent:'center',alignItems:'center' }}>
                   
                  {column.label} 
                  {column.label !== 'Action' && (
  <img
    src="data_icn.svg"
    alt="Column Icon"
    className="data_icn"
    style={{ marginLeft: "8px" }}
  />
)}
                    {/* <img src="data_icn.svg" alt="Column Icon" className="data_icn" style={{ marginLeft: '8px' }} /> */}
                  </div>
                  {/* {column.label} */}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
<TableBody>
            {
            filteredRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align} style={{fontSize:'600 !important'}}
                      // className={column.id === 'activity' ? (value === 'Online' ? 'onlinecss' : 'offlinecss') : ''}
                      >
                        {column.id === 'user' ? (
                          <div style={{ display: 'flex', alignItems: 'center',justifyContent:'center' }}>
                            <img src={value.img} alt={value.name} style={{ marginRight: '8px' }} />
                            {value.name}
                          </div>
                            ): column.id === 'status' ? (
                            <div className="activity-status">
                             {value === 'Paid' ? (<span className="onlinecss">Paid</span>) :value === 'Overdue' ? (<span className="overduecss">Overdue</span>):<span className="offlinecss">Unpaid</span>}
                          </div>
                        ) : column.id === 'action' ? (
                          <div className='action-cell'>
                            {/* delimg:'trash_icn.svg',editimg:'edit_icn.svg' */}
                            <img src='/images/action.svg' alt="action" className='trash_icn' style={{ cursor: 'pointer' }} onClick={Delete}/>
                            
                          </div>
                        ) : (
                          value
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>




          
          
        </Table>
      </TableContainer>
      
    </Paper>
    </div>
    </div>
  );
}
