

// import  React,{useState} from 'react';
// import { DateRange } from 'react-date-range';
// import 'react-date-range/dist/styles.css';
// import 'react-date-range/dist/theme/default.css'; 



// export default function RangeCalender() {
//     const [state, setState] = useState([
//         {
//           startDate: new Date(),
//           endDate: null,
//           key: 'selection'
//         }
//       ]);
  

//   return (
//     <div>
//       <DateRange className='calender'
//   editableDateInputs={true}
//   onChange={item => setState([item.selection])}
//   moveRangeOnFirstSelection={false}
//   ranges={state}
// />
//     </div>
//   )
// }









import { useState } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

// type ValuePiece = Date | null;

// type Value = ValuePiece | [ValuePiece, ValuePiece];

export default function RangeCalender() {
  const [value, onChange] = useState([new Date(), new Date()]);
  console.log(value);

  return (
    <div>
      <DateRangePicker onChange={onChange} value={value} className='calender-input mt-8' />
    </div>
  );
}







