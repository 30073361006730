import React, { useState } from "react";
import "../Dashboard/Dashboard.css";
import DashboardSidebar from "../DashboardComponent/DashboardSidebar";
import Chart1 from "../DashboardComponent/Chart1";
import { NavLink } from "react-router-dom";
import Chart2 from "../DashboardComponent/Chart2";
import BarChart from "../DashboardComponent/BarChart";
import LineMonthly from "../DashboardComponent/LineMonthly";
import LineWeekly from "../DashboardComponent/LineWeekly";
import LineYearly from "../DashboardComponent/LineYearly";
import BarMonthly from "../DashboardComponent/BarMonthly";
import BarWeekly from "../DashboardComponent/BarWeekly";
import BarYearly from "../DashboardComponent/BarYearly";
import DashboardTable from "../DashboardComponent/DashboardTable";
import TicketSidebar from "../SupportComponent/TicketSidebar";
import TicketTable from "../SupportComponent/TicketTable";

export default function TicketDashboard() {
  const [active, isactive] = useState(true);
  const handleactive = () => {
    isactive(true);
    isactive1(false);
    isactive2(false);
    isactive3(false);
  };
  const [active1, isactive1] = useState(false);
  const handleactive1 = () => {
    isactive1(true);
    isactive(false);
    isactive2(false);
    isactive3(false);
  };
  const [active2, isactive2] = useState(false);
  const handleactive2 = () => {
    isactive(false);
    isactive1(false);
    isactive2(true);
    isactive3(false);
  };
  const [active3, isactive3] = useState(false);
  const handleactive3 = () => {
    isactive(false);
    isactive1(false);
    isactive2(false);
    isactive3(true);
  };
  const [BarActive, isBarActive] = useState(true);
  const handleactiveBar = () => {
    isBarActive(true);
    isBarActive1(false);
    isBarActive2(false);
    isBarActive3(false);
  };
  const [BarActive1, isBarActive1] = useState(false);
  const handleactiveBar1 = () => {
    isBarActive1(true);
    isBarActive(false);
    isBarActive2(false);
    isBarActive3(false);
  };
  const [BarActive2, isBarActive2] = useState(false);
  const handleactiveBar2 = () => {
    isBarActive(false);
    isBarActive1(false);
    isBarActive2(true);
    isBarActive3(false);
  };
  const [BarActive3, isBarActive3] = useState(false);
  const handleactiveBar3 = () => {
    isBarActive(false);
    isBarActive1(false);
    isBarActive2(false);
    isBarActive3(true);
  };

  return (
    <div>
      <div className="dashboard_bg">
        <div className="row mr-0">
          <div className="col-3 ticket_dashboard_sidebar">
            <TicketSidebar />
          </div>
          <div className="top_banner col-9">
            <div className="d-flex">
              <img
                className="menu_icon mt-32 ml-22"
                src="menu_icon.svg"
                alt="Menu"
              />

              <img
                className="logout_icon mt-32 justify-content-end"
                src="logout_icon.svg"
                alt="Logout"
              />

              <div className="ml-18 mt-12">
                <div className="profile_round_dashboard">
                  <img
                    className="rounded-circle profile_logo"
                    src="profile.jpeg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-between'>
            <div className="font-family mt-32 ml-20 dash_text">Tickets</div>
            <div className='content_txt font-family mt-32'>Support > Tickets</div>
            </div>
            <div className="mt-20 ml-20 row">
              <div className="pad_none">
                <div className="d-flex">
                  <div className="free_user_bg">
                    <div className="d-flex">
                      <div>
                        <div className="free_user font-family">Response Time</div>
                        <div className="free_num">1 min</div>
                      </div>
                      <div>
                        <img
                          src="graph_line.svg"
                          alt="Graph"
                          className="graph_line_ticket"
                        />
                      </div>
                    </div>
                    <div>
                      <img
                        src="increasing_icon.svg"
                        alt="increasing"
                        className="increase_icon"
                      />
                      <span className="incr">+5%</span>{" "}
                      <span className="incr1">than last month</span>
                    </div>
                  </div>
                  <div className="free_user_bg ml-20">
                    <div className="d-flex">
                      <div>
                        <div className="free_user font-family">
                          Rating
                        </div>
                        <div className="free_num">3.5</div>
                      </div>
                      <div>
                        <img
                          src="graph_line1.svg"
                          alt="Graph "
                          className="graph_line1"
                        />
                      </div>
                    </div>
                    <div>
                      <img
                        src="decreasing_icon.svg"
                        alt="increasing"
                        className="increase_icon"
                      />
                      <span className="decr">-2%</span>{" "}
                      <span className="decr1">than last month</span>
                    </div>
                  </div>
                <div className="d-flex ml-20">
                  <div className="free_user_bg">
                    <div className="d-flex">
                      <div>
                        <div className="free_user font-family">
                          Tickets: Total
                        </div>
                        <div className="free_num">5,103</div>
                      </div>
                      <div>
                        <img
                          src="graph_line1.svg"
                          alt="Graph"
                          className="graph_line1"
                        />
                      </div>
                    </div>
                    <div>
                      <img
                        src="decreasing_icon.svg"
                        alt="increasing"
                        className="increase_icon"
                      />
                      <span className="decr">-10%</span>{" "}
                      <span className="decr1">than last month</span>
                    </div>
                  </div>
                  <div className="free_user_bg ml-20">
                    <div className="d-flex">
                      <div>
                        <div className="free_user font-family">
                        Tickets: Today
                        </div>
                        <div className="free_num">2,475</div>
                      </div>
                      <div>
                        <img
                          src="graph_line.svg"
                          alt="Graph "
                          className="graph_line"
                        />
                      </div>
                    </div>
                    <div>
                      <img
                        src="increasing_icon.svg"
                        alt="increasing"
                        className="increase_icon"
                      />
                      <span className="incr">+4%</span>{" "}
                      <span className="incr1">than last month</span>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
            <TicketTable />
          </div>
          {/* Dashboard Side Ends Here  */}
        </div>
      </div>
    </div>
  );
}
