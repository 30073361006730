import React from "react";
import { Link } from "react-router-dom";

export default function ForgotPass2() {
  return (
    <div>
      <div className="select_background">
      <div>
          <img src="login_bottom.svg" alt="bottom-pic" className="bot_img" />
          <img src="login_top.svg" alt="top-pic" className="top_img" />
          </div>
        <div className="align_1"></div>
        <Link to='/reset'>
        <div className="forgot_card2">
          <div className="logo_positioning">
            <img
              className="select_type_logo"
              alt="logo"
              src="select_type_logo.svg"
            />
          </div>
          <div className="font-family font_user mt-24 lnk_black">Email Sent</div>
          <div className="font-family font_user1 ml-44 mr-44 mt-8 lnk_black">
          Check your email and open the link we sent to continue
          </div>
         
        </div>
        </Link>
        </div>
      </div>
  );
}
