import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Login_2() {
  const [password, setPassword] = useState("");
  // const [error, setError] = useState("");
  // const [emailError, setEmailError] = useState("");

  const [passwordType, setPasswordType] = useState("password");
  // const [isLoading, setIsLoading] = useState(false);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  return (
    <div>
      <div className="select_background">
      <div>
          <img src="login_bottom.svg" alt="bottom-pic" className="bot_img" />
          <img src="login_top.svg" alt="top-pic" className="top_img" />
          </div>
          <div className="align_1">
        <div className="user_card">
          <div className="logo_positioning">
            <img
              className="select_type_logo"
              alt="logo"
              src="select_type_logo.svg"
            />
          </div>
          <div className="font-family font_user mt-24">Sales Log In</div>
          <div className="font-family font_user1 mt-8">
            Log in to your account
          </div>
          <div className="email_text mt-32 ml-30 font-family">Email Id</div>
          <div className="input_size ml-30 mt-7">
            <div className="d-flex">
              <img className="email_icon img-fluid" src="vector2.svg" alt="" />
              <input
                className="input_email"
                type="email"
                required
                placeholder="Enter Your Email"
                //   value={email}
                //   onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="pass_text ml-30 mt-12 font-family">Password</div>
          <div className="input_size ml-30">
            {/* password field   */}
            <div className="d-flex">
              <img
                className="email_icon img-fluid"
                src="Vectorlock.svg"
                alt=""
              />
              <input
                className="input_email font-family"
                type={passwordType}
                id="psd"
                required
                placeholder="Enter Your Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* <img onClick={togglePassword} className="login049 img-fluid"
                                                                src="invisible 1.svg" alt=""/> */}

              <div className=" " onClick={togglePassword}>
                {passwordType === "password" ? (
                  <img
                    type="button"
                    className="eye_img img-fluid "
                    src="eye-slash.svg"
                    alt=""
                  />
                ) : (
                  <img
                    type="button"
                    className="eye_img img-fluid"
                    src="eye.svg"
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>
          <Link to='/forgot' className="lnk_black">
          <div type="button" className="forgot_pas font-family">
            Forgot Password ?
          </div>
          </Link>
          <Link to='/dashboard'>
            <button className="continue_btn font-family mt-4">Log In</button>
          </Link>
        </div>
      </div>
      </div>
    </div>
  );
}
