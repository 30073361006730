import React from "react";
import { Link } from "react-router-dom";

export default function ForgotPass() {
  return (
    <div>
      <div className="select_background">
      <div>
          <img src="login_bottom.svg" alt="bottom-pic" className="bot_img" />
          <img src="login_top.svg" alt="top-pic" className="top_img" />
          </div>
        <div className="align_1">
        <div className="forgot_card">
          <div className="logo_positioning">
            <img
              className="select_type_logo"
              alt="logo"
              src="select_type_logo.svg"
            />
          </div>
          <div className="font-family font_user mt-24">Forgot Password</div>
          <div className="font-family font_user1 mt-8">
            Enter email to get password reset link
          </div>
          <div className="email_text mt-32 ml-30 font-family">Email Id</div>
          <div className="input_size ml-30 mt-7">
            <div className="d-flex">
              <img className="email_icon img-fluid" src="vector2.svg" alt="" />
              <input
                className="input_email"
                type="email"
                required
                placeholder="Enter Your Email"
                //   value={email}
                //   onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <Link to='/forgot2'>
            <button className="continue_btn font-family">Continue</button>
          </Link>
        </div>
        </div>
      </div>
    </div>
  );
}
