import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

export default function LiveTicketTable() {
  const [active, isactive] = useState(true);

  const handleactive = () => {
    isactive(true);
    isactive1(false);
    isactive2(false);
    isactive3(false);
  };
  const [active1, isactive1] = useState(false);

  const handleactive1 = () => {
    isactive(false);
    isactive1(true);
    isactive2(false);
    isactive3(false);
  };
  const [active2, isactive2] = useState(false);

  const handleactive2 = () => {
    isactive(false);
    isactive1(false);
    isactive2(true);
    isactive3(false);
  };
  const [active3, isactive3] = useState(false);

  const handleactive3 = () => {
    isactive(false);
    isactive1(false);
    isactive2(false);
    isactive3(true);
  };
  
  // const toPath = anyConditionTrue ? "/Login1" : "#";
  // const handleContinue = () => {
  //   if (isClicked) {
  //     window.location.href = "/login1";
  //   } else if (isClicked2) {
  //     window.location.href = "/login2";
  //   } else if (isClicked3) {
  //     window.location.href = "/login3";
  //   } else if (isClicked4) {
  //     window.location.href = "/login4";
  //   }
  // };

  return (
    <div>
      <div className="ml-20 mt-20">
        <div className="table_head">
          <div className="tabs_pad">
            <NavLink
              onClick={handleactive}
              className={active ? "nav_table_active" : "nav_table"}
            >
              All<span className="badge bg-light badge_txt ml-8">25</span>
            </NavLink>
            <NavLink
              onClick={handleactive1}
              className={active1 ? "nav_table_active ml-30" : "nav_table ml-30"}
            >
              Open<span className="badge bg-success ml-8 badge_txt1">25</span>
            </NavLink>
            <NavLink
              onClick={handleactive2}
              className={active2 ? "nav_table_active ml-30" : "nav_table ml-30"}
            >
              Closed<span className="badge ml-8 badge_bg badge_txt1">25</span>
            </NavLink>
            <NavLink
              onClick={handleactive3}
              className={active3 ? "nav_table_active ml-30" : "nav_table ml-30"}
            >
              Unassigned<span className="badge badge_bg1 ml-8 badge_txt1">25</span>
            </NavLink>
          </div>
        </div>
        <div className="bg-white">
          <div className="dropdown">
            {/* <button className='btn btn-primary dropdown-toggle'>Activity</button> */}
            <div className="d-flex justify-content-between">
              <div className="ml-20 mt-16">
                <img src="search_icn.svg" className="search_icn" alt="search"/>
                <input className="ticket_input" placeholder="Search client or ticket no" />
              </div>
              <div className="d-flex mr-20">
                <div className="mt-16 font-family page_txt">
                  Rows per page:
                </div>
                <select className="drp_page font-family page_txt">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                </select>
                <div className="font-family mt-16 ml-20 page_txt">
                  0-7 of 11
                </div>
                <div className=" mt-18 ml-16">
                  <img type="button" src="arrow_left.svg" alt="left page" />
                </div>
                <div className=" mt-18 ml-25">
                  <img type="button" src="arrow_right.svg" alt="right page" />
                </div>
              </div>
            </div>
          </div>
          {/* =============All Tab content Starts Here============= */}
          {active && (
            <div className="ml-20 mt-16 mr-20">
              <table className="table table-borderless">
                <thead className=" table_head1 font-family">
                  <tr className="text-white text-center">
                    <th scope="col" className="head_pad1">
                      Chat Id
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                    <th scope="col" className="head_pad1">
                      Created by
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                    <th scope="col" className="head_pad1">
                      Subject
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                    <th scope="col" className="head_pad1">
                      Status
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                    <th scope="col" className="head_pad1">
                      Action
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity7">
                        <div className="activity6">Open</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity4">
                        <div className="activity3">Unassigned</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <Link to="/chatticket">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                      </Link>
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity4">
                        <div className="activity3">Unassigned</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity4">
                        <div className="activity3">Unassigned</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity">
                        <div className="activity1">Closed</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity">
                        <div className="activity1">Closed</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity4">
                        <div className="activity3">Unassigned</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity">
                        <div className="activity1">Closed</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {/* =============All Tab content Ends Here============= */}

          {/* =============Admin Tab content Starts Here============= */}
          {active1 && (
            <div className="ml-20 mt-16 mr-20">
              <table className="table table-borderless">
                <thead className=" table_head1 font-family">
                  <tr className="text-white text-center">
                    <th scope="col" className="head_pad1">
                      Ticket Id
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                    <th scope="col" className="head_pad1">
                      Created by
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                    <th scope="col" className="head_pad1">
                      Subject
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                    <th scope="col" className="head_pad1">
                      Status
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                    <th scope="col" className="head_pad1">
                      Action
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity7">
                        <div className="activity6">Open</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity7">
                        <div className="activity6">Open</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity7">
                        <div className="activity6">Open</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity7">
                        <div className="activity6">Open</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity7">
                        <div className="activity6">Open</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity7">
                        <div className="activity6">Open</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity7">
                        <div className="activity6">Open</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity7">
                        <div className="activity6">Open</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {/* =============Admin Tab content Ends Here============= */}

          {/* =============Sales Tab content Starts Here============= */}
          {active2 && (
            <div className="ml-20 mt-16 mr-20">
              <table className="table table-borderless">
                <thead className=" table_head1 font-family">
                  <tr className="text-white text-center">
                    <th scope="col" className="head_pad1">
                      Ticket Id
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                    <th scope="col" className="head_pad1">
                      Created by
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                    <th scope="col" className="head_pad1">
                      Subject
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                    <th scope="col" className="head_pad1">
                      Status
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                    <th scope="col" className="head_pad1">
                      Action
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity">
                        <div className="activity1">Closed</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity">
                        <div className="activity1">Closed</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity">
                        <div className="activity1">Closed</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity">
                        <div className="activity1">Closed</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity">
                        <div className="activity1">Closed</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity">
                        <div className="activity1">Closed</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity">
                        <div className="activity1">Closed</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity">
                        <div className="activity1">Closed</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {/* =============Sales Tab content Ends Here============= */}

          {/* =============Content Tab content Starts Here============= */}
          {active3 && (
            <div className="ml-20 mt-16 mr-20">
              <table className="table table-borderless">
                <thead className=" table_head1 font-family">
                  <tr className="text-white text-center">
                    <th scope="col" className="head_pad1">
                      Ticket Id
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                    <th scope="col" className="head_pad1">
                      Created by
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                    <th scope="col" className="head_pad1">
                      Subject
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                    <th scope="col" className="head_pad1">
                      Status
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                    <th scope="col" className="head_pad1">
                      Action
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity4">
                        <div className="activity3">Unassigned</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity4">
                        <div className="activity3">Unassigned</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity4">
                        <div className="activity3">Unassigned</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity4">
                        <div className="activity3">Unassigned</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity4">
                        <div className="activity3">Unassigned</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity4">
                        <div className="activity3">Unassigned</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity4">
                        <div className="activity3">Unassigned</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                    #526587
                    </td>
                    <td className="head_pad2"><img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard </td>
                    <td className="head_pad2">Need help! Would like to upgrade plan </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity4">
                        <div className="activity3">Unassigned</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="document_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {/* =============Content Tab content Ends Here============= */}

        </div>
      </div>

     
    </div>
  );
}
