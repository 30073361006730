import React, { useState } from "react";

export default function ContentTable() {  
  const [isClicked, setisClicked] = useState(false);
  const cardClick = () => {
    setisClicked(true);
    setisClicked2(false);
    setisClicked3(false);
    setisClicked4(false);
  };
  const [isClicked2, setisClicked2] = useState(false);
  const cardClick2 = () => {
    setisClicked(false);
    setisClicked2(true);
    setisClicked3(false);
    setisClicked4(false);
  };
  const [isClicked3, setisClicked3] = useState(false);
  const cardClick3 = () => {
    setisClicked(false);
    setisClicked2(false);
    setisClicked3(true);
    setisClicked4(false);
  };
  const [isClicked4, setisClicked4] = useState(false);
  const cardClick4 = () => {
    setisClicked(false);
    setisClicked2(false);
    setisClicked3(false);
    setisClicked4(true);
  };

  const anyConditionTrue = isClicked || isClicked2 || isClicked3 || isClicked4;
  // const toPath = anyConditionTrue ? "/Login1" : "#";
  // const handleContinue = () => {
  //   if (isClicked) {
  //     window.location.href = "/login1";
  //   } else if (isClicked2) {
  //     window.location.href = "/login2";
  //   } else if (isClicked3) {
  //     window.location.href = "/login3";
  //   } else if (isClicked4) {
  //     window.location.href = "/login4";
  //   }
  // };

  return (
    <div>
      <div className="ml-20 mt-20">
        <div className="table_head">
          <div className="tabs_pad">
            <div
              className="nav_table"
            >
              Member<span className="badge bg-light badge_txt ml-8">25</span>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="dropdown">
            {/* <button className='btn btn-primary dropdown-toggle'>Activity</button> */}
            <div className="d-flex justify-content-between">
              <div>
              <select type="button" className="drp_btn text-muted">
                <option disabled selected>
                  <span className="text-muted">Activity</span>
                </option>
                <option>Online</option>
                <option>Offline</option>
              </select>
              <button
                data-bs-target="#MemberModal"
                data-bs-toggle="modal"
                className="add_btn"
              >
                +Add Member
              </button>
              </div>
              <div className="d-flex mr-20">
                <div className="mt-16 font-family page_txt">
                  Rows per page:
                </div>
                <select className="drp_page font-family page_txt">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                </select>
                <div className="font-family mt-16 ml-20 page_txt">
                  0-7 of 11
                </div>
                <div className=" mt-18 ml-16">
                  <img type="button" src="arrow_left.svg" alt="left page" />
                </div>
                <div className=" mt-18 ml-25">
                  <img type="button" src="arrow_right.svg" alt="right page" />
                </div>
              </div>
            </div>
          </div>
          {/* =============All Tab content Starts Here============= */}
          
            <div className="ml-20 mt-16 mr-20">
              <table className="table table-borderless">
                <thead className=" table_head1 font-family">
                  <tr className="text-white text-center">
                    <th scope="col" className="head_pad1">
                      Name
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                    <th scope="col" className="head_pad1">
                      Email
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                    <th scope="col" className="head_pad1">
                      Team
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                    <th scope="col" className="head_pad1">
                      Activity
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                    <th scope="col" className="head_pad1">
                      Action
                      <img className="data_icn" src="data_icn.svg" alt="icon" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                      <img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard
                    </td>
                    <td className="head_pad2">example@gmail.com </td>
                    <td className="head_pad2">Content Team </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity">
                        <div className="activity1">Online</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn"
                        src="trash_icn.svg"
                        alt="trash"
                      />
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="edit_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                      <img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard
                    </td>
                    <td className="head_pad2">example@gmail.com </td>
                    <td className="head_pad2">Content Team </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity2">
                        <div className="activity3">Offline</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn"
                        src="trash_icn.svg"
                        alt="trash"
                      />
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="edit_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                      <img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard
                    </td>
                    <td className="head_pad2">example@gmail.com </td>
                    <td className="head_pad2">Sales Team </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity2">
                        <div className="activity3">Offline</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn"
                        src="trash_icn.svg"
                        alt="trash"
                      />
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="edit_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                      <img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard
                    </td>
                    <td className="head_pad2">example@gmail.com </td>
                    <td className="head_pad2">Support Team </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity2">
                        <div className="activity3">Offline</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn"
                        src="trash_icn.svg"
                        alt="trash"
                      />
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="edit_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                      <img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard
                    </td>
                    <td className="head_pad2">example@gmail.com </td>
                    <td className="head_pad2">Content Team </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity">
                        <div className="activity1">Online</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn"
                        src="trash_icn.svg"
                        alt="trash"
                      />
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="edit_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                      <img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard
                    </td>
                    <td className="head_pad2">example@gmail.com </td>
                    <td className="head_pad2">Support Team </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity">
                        <div className="activity1">Online</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn"
                        src="trash_icn.svg"
                        alt="trash"
                      />
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="edit_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                      <img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard
                    </td>
                    <td className="head_pad2">example@gmail.com </td>
                    <td className="head_pad2">Support Team </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity2">
                        <div className="activity3">Offline</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn"
                        src="trash_icn.svg"
                        alt="trash"
                      />
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="edit_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                  <tr className="text-center font-family">
                    <td className="head_pad2">
                      <img
                        className="profile_avatar"
                        src="profile_avatar.svg"
                        alt="profile"
                      />
                      Esther Howard
                    </td>
                    <td className="head_pad2">example@gmail.com </td>
                    <td className="head_pad2">Sales Team </td>
                    <td className="head_pad2 justify-content-center">
                      <div className="activity">
                        <div className="activity1">Online</div>
                      </div>
                    </td>
                    <td className="head_pad2">
                      <img
                        type="button"
                        className="trash_icn"
                        src="trash_icn.svg"
                        alt="trash"
                      />
                      <img
                        type="button"
                        className="trash_icn ml-24"
                        src="edit_icn.svg"
                        alt="trash"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          

          {/* =============All Tab content Ends Here============= */}
        </div>
      </div>

      {/* =============Modal Starts Here============= */}

      <div
        className="modal fade "
        id="MemberModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered MemberModal_size">
          <div className="modal-content">
            {/* <div className="modal-header"> */}
            <div className="d-flex justify-content-end mr-16">
              <img
                className="cross_icn mt-16"
                type="button"
                data-bs-dismiss="modal"
                src="cross_icn.svg"
                alt="cancel_btn"
              />
            </div>
            <div className="modaltxt font-family">Add Member</div>

            {/* </div> */}
            <div className="d-flex justify-content-center mt-24">
              <img
                className="Modal_profile"
                src="Modal_profile.svg"
                alt="profile_picture"
              />
              <img
                className="camera_icn"
                src="camera_icn.svg"
                alt="profile_picture"
              />
            </div>
            <div>
              <div className="email_text mt-24 ml-30 font-family">
                Full Name
              </div>
              <div className="input_size ml-30 mt-7">
                <div className="d-flex">
                  <img
                    className="User_icon img-fluid"
                    src="User_icn.svg"
                    alt=""
                  />
                  <input
                    className="input_email"
                    type="email"
                    required
                    placeholder="Enter Your Full Name"
                    //   value={email}
                    //   onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="email_text mt-12 ml-30 font-family">Email Id</div>
              <div className="input_size ml-30 mt-7">
                <div className="d-flex">
                  <img
                    className="email_icon img-fluid"
                    src="vector2.svg"
                    alt=""
                  />
                  <input
                    className="input_email"
                    type="email"
                    required
                    placeholder="Enter Your Email"
                    //   value={email}
                    //   onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="font-family ml-30 role_txt mt-8">Assign Role:</div>
            <div>
              <div className="d-flex mt-8">
                <div
                  onClick={cardClick}
                  type="button"
                  className={isClicked ? "admin ml-30" : "sales ml-30"}
                >
                  <img
                    className="admin_logo"
                    alt="logo"
                    src={isClicked ? "admin.svg" : "Admin-Unchecked.svg"}
                  />
                  <div className={isClicked ? "admin_txt" : "admin_txt1"}>
                    Admin
                  </div>
                </div>
                <div
                  onClick={cardClick2}
                  type="button"
                  className={isClicked2 ? "admin ml-12" : "sales ml-12"}
                >
                  <img
                    className="admin_logo"
                    alt="logo"
                    src={isClicked2 ? "Sales-Checked.svg" : "dollar.svg"}
                  />
                  <div className={isClicked2 ? "admin_txt" : "admin_txt1"}>
                    Sales
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div
                  onClick={cardClick3}
                  type="button"
                  className={
                    isClicked3 ? "admin ml-30 mt-12" : "sales ml-30 mt-12"
                  }
                >
                  <img
                    className="admin_logo"
                    alt="logo"
                    src={isClicked3 ? "Content-Checked.svg" : "content.svg"}
                  />
                  <div className={isClicked3 ? "admin_txt" : "admin_txt1"}>
                    Content
                  </div>
                </div>
                <div
                  onClick={cardClick4}
                  type="button"
                  className={
                    isClicked4 ? "admin ml-12 mt-12" : "sales ml-12 mt-12"
                  }
                >
                  <img
                    className="admin_logo"
                    alt="logo"
                    src={isClicked4 ? "Support-Checked.svg" : "support.svg"}
                  />
                  <div className={isClicked4 ? "admin_txt" : "admin_txt1"}>
                    Support
                  </div>
                </div>
              </div>
              {/* <Link to='/login1'> */}
              {isClicked4 ? (
                <div>
                  <select className="select_role ml-30 mt-12">
                    <option>Select Role</option>
                    <option>Live Chat</option>
                    <option>Ticket Support</option>
                  </select>
                </div>
              ) : null}
              <button
                disabled={!anyConditionTrue}
                className="add_btn_modal font-family mb-32"
                // onClick={}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* =============Modal Starts Here============= */}
    </div>
  );
}
