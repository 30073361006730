import React, { useState } from "react";
import "../../css/sale.css";
import SaleSidebar from "../SaleComponents/SaleSidebar";
import NavbarAvatar from "../ContentComponent/NavbarAvatar";
import SmallCard from "../SaleComponents/SmallCard";
import TicketTable from "../SaleComponents/SaleTicketTable";

export default function Tickets() {
  const [isToggle, setIsToggle] = useState(true);
  function togglesidebar() {
    setIsToggle(!isToggle);
  }

  return (
    <div className="d-flex">
      {/* sidebar  */}

      <SaleSidebar isToggle={isToggle} />

      <div
        className="container-fluid sale-content "
        style={{ marginLeft: isToggle ? "20vw" : "0vw" }}
      >
        {/* navbar  */}

        <NavbarAvatar togglesidebar={togglesidebar} />

        {/* content parent container  */}
        <div className="row all-content-container">
          <div className="col-md-12">
            {/* =====    row1    === */}
            <div className="d-flex justify-content-between ml-20 mr-20">
              <div className="font-family mt-32  dash_text">Tickets</div>
              <div className="content_txt font-family mt-32">
                Sales {">"} Tickets
              </div>
            </div>
            {/* =====    row1 ends   === */}

            {/* small cards row 3 */}
            <div className="mt-20 ml-20 mr-20 ">
              <div className="pad_none">
                <div className="d-flex justify-content-between">
                  {/* 1 */}
                  <SmallCard
                    title={"Response Time"}
                    amount={"1 min"}
                    amountgraph={"graph_line.svg"}
                    perImg={"increasing_icon.svg"}
                    perAmount={"+5%"}
                    perTxt={"than last month"}
                  />

                  {/* 2 */}

                  <SmallCard
                    title={"Rating"}
                    amount={"3.5"}
                    amountgraph={"graph_line1.svg"}
                    perImg={"decreasing_icon.svg"}
                    perAmount={"-1%"}
                    perTxt={"than last month"}
                  />

                  {/* 3 */}
                  {/* <div className="d-flex ml-20"> */}

                  <SmallCard
                    title={"Tickets: Total"}
                    amount={"5,103"}
                    amountgraph={"graph_line1.svg"}
                    perImg={"decreasing_icon.svg"}
                    perAmount={"-10%"}
                    perTxt={"than last month"}
                  />

                  {/* 4 */}

                  <SmallCard
                    title={"Tickets: Totay"}
                    amount={"2,475"}
                    amountgraph={"graph_line.svg"}
                    perImg={"increasing_icon.svg"}
                    perAmount={"+4%"}
                    perTxt={"than last month"}
                  />

                  {/* </div> */}
                </div>
              </div>
            </div>
            {/* small cards row 2 ends */}

            {/* user table  */}
           

            <TicketTable />
            {/* user table ends */}
          </div>
        </div>
      </div>
    </div>
  );
}
