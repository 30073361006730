import React, { useState } from 'react'
import "../../css/sale.css"
import SaleSidebar from '../SaleComponents/SaleSidebar'
import NavbarAvatar from '../ContentComponent/NavbarAvatar'
import { NavLink } from "react-router-dom";

import MuiTable from '../DashboardComponent/MuiTable';
import SaleChartDaily from '../SaleComponents/SaleChartDaily';
import MontlyLineChart from '../SaleComponents/MontlyLineChart';
import WeeklyLineChart from '../SaleComponents/WeeklyLineChart';
import YearlyLineChart from '../SaleComponents/YearlyLineChart';
import AnalyticPieChart from '../SaleComponents/AnalyticPieChart';

export default function Analytics() {
  const [isToggle,setIsToggle]=useState(true);
  function togglesidebar()
  {
    setIsToggle(!isToggle);
  }

  const [active, isactive] = useState(true);
  const handleactive = () => {
    isactive(true);
    isactive1(false);
    isactive2(false);
    isactive3(false);
  };
  const [active1, isactive1] = useState(false);
  const handleactive1 = () => {
    isactive1(true);
    isactive(false);
    isactive2(false);
    isactive3(false);
  };
  const [active2, isactive2] = useState(false);
  const handleactive2 = () => {
    isactive(false);
    isactive1(false);
    isactive2(true);
    isactive3(false);
  };
  const [active3, isactive3] = useState(false);
  const handleactive3 = () => {
    isactive(false);
    isactive1(false);
    isactive2(false);
    isactive3(true);
  };
  return (
    <div className='d-flex'>

            {/* sidebar  */}

            <SaleSidebar isToggle={isToggle}/>

          
          
            <div className='container-fluid sale-content ' style={{ marginLeft: isToggle ? '20vw' : '0vw' }}  >
              {/* navbar  */}
            
              <NavbarAvatar togglesidebar={togglesidebar}/>

              {/* content parent container  */}
              <div className='row all-content-container'>
              <div className='col-md-12 mb-40'>

             
              
               {/* =====    row1    === */}
                <div className='d-flex justify-content-between ml-20 mr-20'>
            <div className="font-family mt-32  dash_text">Analytics</div>
            <div className='content_txt font-family mt-32'>Sales {'>'} Analytics</div>
            </div>
            {/* =====    row1 ends   === */}

            {/* charts area row 2   */}
            <div className="d-flex justify-content-between mt-20 ml-20 mr-20">
              <div className="sale_chart_bg " style={{width:isToggle?'47.733vw':'59.733vw'}}>
              <div className="d-flex">
                  <div>
                    <div className="font-family ml-20 pt-20 fnt_sale">
                    Revenue Report
                    </div>
                    <div className="font-family ml-20 mt-8 fnt_sale1 fw-400">
                      Overview of latest day
                    </div>
                  </div>
                  <div className=" pt-22 ms-auto mx-4">
                    <NavLink
                      onClick={handleactive}
                      className={
                        active
                          ? "fnt1_active text-uppercase"
                          : "fnt1 text-uppercase"
                      }
                    >
                      Daily
                    </NavLink>
                    <NavLink
                      onClick={handleactive1}
                      className={
                        active1
                          ? "fnt1_active text-uppercase ml-20"
                          : "fnt1 text-uppercase ml-20"
                      }
                    >
                      Monthly
                    </NavLink>
                    <NavLink
                      onClick={handleactive2}
                      className={
                        active2
                          ? "fnt1_active text-uppercase ml-25"
                          : "fnt1 text-uppercase ml-25"
                      }
                    >
                      Weekly
                    </NavLink>
                    <NavLink
                      onClick={handleactive3}
                      className={
                        active3
                          ? "fnt1_active text-uppercase ml-28"
                          : "fnt1 text-uppercase ml-28"
                      }
                    >
                      Yearly
                    </NavLink>
                  </div>
                </div>


                <div className="chart-container ml-20 mr-20">
                {active &&
                <SaleChartDaily/>}
                {active1 &&
                <MontlyLineChart/>
                }
                {active2 &&
                <WeeklyLineChart/>}
                {active3 &&
                <YearlyLineChart/>}
              </div>
              </div>
              <div className="user_chart_bg"    style={{width:isToggle?'28.667vw':'35.667vw'}}>
                <div className="mt-20 ml-20 user_text font-family">
                  Total Users
                </div>
                <div className="chart_pie ">
                  {/* <Chart2/> */}
                  <AnalyticPieChart/>
                </div>
              </div>
            </div>
            {/* charts row2 ends  */}

             {/* small cards row 3 */}
             <div className="mt-20 ml-20 mr-20 ">
              <div className="pad_none">
                <div className="d-flex justify-content-between">
                  {/* 1 */}
                  <div className="free_user_bg">
                    <div className="d-flex">
                      <div>
                        <div className="free_user font-family">Free Users</div>
                        <div className="free_num">15,565</div>
                      </div>
                      <div>
                        <img
                          src="graph_line.svg"
                          alt="Graph"
                          className="graph_line"
                        />
                      </div>
                    </div>
                    <div>
                      <img
                        src="increasing_icon.svg"
                        alt="increasing"
                        className="increase_icon"
                      />
                      <span className="incr">+5%</span>{" "}
                      <span className="incr1">than last month</span>
                    </div>
                  </div>
                  {/* 2 */}
                  <div className="free_user_bg ">
                    <div className="d-flex">
                      <div>
                        <div className="free_user font-family">
                          Premium Users
                        </div>
                        <div className="free_num">10,279</div>
                      </div>
                      <div>
                        <img
                          src="graph_line1.svg"
                          alt="Graph "
                          className="graph_line1"
                        />
                      </div>
                    </div>
                    <div>
                      <img
                        src="decreasing_icon.svg"
                        alt="increasing"
                        className="increase_icon"
                      />
                      <span className="decr">-2%</span>{" "}
                      <span className="decr1">than last month</span>
                    </div>
                  </div>

                  {/* 3 */}
                {/* <div className="d-flex ml-20"> */}
                  <div className="free_user_bg ">
                    <div className="d-flex">
                      <div>
                        <div className="free_user font-family">
                          Business Users
                        </div>
                        <div className="free_num">5,103</div>
                      </div>
                      <div>
                        <img
                          src="graph_line1.svg"
                          alt="Graph"
                          className="graph_line1"
                        />
                      </div>
                    </div>
                    <div>
                      <img
                        src="decreasing_icon.svg"
                        alt="increasing"
                        className="increase_icon"
                      />
                      <span className="decr">-10%</span>{" "}
                      <span className="decr1">than last month</span>
                    </div>
                  </div>
                  {/* 4 */}
                  <div className="free_user_bg ">
                    <div className="d-flex">
                      <div>
                        <div className="free_user font-family">
                          Custom Users
                        </div>
                        <div className="free_num">2,475</div>
                      </div>
                      <div>
                        <img
                          src="graph_line.svg"
                          alt="Graph "
                          className="graph_line"
                        />
                      </div>
                    </div>
                    <div>
                      <img
                        src="increasing_icon.svg"
                        alt="increasing"
                        className="increase_icon"
                      />
                      <span className="incr">+4%</span>{" "}
                      <span className="incr1">than last month</span>
                    </div>
                  </div>
                {/* </div> */}
                </div>
              </div>
            </div>
            {/* small cards row 2 ends */}

            {/* user table  */}
            {/* <DashboardTable/> */}
            <MuiTable/>
            {/* user table ends */}



              </div>
              </div>
              

            </div>
       
      
    </div>
  )
}
